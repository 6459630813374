import axios from "axios"
import {notification} from "antd"
import {Auth} from '@q4us-sw/q4us-ui'
import {MetaProps} from "../components/report/Reports";
import moment from "moment/moment";

if(process.env.REACT_APP_SERVER_URL){
    axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
}

axios.interceptors.request.use(async (cfg)=>{
    //skip auto header attachment from auth requests
    if(Auth.isConfigured() && Auth.isSignedIn() && cfg.url !== "/api/auth/open-id/connect"){
        const token = await Auth.getToken();
        cfg.headers["Authorization"] = `Bearer ${token}`;
    }
    return cfg;
}, async err=>{
    notification.error({message: err.message});
})

axios.interceptors.response.use(res => {return res}, err => {
    notification.error({message: err.response?.data?.message || err.message || 'Error'});

    if(err.response?.status === 401){
        Auth.signOut()
    }
    // notification.error({message: err.message || 'Error'});
    return {};
});



    export enum Comparator{
    Equal = "=",
    LessThan = "<",
    GreaterThan = ">",
    LessThanOrEqual = "<=",
    GreaterThanOrEqual = ">=",
    NotEqual = "!="
}

export interface Filter{
    name:string,
    value: any
    comparator?: Comparator | string
}

export interface Page{
    from?: number,
    size?: number
}

export interface FetchRequest{
    tableName: string,
    orderBy?: string[],
    filter?: Filter[],
    page?: Page

}

export interface fetchWithoutPageData{
    tableName: string,
    orderBy?: string[],
    filter?: Filter[],
    returns?: string[]
}

export interface PersistenceBody{
    tableName: string,
    data: any
}

export interface UpdatePersistenceBody{
    tableName: string
    data: any
    idField?: string
    emptyStringAllowed?: boolean
}

export interface ClearDefaultBody{
    tableName: string,
    company_id: number,
    location_id: number
}

export interface UpdateDefaultBody{
    tableName: string,
    id: number,
    company_id: number,
    location_id: number
}

export interface ResetPasswordBody {
    old_password: string,
    new_password: string,
    confirm_password: string,
}

export interface InspectionsFetchRequest {
    locationId?: number,
    inspectorId?: number,
    orderBy?: string[],
    filter?: Filter[],
    page?: Page
}

export interface ReportPayload {
    company_id?: number,
    location_id: number,
    orderBy?: string[],
    start_date: string,
    end_date: string
}

export interface CompanyAcquisitionBody {
    acquirer_company: string,
    acquired_company: string
}

export interface Comment {
    frequency_comment?: string
    selection_comment?: string
    inspection_detail_comment?: string
    documentation_comment?: string
    overall_comment?: string
}

export interface Flag {
    frequency_flag?: boolean
    selection_flag?: boolean
    inspection_detail_flag?: boolean
    documentation_flag?: boolean
    overall_flag?: boolean
    frequency_status?: string
    inspection_details_status?: string
    documentation_status?: string
    overall_status?: string
    selection_status?: string
}

export interface Point {
    frequency_point?: number
    selection_point?: number
    inspection_detail_point?: number
    inspection_ooc_point?: number
    preliminary_ooc_point?: number
    detailed_ooc_point?: number
    documentation_point?: number
}

export interface FetchDashboardPayload {
    location_id: number
    quarter: string
    start_date: string | undefined
    end_date: string | undefined
    weeks: number
}

export interface ReviewData {
    avg_setup_reported: number
    inspection_expected: number
    floor_inspection_expected: number
    roof_inspection_expected: number
    percent_floor_reported: number
}

export interface UpdateDashboardPayload {
    review_id?: number
    dashboard_status?: string
    comment?: Comment
    flag?: Flag
    data?: ReviewData
    point?: Point
}

export interface logReportAddSignPayload{
    sign_off: string
    signature: string
    comments: string
    sign_off_date: string
    meta_data: MetaProps
    location_id: number
}

export interface logReportGetSignPayload{
    start_date: string
    end_date: string
    location_id?: number
}

export type UploadProps = {
    oid: number
    filename: string
}

export interface dashboardReviewMailPayload {
    to: string[]
    cc: string[]
    subject: string
    upload: UploadProps
    company_id?: number
    location_id?: number
    quarter: string
    start_date?: string
    end_date?: string
    weeks: number
}

export type FetchUsersForReviewEmail = {
    company_id: number
    location_id: number
}

export const fetch = async (request: FetchRequest)=>{
    return axios.post(`/api/v1/load`, request)
}

export const fetchWithoutPageData = async (request: fetchWithoutPageData)=>{
    return axios.post(`/api/v1/loadWithoutPageData`, request)
}

export const getUserCSV = async (filters: object[]) => {
    return axios.post(`/api/v1/get_user_csv`, {filters}, {responseType: 'blob'})
}

export const getCompanyCSV = async (filters: object[]) => {
    return axios.post(`/api/v1/get_company_csv`, {filters}, {responseType: 'blob'})
}

export const getLocationCSV = async (filters: object[]) => {
    return axios.post(`/api/v1/get_location_csv`, {filters}, {responseType: 'blob'})
}

export const getInspectionCSV = async (filters: object[], id: string | undefined) => {
    return axios.post(`/api/v1/get_inspection_csv`, {filters, id}, {responseType: 'blob'})
}

export const visualize = async (tableName:string)=>{
    return await axios.get(`/api/v1/load/${tableName}`)
}

export const configs = async (name:string)=>{
    return axios.get(`/api/v1/configs`, {params:{name}})
}

export const insert = async (data: PersistenceBody)=>{
    return axios.post(`/api/v1/insert`, data)
}

export const insertRows = async (data: PersistenceBody) => {
    return axios.post(`/api/v1/insert_rows`, data)
}

export const insertCompanyLocations = async (data: PersistenceBody) => {
    return axios.post(`/api/v1/insert_company_locations`, data)
}

export const setDailyHours = async (data: PersistenceBody) => {
    return axios.post(`/api/v1/set_daily_hours`, data)
}

export const update = async (data: UpdatePersistenceBody)=>{
    return axios.post(`/api/v1/update`, data)
}

export const inactive = async (data: UpdatePersistenceBody) => {
    return axios.post(`/api/v1/inactive`, data)
}

export const updateWithoutNull = async (data: PersistenceBody)=>{
    return axios.post(`/api/v1/update_without_null`, data)
}

export const updateUser = async (data: any)=>{
    return axios.post(`/api/v1/update_user`, data)
}

export const insertUser = async (data: any)=>{
    return axios.post(`/api/v1/create_user`, data);
}

export const importUser = async (data: any)=>{
    return axios.post(`/api/v1/import_user`, data);
}

export const forcePasswordReset = async (data: {email: string})=>{
    return axios.post(`/api/v1/force_password_reset`, data);
}

// export const createCompany = async (companyEmail: string)=>{
//         return axios.post(`/api/v1/create_company`, {group_name: companyEmail})
// }

export const clearDefault = async (data: ClearDefaultBody)=>{
    return axios.post(`/api/v1/clear_default`, data)
}

export const updateDefault = async (data: UpdateDefaultBody)=>{
    return axios.post(`/api/v1/update_default`, data)
}

export const getUserInfo = async (userRole: string)=> {
    return axios.post(`/api/v1/get_user_role`, {user_role: userRole})
}

export const resetPassword = async (data: ResetPasswordBody)=>{
    return axios.post(`/api/v1/reset_password`, data)
}

export const userInfo = async ()=>{
    return axios.get(`/api/v1/userinfo`)
}

// inspections

export const fetch_company = async (companyList?: number[] | undefined)=>{
    return axios.get(`/api/v1/company`, {params:{companyList}})
}

export const companyAcquire = async (data: CompanyAcquisitionBody)=>{
    return axios.post(`/api/v1/company-acquire`, data)
}

export const fetch_locations = async (id:number|undefined, locationList?: number[] | undefined)=>{
    return axios.get(`/api/v1/locations`, {params:{id, locationList}})
}

export const fetch_inspectors = async (id:number|undefined = undefined)=>{
    return axios.get(`/api/v1/inspectors`, {params:{id}})
}

export const fetch_inspectors_user_registration = async (id:number|undefined = undefined)=>{
    return axios.get(`/api/v1/inspectors_user_registration`, {params:{id}})
}

export const fetch_inspector_names = async (id: number | undefined = undefined) => {
    return axios.get(`/api/v1/inspector_names`, {params: {id}})
}

export const fetch_inspections = async (request: InspectionsFetchRequest)=>{
    return axios.post(`/api/v1/get_inspections`, request)
}

export const delete_inspection = async (inspectionID: number)=>{
    return axios.get(`/api/v1/delete_inspection`, {params:{inspection_id:inspectionID}})
}

export const fetch_inspection = async (inspection_id: number, report_type: string = "html")=>{
    const currentOffsetMinutes = moment().utcOffset();
    let content_type = "text/html";
    if (report_type === "pdf"){
        content_type = "application/pdf"
    }
    try{
        const response = await axios({
            // url: `/api/v1/inspection?inspection_id=${inspection_id}&report_type=${report_type}`,
            url: `/api/v1/inspection`,
            params: {inspection_id, report_type, currentOffsetMinutes},
            method: 'GET',
            responseType: 'blob',
        });
        if (response.status === 200){
            const blobStore = new Blob([response.data], { type: content_type });
            const url = window.URL.createObjectURL(blobStore);

            if (report_type === "pdf"){
                const a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = `inspection_${inspection_id}.pdf`;
                a.click();
                window.URL.revokeObjectURL(url);
            }
            else{
                const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no`;
                window.open(url, `inspection_${inspection_id}_${report_type}.pdf`, params);
            }
        }
    }
    catch (e) {
        console.log(e);
    }
}

// downloads

export const upload_dqc = async (data: FormData, callback: (progress: number) => void)=>{
    return await axios.post(`/api/upload/upload_dqc`, data, {onUploadProgress: progressEvent => callback(progressEvent), responseType: 'stream'})
}

export const upload_release_notes = async (data: FormData)=>{
    return await axios.post(`/api/upload/upload_release_notes`, data)
}

// export const upload_critical_joint_images = async (data: FormData, inspectionID: string, signOffNote: string) => {
export const upload_critical_joint_images = async (data: FormData) => {
    return await axios.post(`/api/upload/upload_critical_joint_images`, data)
    // return axios({
    //     url: `/api/upload/upload_critical_joint_images`,
    //     method: 'POST',
    //     // params: {inspectionID: inspectionID, signOffNote: signOffNote},
    //     headers: {inspectionID: inspectionID, signOffNote: signOffNote},
    //     data: data
    // });
}

export const download_release_notes = async ()=>{
    try{
        const response = await axios({
            url: `/api/v1/get_release_notes`,
            method: 'GET',
            responseType: 'blob',
        });
        if (response.status === 200){
            const blobStore = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blobStore);
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.href = url;
            a.download = `release_notes.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    }
    catch (e) {
        console.log(e);
    }
}

export const delete_dqc = async (version:string)=>{
    return axios.get(`/api/v1/delete_dqc`, {params: {version}})
}

export const download_dqc = async (version: string)=>{
    try{
        const {data: url} = await axios.get('/api/v1/download_url', {params: {version}})
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `digital-qc-${version}.msi`;
        a.click();
        window.URL.revokeObjectURL(url);
    }
    catch (e) {
        console.log(e);
    }
}

export const download_crash_report = async (id: number, file_name: string)=>{
    try{
        const result = await axios.post('/api/v1/fetch_crash_report',
            {id: id}, {responseType: 'blob'})

        const blob = new Blob([result.data], { type: 'Buffer' });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = downloadUrl;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    }
    catch (e) {
        console.log(e);
    }
}

// migrations

export const fetch_migrated_user = async (email: string)=>{
    return axios.post(`/api/v1/get_migrated_user`, {email})
}


//authentication

export const signIn = async (user:string,pass:string)=>{
    const {data} = await axios.post('/api/auth/open-id/connect', {
        password:pass,
        username:user
    }) || {}

    return data || {};
}
export const signInWithCode = async (code:any,redirect_uri:string)=>{
    const {data} = await axios.post('/api/auth/open-id/connect', {
        grant_type:"authorization_code",
        code,
        redirect_uri
    }) || {}

    return data || {};
}

export const signOut = async (refresh_token: string)=>{
    const {data} = await axios.post(`/api/auth/open-id/logout`, {
        token:refresh_token
    }) || {}

    return data || {};
}



export const auth_cfg = async ()=>{
    return axios.get('/api/auth/cfg')
}


export const refreshToken = async (token: string)=>{
    const {data} = await axios.post('/api/auth/open-id/connect', {
        grant_type:"refresh_token",
        refresh_token:token,
    }) || {}

    return data || {};
}

// General Location reports
export const averageReport = async (data: ReportPayload) => {
    return axios.post('/api/v1/average_report', data)
}

export const summaryReport = async (data: ReportPayload) => {
    return axios.post('/api/v1/summary_report', data)
}

export const qualityControlLog = async (data: ReportPayload) => {
    return axios.post('/api/v1/quality_control_log', data)
}

export const weeklySummaryReport = async (data: ReportPayload) => {
    return axios.post('/api/v1/weekly_summary_report', data)
}

export const certificationSummaryReport = async (data: ReportPayload) => {
    return axios.post('/api/v1/certification_summary_report', data)
}

export const commentReport = async (data: ReportPayload) => {
    return axios.post('/api/v1/comment_report', data)
}

export const jointReport = async (data: ReportPayload) => {
    return axios.post('/api/v1/joint_report', data)
}

export const plantReport = async (data: ReportPayload) => {
        return axios.post('/api/v1/plant_report', data)
}

export const summaryReportByCrew = async (data: ReportPayload) => {
    return axios.post('/api/v1/crew_summary', data)
}

export const logReport = async (data: ReportPayload) => {
    return axios.post('/api/v1/log_report', data)
}

export const logReportAddSign = async (data: logReportAddSignPayload) => {
    return axios.post('/api/v1/log_report/sign_off', data)
}

export const logReportGetSign = async (data: logReportGetSignPayload) => {
    return axios.get('/api/v1/log_report/sign_off', {params: data})
}

export const insertOneForEachCompanyLocation = async (data: PersistenceBody) => {
    return axios.post('/api/v1/insert_one_for_each_company_location', data)
}

export const deleteUser = async (userEmail: string, id: number)=>{
    return axios.post(`/api/v1/delete_user`, {email: userEmail, id: id})
}

export const fetchDailyHours = async (filter: any) => {
    return axios.post('/api/v1/fetch_daily_hours', filter)
}

export const uploadQCManual = async (data: any)=>{
    return axios.post('/api/upload/upload_qc_manual', data)
}

export const fetchQCManual = async (oid: number, filename: string)=>{
    const {data} = await axios({
        url: `/api/v1/fetch_qc_manual`,
        params:{oid},
        method: 'GET',
        responseType: 'blob',
    });
    const a = document.createElement("a");
    document.body.appendChild(a);
    const blobStore = new Blob([data], {type: "octet/stream"});
    const url = window.URL.createObjectURL(blobStore);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

export const fetchUsers = async (data: FetchRequest)=>{
    return axios.post('/api/v1/fetch_users', data)
}

export const createDashboardReviewRecord = async (data: any)=>{
    return axios.post('/api/v1/create_dashboard_review_record', data)
}

export const updateDashboardReviewRecord = async (data: UpdateDashboardPayload)=>{
    return axios.post('/api/v1/update_dashboard_review_record', data)
}

export const fetchReviewDashboardData = async (data: FetchDashboardPayload)=>{
    return axios.post('/api/v1/fetch_review_dashboard_data', data)
}

export const fetchInspectionComments = async (data: ReportPayload)=>{
    return axios.post('/api/v1/fetch_inspection_comments', data)
}

export const fetchOutOfConformanceComments = async (data: ReportPayload)=>{
    return axios.post('/api/v1/fetch_ooc_comments', data)
}

export const fetchSpanData = async (data: ReportPayload)=>{
    return axios.post('/api/v1/fetch_span_data', data)
}

export const sendDashboardReviewMail = async (data: dashboardReviewMailPayload)=>{
    return axios.post('/api/v1/send_dashboard_review', data)
}

export const fetchUsersForReviewEmail = async (data: FetchUsersForReviewEmail)=>{
    return axios.post('/api/v1/fetch_users_for_review_email', data)
}

export const fetch_critical_joints = async (inspection_id:number|undefined)=>{
    return axios.get(`/api/v1/fetch_critical_joints`, {params:{inspection_id}})
}

export const fetch_critical_joints_extra = async (inspection_id:number|undefined)=>{
    return axios.get(`/api/v1/fetch_critical_joints_extra`, {params:{inspection_id}})
}
