import React, {Fragment} from "react";
import {Card, Collapse, Empty, Space} from "antd";
import {
    AVERAGE_DISTANCE,
    AVERAGE_DISTANCE_FROM_DESIGNED_CENTER,
    AVERAGE_ROTATION,
    AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION,
    COUNT, fillColor,
    graphValues,
    JOINT_OVERVIEW,
    JOINT_QC_DETAIL,
    jointReports,
    NO_OF_INSPECTED,
    OUT_OF_CONFORMANCE,
    OVERVIEW_AS_PERCENTAGE,
    PLATES_UPSIZED,
    summaryReports
} from "../../utils/reportConstants";
import {Bullet, Column, DualAxes, Pie} from "@ant-design/plots";
import {processTitle} from "../../utils/report";

interface JointReportProps {
    summary: any
    breakdown: any
    jointsByRange: any
}

const JointReport: React.FunctionComponent<JointReportProps> = ({summary, breakdown, jointsByRange}) => {

    const { Panel } = Collapse
    const {title, data} = jointsByRange
    const positionOfGraphValues = 'bottom'
    const certificationReportLabelColor = '#566573'

    return <Fragment>
        <Collapse defaultActiveKey={[jointReports.SUMMARY]}>
            {summary && <Panel key={jointReports.SUMMARY} header={jointReports.SUMMARY}>
                {summary?.critical.total_joints?<Space>
                    <Card title={JOINT_OVERVIEW} style={{width: '25vw', margin: 5}}>
                        <Column
                            color={fillColor}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: COUNT
                                }
                            }}
                            data={[
                                {
                                    name: NO_OF_INSPECTED,
                                    count: summary?.critical.total_joints
                                },
                                {
                                    name: OUT_OF_CONFORMANCE,
                                    count: summary?.critical.failed_joints
                                }
                            ]}
                        />
                    </Card>
                    <Card title={OVERVIEW_AS_PERCENTAGE} style={{width: '25vw', margin: 5}}>
                        <Pie
                            angleField={'count'}
                            colorField={'name'}
                            label={{
                                type: 'inner',
                                offset: '-30%',
                                content: ({ count }) => `${count}%`,
                                style: {
                                    fontSize: 14,
                                    textAlign: 'center',
                                },
                            }}
                            tooltip={{
                                formatter: ({name, count}) => ({ name, value: `${count}%` })
                            }}
                            data={[
                                {
                                    'name': `Passed Joints`,
                                    'count': parseFloat((100 - summary?.critical.percent).toFixed(2))
                                },
                                {
                                    'name': `Out of Conformance`,
                                    'count': summary?.critical.percent
                                }
                            ]}
                        />
                    </Card>
                    <Card title={summaryReports.PLATES_UPSIZED_FROM_SPECIFIED} style={{width: '25vw', margin: 5}}>
                        <Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'Total plates'}
                            xField={'title'}
                            data={[
                                {
                                    title: PLATES_UPSIZED,
                                    ranges: [summary?.critical.total_joints],
                                    measures: [summary?.upsized.count],
                                    'Total plates': summary?.critical.total_joints,
                                }
                            ]}
                        />
                    </Card>
                </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            </Panel>}
            {breakdown && <Panel key={jointReports.BREAKDOWN} header={jointReports.BREAKDOWN}>
                {summary?.critical.total_joints?<Fragment>
                    <Card title={OUT_OF_CONFORMANCE}>
                        <Column
                            color={fillColor}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: COUNT
                                }
                            }}
                            label={{
                                content: ({percent}) => `${percent || 0}%`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={Object.entries(breakdown?.out_of_conformance)?.map((field: any) => ({
                                name: processTitle(field[0]),
                                count: field[1].count,
                                percent: field[1].percent
                            }))}
                        />
                    </Card>
                    <Space>
                        <Card title={JOINT_QC_DETAIL} style={{width: window.innerWidth>1900?'60vw':'50vw', margin: 5, marginTop: 20}}>
                            <Column
                                color={fillColor}
                                xField={'name'}
                                yField={'count'}
                                yAxis={{
                                    title: {
                                        text: COUNT
                                    }
                                }}
                                label={{
                                    content: ({percent}) => `${percent || 0}%`,
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={Object.entries(breakdown?.joint_detail)
                                    ?.filter((field: any) => field[0]!=='average_distance_16ths' && field[0]!=='average_orientation')
                                    ?.map((field: any) => ({
                                        name: processTitle(field[0]),
                                        count: field[1].count,
                                        percent: field[1].percent
                                    }))}
                            />
                        </Card>
                        <Space direction={"vertical"} style={{width: '25vw'}}>
                            <Card title={AVERAGE_DISTANCE_FROM_DESIGNED_CENTER}>
                                <Bullet
                                    measureField={'measures'}
                                    rangeField={'ranges'}
                                    targetField={'Total joints'}
                                    xField={'title'}
                                    data={[
                                        {
                                            title: AVERAGE_DISTANCE,
                                            ranges: [breakdown?.joint_detail.average_distance_16ths * 4],
                                            measures: [breakdown?.joint_detail.average_distance_16ths],
                                            'Total joints': breakdown?.joint_detail.average_distance_16ths * 4,
                                        }
                                    ]}
                                    style={{maxHeight: 145}}
                                />
                            </Card>
                            <Card title={AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION}>
                                <Bullet
                                    measureField={'measures'}
                                    rangeField={'ranges'}
                                    targetField={'Total joints'}
                                    xField={'title'}
                                    data={[
                                        {
                                            title: AVERAGE_ROTATION,
                                            ranges: [breakdown?.joint_detail.average_orientation * 4],
                                            measures: [breakdown?.joint_detail.average_orientation],
                                            'Total joints': breakdown?.joint_detail.average_orientation * 4,
                                        }
                                    ]}
                                    style={{maxHeight: 145}}
                                />
                            </Card>
                        </Space>
                    </Space>
                </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            </Panel>}
            {jointsByRange && <Panel key={title} header={title}>
                {summary?.critical.total_joints?<DualAxes
                    xField={'range'}
                    yField={['joints', 'passPercentage']}
                    data={[data, data]}
                    geometryOptions={[
                        {
                            geometry: 'column'
                        },
                        {
                            geometry: 'line',
                            lineStyle: {
                                lineWidth: 2
                            },
                        },
                    ]}
                />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            </Panel>}
        </Collapse>
    </Fragment>
}

export default JointReport