import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'antd/dist/antd.css'
import {
    BrowserRouter as Router,
    Switch,
    Route, useLocation
} from 'react-router-dom'
import {I18nextProvider} from "react-i18next";
import i18 from "./i18";
import qs from "query-string";
import {AuthorizationCode} from "@q4us-sw/q4us-ui/lib/auth/AuthorizationCode";

import {auth_cfg} from "./api";
import {MainView} from "./view/MainView";
import {AuthContextProvider} from "./components/user/AuthProvider"

export const View = (props: any) => {
    let location = useLocation();
    const {code} = qs.parse(location.search);

    return <AuthorizationCode
                configs={
                    async () => {
                    const {data: cfg} = await auth_cfg();
                    if (cfg){
                        if (location.pathname && location.pathname.slice(location.pathname.length - 9)!== 'undefined'){
                            cfg.redirect_uri = cfg.redirect_uri + location.pathname
                        }
                        else{
                            cfg.redirect_uri = cfg.redirect_uri + '/'
                        }
                    }
                    return cfg;
                    }
                }
                code={code ? `${code}` : undefined}
    >
        <MainView/>
    </AuthorizationCode>
}


ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18}>
            <Router>
                <Switch>
                    <Route path={"/"}>
                        <AuthContextProvider>
                            <View/>
                        </AuthContextProvider>
                    </Route>
                </Switch>
            </Router>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementsByTagName('body')[0]
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

