export const writeToCSV = (res: any, fileName: string) => {
    const dataCSV = res.data;
    const filename = fileName;
    const blob = new Blob([dataCSV], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}