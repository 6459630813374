import React, {Fragment} from "react";
import {Card, Input, notification} from "antd";
import {Form} from "@q4us-sw/q4us-ui";
import {configs, resetPassword, ResetPasswordBody} from "../api";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";

const ResetPassword = () => {
    const {Password} = Input;

    return <Fragment>
        <Card
            type={"inner"}
            title={'Change Password'}
            style={{width: '50vw', marginLeft: '20vw'}}
        >
            <Form
                schema={'reset_password'}
                config={async () => {
                    const {data: {rows = []} = {}} = await configs('reset_password')
                    return rows
                }}
                validateField={async (
                    rule: any, value: any, schema: string, column:FormElementProps, form:FormInstance<any>) => {
                    if (column.name==='confirm_password' && value && value!==form.getFieldValue('new_password')) {
                        return Promise.reject("Confirm password should match with new password")
                    } else if (column.name==='new_password' && value && value === form.getFieldValue('old_password')) {
                        return Promise.reject("New password is same as the existing password")
                    }
                }}
                overrideComponent={() => {
                    return <Password/>
                }}
                submit={async (values: ResetPasswordBody) => {
                    const {data: {code, message} = {}} = await resetPassword(values)
                    if (code===200) {
                        notification.success({message: message || 'success'})
                    } else {
                        notification.error({message: message || 'error'})
                    }
                }}
            />
        </Card>
    </Fragment>
}

export default ResetPassword