import React, {Fragment, FunctionComponent} from "react";
import {Card, Collapse, Space} from "antd";
import {
    ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES,
    AVERAGE_DISTANCE,
    AVERAGE_DISTANCE_FROM_DESIGNED_CENTER,
    AVERAGE_FEET,
    AVERAGE_ROTATION,
    AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION,
    BACK,
    certificationReports,
    COUNT,
    DISTANCE_IN_INCHES, fillColor,
    FLOOR_CRITICAL,
    FLOOR_SPAN,
    FRONT, HOURS, HOURS_PER_WEEK,
    OVERVIEW_AS_PERCENTAGE,
    PERCENT_DEFECTS_OF_INSPECTIONS,
    PERCENT_DEFECTS_OF_JOINTS,
    PERCENT_OF_PLATES_UPSIZED,
    PERCENT_TRUSSES_OF_UPSIZED_PLATES,
    PERCENTAGE,
    RATIO_OF_SQUARE_INCHES,
    ROOF_CRITICAL,
    ROOF_SPAN,
    ROTATION_IN_DEGREES, SQUARE_INCHES, TARGET,
    TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS,
    TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS,
    TRUSS_COUNT, TRUSSES,
    TRUSSES_AS_PERCENTAGE, TRUSSES_PER_SHIFT_PER_WEEK
} from "../../utils/reportConstants";
import {Bullet, Column, Line} from "@ant-design/plots";
import {
    inchToFeet,
    processData,
    processDefectFoundData, processFrequencySummaryData,
    processInspectionDetailData,
    processTrussCountData,
    processTrussPercentData,
    processUpsizedPlatesData
} from "../../utils/report";
import Score from "./Score";

interface CertificationReportBody {
    overallOutOfConformance: any
    defectsFound: any
    upsizedPlates: any
    placementTolerance: any
    frequencySummary: any
    trussSelection: any
    inspectionDetail: any
    scoring: any
}

const CertificationReport:FunctionComponent<CertificationReportBody> = ({
                                                                            overallOutOfConformance,
                                                                            defectsFound,
                                                                            upsizedPlates,
                                                                            placementTolerance,
                                                                            frequencySummary,
                                                                            trussSelection,
                                                                            inspectionDetail,
                                                                            scoring
                                                                        }) => {

    const { Panel } = Collapse
    return <Fragment>
        <Collapse defaultActiveKey={['overallOutOfConformance']}>
            {overallOutOfConformance && <Panel key={'overallOutOfConformance'} header={certificationReports.OVERALL_OUT_OF_CONFORMANCE}>
                <Line
                    xField={'Quarter'}
                    yField={'Average'}
                    seriesField={'category'}
                    animation={{
                        appear: {
                            animation: 'path-in',
                            duration: 2000,
                        },
                    }}
                    yAxis={{
                        title: {
                            text: PERCENTAGE
                        }
                    }}
                    tooltip={{
                        formatter: ({category, Average}) => ({ name: category, value: Average + '%' }),
                    }}
                    data={processData(overallOutOfConformance)}
                />
            </Panel>}
            {defectsFound && <Panel key={'defectsFound'} header={certificationReports.DEFECTS_FOUND}>
                <Card title={TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: COUNT
                            }
                        }}
                        data={processDefectFoundData(defectsFound, "defect")}
                    />
                </Card>
                <Card title={TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: COUNT
                            }
                        }}
                        data={processDefectFoundData(defectsFound, "defect_out")}
                    />
                </Card>
                <Card title={PERCENT_DEFECTS_OF_JOINTS}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: PERCENTAGE
                            }
                        }}
                        tooltip={{
                            formatter: ({type, count}) => ({ name: type, value: count + '%' })
                        }}
                        data={processDefectFoundData(defectsFound, "joint")}
                    />
                </Card>
                <Card title={PERCENT_DEFECTS_OF_INSPECTIONS}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: PERCENTAGE
                            }
                        }}
                        tooltip={{
                            formatter: ({type, count}) => ({ name: type, value: count + '%' })
                        }}
                        data={processDefectFoundData(defectsFound, "inspection")}
                    />
                </Card>
            </Panel>}
            {upsizedPlates && <Panel key={'upsizedPlates'} header={certificationReports.UPSIZED_PLATES}>
                <Card title={PERCENT_OF_PLATES_UPSIZED}>
                    <Column
                        color={fillColor}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: PERCENTAGE
                            }
                        }}
                        tooltip={{
                            formatter: ({count}) => ({ name: 'Upsized plates', value: count + '%' })
                        }}
                        data={upsizedPlates?.map((quarter: any) => ({
                            name: quarter.range,
                            count: quarter.upsized_trusses
                        }))}
                    />
                </Card>
                <Card title={ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES}>
                    <Column
                        color={fillColor}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: SQUARE_INCHES
                            }
                        }}
                        tooltip={{
                            formatter: ({count}) => ({ name: 'Additional square inches', value: count + ' sq in' })
                        }}
                        data={upsizedPlates?.map((quarter: any) => ({
                            name: quarter.range,
                            count: quarter.additional_square
                        }))}
                    />
                </Card>
                <Card title={RATIO_OF_SQUARE_INCHES}>
                    <Bullet
                        measureField={'measures'}
                        rangeField={'ranges'}
                        targetField={'target'}
                        xField={'title'}
                        tooltip={{
                            formatter: ({measures}) => ({ name: 'Actual/specified ratio', value: measures })
                        }}
                        data={upsizedPlates?.map((quarter: any) => ({
                            title: quarter.range,
                            ranges: [1, quarter.area_ratio * 1.2],
                            measures: [quarter.area_ratio],
                            target: 1
                        }))}
                    />
                </Card>
                <Card title={PERCENT_TRUSSES_OF_UPSIZED_PLATES}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: PERCENTAGE
                            }
                        }}
                        tooltip={{
                            formatter: ({type, count}) => ({ name: type, value: count + '%' })
                        }}
                        data={processUpsizedPlatesData(upsizedPlates)}
                    />
                </Card>
            </Panel>}
            {placementTolerance && <Panel key={'placementTolerance'} header={certificationReports.PLACEMENT_TOLERANCE}>
                <Card title={AVERAGE_DISTANCE}>
                    <Space>
                        <Card title={FRONT} type={"inner"} style={{width: '40vw'}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={processData(placementTolerance?.map((quarter: any) => quarter.distance.front))}
                            />
                        </Card>
                        <Card title={BACK} type={"inner"} style={{width: '40vw'}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={processData(placementTolerance?.map((quarter: any) => quarter.distance.back))}
                            />
                        </Card>
                    </Space>
                </Card>
                <Card title={AVERAGE_ROTATION}>
                    <Space>
                        <Card title={FRONT} type={"inner"} style={{width: '40vw'}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={processData(placementTolerance?.map((quarter: any) => quarter.rotation.front))}
                            />
                        </Card>
                        <Card title={BACK} type={"inner"} style={{width: '40vw'}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={processData(placementTolerance?.map((quarter: any) => quarter.rotation.back))}
                            />
                        </Card>
                    </Space>
                </Card>
            </Panel>}
            {frequencySummary && <Panel key={'frequencySummary'} header={certificationReports.FREQUENCY_SUMMARY}>
                <Space>
                    <Card title={TRUSSES_PER_SHIFT_PER_WEEK} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Count'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: TRUSSES
                                }
                            }}
                            lineStyle={({category}) => {
                                if(category===TARGET){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={processFrequencySummaryData(frequencySummary, true)}
                        />
                    </Card>
                    <Card title={HOURS_PER_WEEK} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Count'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: HOURS
                                }
                            }}
                            lineStyle={({category}) => {
                                if(category===TARGET){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Count}) => ({ name: category, value: `${Count}h` })
                            }}
                            data={processFrequencySummaryData(frequencySummary, false)}
                        />
                    </Card>
                </Space>
            </Panel>}
            {trussSelection && <Panel key={'trussSelection'} header={certificationReports.TRUSS_SELECTION}>
                <Space>
                    <Card title={TRUSS_COUNT} style={{width: '40vw'}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: COUNT
                                }
                            }}
                            data={processTrussCountData(trussSelection)}
                        />
                    </Card>
                    <Card title={TRUSSES_AS_PERCENTAGE} style={{width: '40vw'}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Percent'}
                            yAxis={{
                                title: {
                                    text: PERCENTAGE
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Percent}) => ({ name: category, value: Percent?`${Percent}%`:0 })
                            }}
                            data={processTrussPercentData(trussSelection)}
                        />
                    </Card>
                </Space>
                <Space>
                    <Card title={ROOF_SPAN} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: AVERAGE_FEET
                                }
                            }}
                            lineStyle={({category}) => {
                                if(category==='goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: inchToFeet(Average * 12) })
                            }}
                            data={processData(trussSelection?.map((quarter: any) => quarter.roof.span), true)}
                        />
                    </Card>
                    <Card title={FLOOR_SPAN} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: AVERAGE_FEET
                                }
                            }}
                            lineStyle={({category}) => {
                                if(category==='goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: inchToFeet(Average * 12) })
                            }}
                            data={processData(trussSelection?.map((quarter: any) => quarter.floor.span), true)}
                        />
                    </Card>
                </Space>
                <Space>
                    <Card title={ROOF_CRITICAL} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            lineStyle={({category}) => {
                                if(category==='minimum_required_goal' || category==='certification_minimum_goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: Average })
                            }}
                            data={processData(trussSelection?.map((quarter: any) => quarter.roof.critical), false)}
                        />
                    </Card>
                    <Card title={FLOOR_CRITICAL} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            lineStyle={({category}) => {
                                if(category==='minimum_required_goal' || category==='certification_minimum_goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: Average })
                            }}
                            data={processData(trussSelection?.map((quarter: any) => quarter.floor.critical), false)}
                        />
                    </Card>
                </Space>
            </Panel>}
            {inspectionDetail && <Panel key={'inspectionDetail'} header={certificationReports.INSPECTION_DETAIL}>
                <Space>
                    <Card title={AVERAGE_DISTANCE_FROM_DESIGNED_CENTER} style={{width: '40vw'}}>
                        <Column
                            color={fillColor}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: DISTANCE_IN_INCHES
                                }
                            }}
                            tooltip={{
                                formatter: ({Count}) => ({ name: 'avg distance', value: `${Count}in` })
                            }}
                            data={inspectionDetail?.map((detail: any) => ({
                                Quarter: detail?.detailed.range,
                                Count: detail?.detailed.avg_center_misplaced_distance,
                            }))}
                        />
                    </Card>
                    <Card title={AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION} style={{width: '40vw'}}>
                        <Column
                            color={fillColor}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: ROTATION_IN_DEGREES
                                }
                            }}
                            tooltip={{
                                formatter: ({Count}) => ({ name: 'avg rotation', value: `${Count}°` })
                            }}
                            data={inspectionDetail?.map((detail: any) => ({
                                Quarter: detail?.detailed.range,
                                Count: detail?.detailed.avg_rotation_from_specified,
                            }))}
                        />
                    </Card>
                </Space>
                <Card title={OVERVIEW_AS_PERCENTAGE}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'category'}
                        xField={'Quarter'}
                        yField={'Percent'}
                        yAxis={{
                            title: {
                                text: PERCENTAGE
                            }
                        }}
                        tooltip={{
                            formatter: ({category, Percent}) => ({ name: category, value: `${Percent}%` })
                        }}
                        data={processInspectionDetailData(inspectionDetail)}
                    />
                </Card>
                <Card title={ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES}>
                    <Column
                        color={fillColor}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: SQUARE_INCHES
                            }
                        }}
                        tooltip={{
                            formatter: ({count}) => ({ name: 'Additional square inches', value: count + ' sq in' })
                        }}
                        data={inspectionDetail?.map((detail: any) => ({
                            name: detail?.detailed.range,
                            count: detail?.detailed.additional_square
                        }))}
                    />
                </Card>
            </Panel>}
            {scoring && <Panel key={'scoring'} header={certificationReports.CERTIFICATION_SCORING}>
                <Score data={scoring} column={4}/>
            </Panel>}
        </Collapse>
    </Fragment>
}

export default CertificationReport