import React, {Fragment, FunctionComponent} from "react";
import {Button, InputNumber, Typography} from "antd";

export interface ExtraProps {
    points?: number
    style?: { [key: string]: any }
    isEdit: boolean
    pointValue?: number
    onPointChange?: (value: number | null) => void
}

const Extra: FunctionComponent<ExtraProps> = ({points, style, isEdit, pointValue, onPointChange}) => {

    const {Text} = Typography
    return <Fragment>
        <InputNumber
            value={pointValue}
            onChange={value => isEdit && onPointChange && onPointChange(value)}
            style={{
                backgroundColor: isEdit?'#EBF5FB':'#FFFFFF',
                marginRight: 2,
                color: "black"
                , ...style
            }}
            min={0}
            max={points}
            disabled={!isEdit}
        />
        <Button disabled><Text strong>/{points}</Text></Button>
    </Fragment>
}

export default Extra
