import React, {useCallback, useEffect, useState} from "react";
import {Select} from "antd";
import {Trans} from "react-i18next";
import {AxiosResponse} from "axios";

// export interface Filters{
//     filter: string,
//     value:string
// }

export interface CustomSelectProps {
    value?: any,
    onChange?: (data: any) => void,
    fetch: (filterValues:string[]) => Promise<AxiosResponse<any>>,
    schema: string,
    name: string,
    title: string,
    filterValues: string[]
    enum_map: string[]
}

const CustomSelect: React.FunctionComponent<CustomSelectProps> = (props) => {
    const [enums, setEnums] = useState<[string, string][]|undefined>(undefined);

    // const arrChecker = (values:string[], enums: string[]) => values.every(val => enums.includes(val))
    const propsValueFilter = (values:string[]|undefined, enums: string[]|undefined) => values?.filter(val => enums?.includes(val))

    const fetchEnums = useCallback(async (filterValues:string[])=>{
        const response = await props.fetch(filterValues)
        const {status, data:{rows=[]}}:{status:number, data:{rows:Array<any>}}  = response;
        if (status===200){
            const enums = rows.map((item):[string, string]=>{return [String(item[props.enum_map[0]]) ,String(item[props.enum_map[1]])]});
            setEnums(enums);
            if (props.value){
                props.onChange?.(propsValueFilter(props.value, rows.map(item =>{return String(item[props.enum_map[0]])})))
            }
            else{
                props.onChange?.(undefined)
            }
        } else{
            setEnums([]);
            props.onChange?.(undefined)
        }
    }, []);

    useEffect(()=>{
        fetchEnums(props.filterValues);
    }, [...props.filterValues]);

    return <Select
        allowClear
        mode={"multiple"}
        showSearch
        value={props.value}
        // value={propsValueFilter(props.value, enums?.map(item =>{return item[0]}))}
        onChange={(e)=>{props.onChange?.(e)}}
        placeholder={"Please Select ..."}
    >
        {enums?.map(([k,v])=><Select.Option key={k} value={k} title={v}>
            <Trans i18nKey={`${props.schema}.${props.name}.${k.replace(/\./g, "_")}`} defaults={v}/>
        </Select.Option>)}
    </Select>
}

export default CustomSelect

