import React, {Fragment, useEffect, useState} from "react";
import {companyAcquire, configs, fetch_locations} from "../api";
import {Form} from "@q4us-sw/q4us-ui";
import {Modal, notification, PageHeader, Typography} from "antd";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";

interface CompanyAcquisitionBody {
    acquirer_company: string,
    acquired_company: string
}

export const CompanyAcquisition: React.FunctionComponent = () => {
    const [config,setConfig] = React.useState([] as FormElementProps[])
    const [opt, setOpt] = useState<{acquirer_company?: string, acquired_company?: string, open: boolean}>({open: false})

    const load = async () => {
        const res = await configs('company_acquisition')
        setConfig(res.data?.rows || [])
    }

        const fetchLocations = async(acquired_company: string) =>{
            const id = parseInt(opt.acquired_company || "")
            const {data: {rows = []} = {}} = await fetch_locations(id);
            let inactiveLocations = false;
            if (rows === null || rows.length === 0) {
                inactiveLocations = true;
            }
            return inactiveLocations;
        }

    const getCompanyName = (id: string) => {
        return config?.[0].enums?.find((element:[string, string]) => element[0] === id)?.[1] || '';
    }

    useEffect(() => {
        load()
    }, [])

    const handleOk = async () => {
        if (opt.acquirer_company && opt.acquired_company) {
            const inactiveLocationsValue = await fetchLocations(opt.acquired_company)
            if (opt.acquirer_company === opt.acquired_company) {
                notification.warning({message: 'Acquire company and the acquired company cannot be the same'})
                setOpt({...opt, open: false})
            }
            else if (inactiveLocationsValue) {
                notification.warning({message: 'Company doesn’t have any active locations. Please add at least one active location before acquiring'})
                setOpt({...opt, open: false})
            } else {
                const {data: {statusCode} = {}} = await companyAcquire({
                    acquirer_company: opt.acquirer_company,
                    acquired_company: opt.acquired_company
                })
                if (statusCode === 200) {
                    notification.success({
                        message: `${getCompanyName(opt.acquired_company)} aquired by ${getCompanyName(opt.acquirer_company)}`
                    })
                    setOpt({...opt, open: false})
                    load()
                }
            }
        }
    }

    const handleCancel = () => {
        setOpt({...opt, open: false})
    }

    const {Text} = Typography;
    return <Fragment>
        <PageHeader title={'Company Acquisition'}/>
        <Form
            schema={'company_acquisition'}
            layout={'inline'}
            acceptText={'Acquire'}
            config={async () => {
                return config
            }}
            submit={async (values: CompanyAcquisitionBody) => {
                setOpt({...values, open: true})
                return true
            }}
        />
        <Modal title="Confirmation" open={opt.open} onOk={handleOk} onCancel={handleCancel}>
            {opt.acquired_company && opt.acquirer_company && <Fragment>
                <Text>Please confirm to
                    acquire </Text><Text strong>{getCompanyName(opt.acquired_company)}</Text><Text> by </Text><Text strong>{getCompanyName(opt.acquirer_company)}</Text>
            </Fragment>}
        </Modal>
    </Fragment>
}