import React, {useRef, useState} from "react";
import {Button, Modal, Row, Col, PageHeader, Typography, Form, DatePicker, Progress, Space, Upload} from "antd";
import {FilePdfOutlined} from '@ant-design/icons';
import {ITableContext, Page, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {
    configs,
    fetch,
    upload_dqc,
    download_dqc,
    delete_dqc,
    upload_release_notes,
    download_release_notes
} from "../api";
import moment from "moment";
import {AuthContext} from "./user/AuthProvider";
import {unstable_batchedUpdates} from "react-dom";
import {getOrdering} from "./filters/ordering";
import {Validate} from "./lib/Unauthorized";
import { ConfigsContext } from './configs/ConfigsProvider';
import DatePickerFormatted from "./lib/DatePickerFormatted";
import {handleScroll} from "../utils/util";

const fetchDownloadsData = async (page?: Page, {column='release_date', direction='desc'}:{column?:string, direction?:string}={}) => {
    const orderBy = getOrdering(column, direction);
    return await fetch({
        tableName: 'download_view',
        orderBy: orderBy,
        page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
    }) || {}
}

const checkVersion = async (version: string) => {
    const {data:{rowCount}} = await fetch(
        {
            tableName: 'version_history',
            filter: [{
                name: 'version',
                value: version,
                comparator: '='
            }]
        }
    )
    return rowCount == 0;
}

interface FileInputFormControlProps{
    value?: any;
    onChange?: (value: any) => void;
}

const FileInputFormControl:React.FC<FileInputFormControlProps> = ({value = {}, onChange}) => {
    const [file, setFile] = useState<any>(null);

    const inputFile = useRef<HTMLInputElement | null>(null)

    const triggerChange = (changedValue: { file?: any}) => {
        onChange?.({ file, ...value, ...changedValue });
    };

    const onFileChange = (selection: any) => {
        if (selection.target.files[0]) {
            setFile(selection.target.files[0]);
        }
        triggerChange({ file: selection.target.files[0]});
    };

    return <span>
        <input type="file" ref={inputFile} onChange={onFileChange} style={{display: 'none'}}/>
        <Button onClick={() => inputFile.current && inputFile.current.click()}>Select a File</Button>
    </span>
}


// Upload Button and Modal
const UploadFile:React.FunctionComponent = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [version, setVersion] = useState<string>('');

    const [form] = Form.useForm();

    const version_re = /^digital-qc-([0-9]+\.[0-9]+\.[0-9]+).msi$/;

    const upload = async (values: any) => {
        const formData = new FormData();
        const release_date = moment(values.release_date).utc().set({hour: 12, minute: 0, second: 0, millisecond: 0})
        formData.append("version", version);
        formData.append("release", release_date.toString());
        formData.append("size", (values.upload.file.size / 1e6).toString());
        formData.append("file", values.upload.file);

        setUploading(true)
        window.onbeforeunload = function () {return false;}
        await upload_dqc(formData, updateUploadProgress)
        window.onbeforeunload = function () {}
        setUploadProgress(100)
        setUploading(false)
    }

    const updateUploadProgress = (data_sent:any) => {
        const {loaded, total} = data_sent;
        const progress = Math.min(Math.round(loaded * 100/ total), 95);
        setUploadProgress(progress);
    }

    const handleCancel = () => {
        if (!uploading){
            setVersion('')
            setOpen(false)
        }
    }

    const handleSubmit = (table: ITableContext|undefined) => {
        form
            .validateFields()
            .then(async (values) => {
                table?.setLoading(true)
                await upload(values)
                form.resetFields();
                const res = await fetchDownloadsData(table?.page);
                unstable_batchedUpdates(() => {
                    setVersion('')
                    setUploadProgress(0)
                    setOpen(false)
                    // update table
                    table?.setData(res.data?.rows || [])
                    table?.setPage({
                        current: res.data?.page?.current ?? table?.page?.current ?? 0,
                        total: res.data?.page?.total ?? table?.page?.total ?? 0,
                        size: table?.page?.size ?? table?.props.defaultPageSize ?? 100
                    })
                    table?.setLoading(false)
                })
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setUploading(false)
            });
    }

    const parseVersion = (selected_file: any) => {
        const file = selected_file.file
        const file_name = file.name
        const match = file_name.match(version_re)
        return match?.[1] || ''
    };

    return <div>
        <Button onClick={() => setOpen(true)}>Upload New Version</Button>
        <TableContext.Consumer>
            {table =>
        <Modal
            title='Upload New Version'
            visible={open}
            okText="Upload"
            okButtonProps={{disabled: uploading}}
            cancelButtonProps={{disabled: uploading}}
            cancelText="Cancel"
            onCancel={handleCancel}
            onOk={() => handleSubmit(table)}
            destroyOnClose
        >
            <Form
                form={form}
                layout="horizontal"
                name="form_in_modal"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    label="Select a file"
                    name="upload"
                    rules={
                        [
                            {
                                required: true,
                                message: 'Please select a file'
                            },
                            {
                                message: 'Invalid file selected',
                                validator: async (_, value) => {
                                    if (value){
                                        const version = parseVersion(value)
                                        if (version){
                                            return Promise.resolve()
                                        }
                                        else{
                                            return Promise.reject('Invalid file selected')
                                        }
                                    }
                                }
                            },
                            {
                                message: 'Version already exists',
                                validator: async (_, value) => {
                                    if (value && parseVersion(value)){
                                        const version_exists = await checkVersion(version)
                                        if (version_exists){
                                            return Promise.resolve()
                                        }
                                        else{
                                            return Promise.reject('Version already exists')
                                        }
                                    }
                                }
                            }
                        ]
                    }
                >
                    <FileInputFormControl onChange={(file)=> {setVersion(parseVersion(file))}}/>
                </Form.Item>
                <Form.Item label="Version" hidden={version===''}>
                    <span className="ant-form-text">{version}</span>
                </Form.Item>
                <Form.Item name="release_date"
                           label="Release Date"
                           rules={[{ required: true, message: 'Please select a release date' }]}>
                    <DatePickerFormatted/>
                </Form.Item>
            </Form>
            {uploading && <div>
                Uploading
                <Progress percent={uploadProgress} status="active"/>
            </div>}
        </Modal>}</TableContext.Consumer>
    </div>
}

interface ReleaseNotesProps{
    user_role: string
}

const ReleaseNotes:React.FunctionComponent<ReleaseNotesProps> = ({user_role}) => {
    const [open, setOpen] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const administrator = user_role === 'administrator';

    const handleCancel = () => {
        if (!uploading){
            form.resetFields();
            unstable_batchedUpdates(() =>{
                setFileName('');
                setOpen(false);
            })
        }
    }

    const upload = async (values: any) => {
        const formData = new FormData();
        formData.append("file_name", values.upload.file.name);
        formData.append("file", values.upload.file);
        setUploading(true)
        window.onbeforeunload = function () {return false;}
        await upload_release_notes(formData)
        window.onbeforeunload = function () {}
        setUploading(false)
    }

    const handleSubmit = () => {
        form
            .validateFields()
            .then(async (values) => {
                await upload(values)
                form.resetFields();
                unstable_batchedUpdates(() =>{
                    setFileName('');
                    setOpen(false);
                })
            })
            .catch(info => {
                console.log('Validate Failed:', info);
                setUploading(false)
            });
    }

    return <div style={{ marginBottom: '7' }}>
        <Space >
            <Typography.Link onClick={() =>{download_release_notes()}}>
                Release Notes <FilePdfOutlined />
            </Typography.Link>
            <Validate role={["administrator"]}>
                <Button onClick={() => setOpen(true)}>Upload Release Notes</Button><Modal
                title='Upload Release Notes'
                visible={open}
                okText="Upload"
                okButtonProps={{disabled: uploading}}
                cancelButtonProps={{disabled: uploading}}
                cancelText="Cancel"
                onCancel={handleCancel}
                onOk={handleSubmit}
                destroyOnClose
            >
                <Form
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                >
                    <Form.Item
                        label="Select a file"
                        name="upload"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a file'
                            },
                            {
                                message: 'Invalid file selected',
                                validator: async (_, value) => {
                                    const file_name = value?.file?.name;
                                    if (file_name){
                                        const re = /(?:\.([^.]+))?$/;
                                        const match = file_name.match(re);
                                        if (match?.[1].toLowerCase() === 'pdf'){
                                            return Promise.resolve()
                                        }
                                        else{
                                            return Promise.reject('Invalid file selected')
                                        }
                                    }
                                }
                            }
                        ]}
                        extra={fileName}
                    >
                        <FileInputFormControl onChange={(file) => {
                            setFileName(file.file?.name);
                        }}/>
                    </Form.Item>
                </Form>
            </Modal>
            </Validate>
        </Space>
    </div>
}

interface DownloadsTableHeaderProps{
    user_role: string
}

const DownloadsTableHeader:React.FunctionComponent<DownloadsTableHeaderProps> = ({user_role:user_role}) => {
    const administrator = user_role === 'administrator'
    return <div style={{textAlign: 'right', paddingRight: 7, width: '100%', marginBottom: 7}}>
        <Row>
            <Col span={12}>
                <PageHeader title={'Downloads'}/>
            </Col>
            <Col span={12}>
                <div>
                    <Validate role={["administrator"]}>
                        <UploadFile/>
                    </Validate>
                </div>
            </Col>
        </Row>
        <Row>
            <Col span={24}>
                <ReleaseNotes user_role={user_role}/>
            </Col>
        </Row>
    </div>
}



export const Downloads:React.FunctionComponent = (props) => {
    const {Title} = Typography

    const handleDeleteDQC = async (table: ITableContext|undefined, value: string) =>{
        table?.setLoading(true)
        await delete_dqc(value);
        const res = await fetchDownloadsData(table?.page);
        unstable_batchedUpdates(() => {
            // update table
            table?.setData(res.data?.rows || [])
            table?.setPage({
                current: res.data?.page?.current ?? table?.page?.current ?? 0,
                total: res.data?.page?.total ?? table?.page?.total ?? 0,
                size: table?.page?.size ?? table?.props.defaultPageSize ?? 100
            })
            table?.setLoading(false)
        })
    }

    return <React.Fragment>
        <AuthContext.Consumer>{auth =>
        <TableV2
            // key={refresh}
            schema={'downloads'}
            maxColumnWidth={400}
            header={DownloadsTableHeader({
                user_role: auth.user?.user_role
            })}
            fetchConfig={async (schema) => {
                return  await configs(schema)
            }}
            fetchData={async (request) => {
                handleScroll()
                return await fetchDownloadsData(request.page, request.options.inbuilt?.sort)
            }}
            scroll={{y: 'calc(100vh - 540px)', x: '100%'}}
            customRenderer={{
                size_in_mega_byte: (value, row, index, column) => {
                    return <Typography.Text>{Math.round(value)} MB</Typography.Text>
                },
                release_date: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                },
                download: (value, row, index, column) => {
                    return <TableContext.Consumer>
                        {table =><Space>
                            <Button onClick={async () => {await download_dqc(value)}}>Download</Button>
                            <Validate role={["administrator"]}>
                                <Button onClick={() => handleDeleteDQC(table, value)}>Delete</Button>
                            </Validate>
                    </Space>}</TableContext.Consumer>
                }
            }}
        />
        }</AuthContext.Consumer>
        <br/>
        <div>
            <Title level={5}>Mobile and Tablet Downloads</Title>
            <table>
                <tr>
                    <td>
                        <a href='https://play.google.com/store/apps/details?id=org.sbca.dqc&hl=en&gl=US' target={'_blank'} rel={'noopener, noreferrer'}>
                            <img
                                alt='Get it on Google Play'
                                style={{ width: '160px'}}
                                src='https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png'
                            />
                        </a>
                    </td>
                    <td>
                        <a href='https://apps.apple.com/us/app/digital-qc/id1620686577' target={'_blank'} rel={'noopener, noreferrer'}>
                            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1603843200&h=6378da75358743d38bc6021b1900ee54"
                                 alt="Download on the App Store"
                                 style={{ width: '130px', height: '43.16px', borderRadius: '13px'}}
                            />
                        </a>
                    </td>
                </tr>
                <tr>
                    <td><p style={{textAlign: "center"}}>Android Version</p></td>
                    <td><p style={{textAlign: "center"}}>IOS Version</p></td>
                </tr>
            </table>
        </div>
        <div>
            <Title level={5}>
                <a href={'https://www.sbcacomponents.com/digqc-resources'} target={'_blank'} rel={'noopener, noreferrer'}>Digitial QC Resources</a>
            </Title>
        </div>
    </React.Fragment>
}

export default Downloads;
