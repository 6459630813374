import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Descriptions, Modal, notification, PageHeader, Row} from 'antd';
import {Form} from "@q4us-sw/q4us-ui";
import {AuthContext} from "./AuthProvider";
import {configs, updateUser, userInfo} from "../../api";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {validatePhone} from "../../utils/util";

interface ProfileProps{
    user: any,
    schema: string,
    onUpdated: () => void,
}


export const Profile: React.FunctionComponent<any> = (props) => {

    const [userProfile, setUserProfile] = useState({
        email: '-',
        first_name: '-',
        last_name: '-',
        contact_no: '-',
        user_role: 'none',
        company: '-',
        location_name: '-'
    })
    const [isUpdated, setIsUpdated] = useState<boolean>(false)

    function handleUpdate() {
        setIsUpdated(true);
    }

    const loadUserInfo = async () => {
        const {data} = await userInfo()
        setUserProfile(data.user_profile)
    }

    useEffect(() => {
        loadUserInfo()
        setIsUpdated(false)
    }, [isUpdated])

    return <AuthContext.Consumer>
        {auth =>
            <React.Fragment>
                <div >
                    <Row style={{textAlign: 'right', padding: 7, width: '100%'}}>
                        <Col span={12}>
                            <PageHeader title={'User Profile'}/>
                        </Col>
                        <Col span={12}>
                            <div>
                                <EditModal user={userProfile} schema={'profile_form'} onUpdated={handleUpdate}/>
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <Descriptions
                            bordered
                            size={'default'}
                        >
                            <Descriptions.Item label="Email">{userProfile.email}</Descriptions.Item>
                            <Descriptions.Item label="First Name">{userProfile.first_name}</Descriptions.Item>
                            <Descriptions.Item label="Last Name">{userProfile.last_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="Contact Number">{userProfile.contact_no || '-'}</Descriptions.Item>
                            <Descriptions.Item
                                label="User Role">{auth.user?.user_role.toUpperCase()}</Descriptions.Item>
                            <Descriptions.Item
                                label="Company">{userProfile.company || '-'}</Descriptions.Item>
                            <Descriptions.Item
                                label="Location">{userProfile.location_name || '-'}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>

            </React.Fragment>
        }
    </AuthContext.Consumer>
}

export const EditModal: React.FunctionComponent<ProfileProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false)

    function onClose() {
        setOpen(false);
        props.onUpdated()
    }

    return <Fragment>
        <AuthContext.Consumer>
            {auth =>
                <div>
                    <Button type="primary" onClick={() => setOpen(true)}>Edit</Button>
                    <Modal
                        title={'Edit User Info'}
                        width={'50vw'}
                        visible={open}
                        onCancel={onClose}
                        footer={null}
                        destroyOnClose
                    >
                        <Form
                            schema={props.schema}
                            config={async (schema: string) => {
                            const res = await configs(props.schema)
                            return res.data.rows;}}

                            initialValue={props.user}

                            visibilityValidator={(schema: string, element: any, form: any) => {
                                switch (element.name){
                                    case 'email':
                                        return false
                                }
                                return true;
                            }}

                            validateField={async  (rule:any, value:any, schema:string, column:FormElementProps, form:FormInstance<any>) =>{
                                if (value!=null){
                                    switch (column.name){
                                        case "contact_no":{
                                            if(!validatePhone(form.getFieldValue('contact_no'))){
                                                return Promise.reject('Invalid Contact Number')
                                            }
                                            break
                                        }
                                    }
                                }
                                return Promise.resolve()
                            }}

                            submit={async (values:any) => {
                                const response = await updateUser({
                                    tableName: "user_account",
                                    data: {...values, email: props.user.email, user_role: props.user.user_role, id: props.user.id},
                                    previous_data: {}
                                })
                                const {data = {}} = response;
                                if(data?.statusCode===200){
                                    notification.success({message: 'User updated successfully'})
                                    onClose()
                                    return true
                                }else{
                                    notification.error({message: 'User update failed.'})
                                }
                            }}
                        />
                    </Modal>
                </div>

            }
        </AuthContext.Consumer>
    </Fragment>
}