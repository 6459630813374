import React, {Fragment} from "react";
import {Card, Collapse, Space} from "antd";
import {OverallQCInspections} from "./SummaryReport";
import {
    COUNT, fillColor,
    graphValues,
    JOINT_BREAKDOWN,
    JOINTS,
    JOINTS_IN_DETAIL,
    MEMBERS,
    MEMBERS_IN_DETAIL,
    NO_OF_INSPECTED,
    OUT_OF_CONFORMANCE,
    OVERVIEW_AS_PERCENTAGE,
    PASS,
    PLATES_UPSIZED,
    PLATES_UPSIZED_OVERVIEW,
    PRELIMINARY,
    PRELIMINARY_BREAKDOWN,
    PRELIMINARY_IN_DETAIL,
    summaryReports,
    TRUSSES,
    TRUSSES_IN_DETAIL
} from "../../utils/reportConstants";
import {Bullet, Column, Pie} from "@ant-design/plots";
import {processTitle} from "../../utils/report";

interface OverviewBody {
    total: number
    out: number
    percent: number
}

interface OverviewProps {
    title: string
    data: OverviewBody
    isPDFMode?: boolean
}

export const Overview: React.FunctionComponent<OverviewProps> = ({title, data, isPDFMode}) => {
    const {total, out, percent} = data

    return <Fragment>
        <Space>
            <Card type={"inner"} title={`${title} Overview`} style={{width: isPDFMode?350:'30vw', pageBreakInside: "avoid"}}>
                <Column
                    color={fillColor}
                    style={{height: isPDFMode?350:undefined}}
                    xField={'name'}
                    yField={'count'}
                    yAxis={{
                        title: {
                            text: COUNT
                        }
                    }}
                    data={[
                        {
                            name: `${NO_OF_INSPECTED} ${title}`,
                            count: total
                        },
                        {
                            name: OUT_OF_CONFORMANCE,
                            count: out
                        }
                    ]}
                />
            </Card>
            <Card type={"inner"} title={OVERVIEW_AS_PERCENTAGE} style={{width: isPDFMode?300:'30vw', pageBreakInside: "avoid"}}>
                <Pie
                    style={{height: isPDFMode?350:undefined}}
                    angleField={'count'}
                    colorField={'name'}
                    radius={0.8}
                    label={{
                        type: 'inner',
                        offset: '-30%',
                        content: ({ count }) => `${count}%`,
                        style: {
                            fontSize: 14,
                            textAlign: 'center',
                        },
                    }}
                    legend={{
                        layout: 'vertical',
                        position: isPDFMode?'bottom':'right'
                    }}
                    tooltip={{
                        formatter: ({name, count}) => ({ name, value: `${count}%` })
                    }}
                    data={[
                        {
                            'name': OUT_OF_CONFORMANCE,
                            'count': percent
                        },
                        {
                            'name': `${PASS} ${title}`,
                            'count': parseFloat((100 - percent).toFixed(2))
                        }
                    ]}
                />
            </Card>
        </Space>
    </Fragment>
}

interface BreakdownProps {
    data: any
    isPDFMode?: boolean
}

export const Breakdown: React.FunctionComponent<BreakdownProps> = ({data, isPDFMode}) => {

    const positionOfGraphValues = 'bottom'
    const certificationReportLabelColor = '#566573'
    return <Column
        color={fillColor}
        xField={'name'}
        yField={'count'}
        yAxis={{
            title: {
                text: COUNT
            }
        }}
        label={{
            content: ({percent}) => `${percent || 0}%`,
            position: positionOfGraphValues,
            style: {
                fill: certificationReportLabelColor,
                OPACITY: graphValues.OPACITY
            },
        }}
        data={Object.entries(data)?.map((field: any) => ({
            name: isPDFMode?field[0].substring(0, 3):processTitle(field[0]),
            count: field[1].count,
            percent: field[1].percent
        }))}
    />
}

export interface SummaryByCrew {
    id: number
    name: string
    is_active: boolean
    inspection: any
    preliminary: any
    joint: any
    member: any
    upsized: any
}

interface CrewSummaryReportProps {
    overallQCInspections: any
    crewSummary: SummaryByCrew[]
}

const CrewSummaryReport: React.FunctionComponent<CrewSummaryReportProps> = ({overallQCInspections, crewSummary}) => {

    const { Panel } = Collapse
    return <Collapse defaultActiveKey={['overallQCInspections']}>
        {overallQCInspections && <Panel key={'overallQCInspections'} header={summaryReports.OVERALL_QC_INSPECTIONS} style={{margin: 5}}>
            <OverallQCInspections overallQCInspections={overallQCInspections}/>
        </Panel>}
        {crewSummary?.map(({id, name, is_active, inspection, preliminary, joint, member, upsized}) => <Panel key={id} header={name} style={{margin: 5}}>
            <Card title={TRUSSES_IN_DETAIL}>
                <Overview title={TRUSSES} data={inspection}/>
            </Card>
            <Card title={PRELIMINARY_IN_DETAIL}>
                <Overview title={PRELIMINARY} data={preliminary}/>
                <Card type={"inner"} title={PRELIMINARY_BREAKDOWN}>
                    <Breakdown data={preliminary.breakdown}/>
                </Card>
            </Card>
            <Card title={JOINTS_IN_DETAIL}>
                <Overview title={JOINTS} data={joint}/>
                <Card type={"inner"} title={JOINT_BREAKDOWN}>
                    <Breakdown data={joint.breakdown}/>
                </Card>
            </Card>
            <Card title={MEMBERS_IN_DETAIL}>
                <Overview title={MEMBERS} data={member}/>
            </Card>
            <Card title={PLATES_UPSIZED}>
                <Space>
                    <Card type={"inner"} title={PLATES_UPSIZED_OVERVIEW} style={{width: '30vw'}}>
                        <Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'Total plates'}
                            xField={'title'}
                            data={[
                                {
                                    title: PLATES_UPSIZED,
                                    ranges: [upsized.total],
                                    measures: [upsized.upsized],
                                    'Total plates': upsized.total
                                }
                            ]}
                        />
                    </Card>
                    <Card type={"inner"} title={OVERVIEW_AS_PERCENTAGE+' %'} style={{width: '30vw'}}>
                        <Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'Total plates'}
                            xField={'title'}
                            data={[
                                {
                                    title: PLATES_UPSIZED,
                                    ranges: [100],
                                    measures: [upsized.percent],
                                    'Total plates': 100
                                }
                            ]}
                        />
                    </Card>
                </Space>
            </Card>
        </Panel>)}
    </Collapse>
}

export default CrewSummaryReport