import React, {Fragment, useCallback, useEffect, useState, useRef} from "react";
import {
    clearDefault,
    configs,
    fetch,
    fetchQCManual,
    FetchRequest,
    insert,
    insertOneForEachCompanyLocation,
    update,
    updateDefault,
    uploadQCManual
} from "../api";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Form as AntdForm,
    Input,
    InputNumber,
    message,
    Modal,
    notification,
    PageHeader,
    Popconfirm,
    Radio,
    Row,
    Space,
    Tabs,
    Typography,
    Upload
} from "antd";
import {CheckOutlined, CloseOutlined, EditOutlined, FilePdfOutlined, UploadOutlined} from "@ant-design/icons";
import {ConfigResponse, Response, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {Form} from "@q4us-sw/q4us-ui";
import {unstable_batchedUpdates} from "react-dom";
import CertifiedSettings from "./CertifiedSettings";
import {AuthContext} from "./user/AuthProvider";
import {getOrdering} from "./filters/ordering";
import {LocationsFilter, LocationsFilterBaseProps} from "./filters/LocationsFilter";
import {handleScroll} from "../utils/util";
import SingleSelectCustomCheckBox from "./lib/SingleSelectCustomCheckBox";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";

interface HeaderProps {
    title: string
    schema: string
    company_id?: number
    location_id?: number
    defAvailSetting: any
}

export interface SettingsProps extends LocationsFilterBaseProps{

}

const fetchSettings = (request:FetchRequest, companyID?:number, locationID?:number) => {
    let modifiedFilter = [{name: 'company_id', value: companyID}, {name: 'location_id', value: locationID}];
    if (request.filter){
        modifiedFilter = modifiedFilter.concat(request.filter);
    }
    return fetch({...request, filter:modifiedFilter});
}

const getDataPayload = (request:any, schema:string, filterOverride?: any) => {
    const column = request?.options?.inbuilt?.sort.column || 'id'
    const direction = request?.options?.inbuilt?.sort.direction || 'asc'
    const orderBy = getOrdering(column, direction);
    return {
        tableName: schema,
        orderBy: orderBy,
        filter: filterOverride || request?.options?.values,
        page: {size: request?.page?.size || 100, from: ((request?.page?.current || 1) - 1) * (request?.page?.size || 100)}
    }
}

const Header: React.FunctionComponent<HeaderProps> = ({title, schema, company_id, location_id, defAvailSetting}) => {
    const [open, setOpen] = useState(false)

    const openSetupDialog = (company_id: any, location_id: any) => {
        if (company_id && location_id) {
            setOpen(true)
        } else {
            notification.info({message: 'Please select company and location first'})
        }
    }

    return <div style={{paddingRight: 7, width: '100%'}}>
        <Row style={{textAlign: 'right'}}>
            <Col span={12}>
                <PageHeader title={title}/>
            </Col>
            <Col span={12}>
                <TableContext.Consumer>{
                    table=><React.Fragment>
                        <Space>
                            <Checkbox
                                onChange={async (e) => {
                                    table?.setLoading(true);
                                    let modified = undefined;
                                    if(e.target.checked){
                                        modified = [{...table?.customData?.values, ...{name:'is_active', value:e.target.checked}}];
                                    }
                                    else{
                                        modified = table?.customData?.values?.filter((item:any)=>item.name!=='is_active');
                                    }
                                    const request = getDataPayload({options:table?.customData, page:table?.page}, schema, modified);
                                    const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                    table?.setCustomData({...table?.customData, values:modified});
                                    table?.setData(rows);
                                    table?.setPage({
                                        current: page?.current ?? table?.page?.current ?? 0,
                                        total: page?.total ?? table?.page?.total ?? 0,
                                        size: page?.size ?? table?.page?.size ?? 100
                                    });
                                    table?.setLoading(false);
                                }}
                            >Hide Inactive</Checkbox>
                            <Button type={"primary"} onClick={()=>{openSetupDialog(company_id, location_id)}}>{`Add ${title}`}</Button>
                            <Button onClick={async () => {
                                if (company_id && location_id){
                                    const {data: {statusCode, message}} = await clearDefault({
                                        tableName: schema,
                                        company_id,
                                        location_id
                                    })
                                    if(statusCode===200){
                                        table?.setLoading(true);
                                        const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                                        const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                        table?.setData(rows);
                                        table?.setPage({
                                            current: page?.current ?? table?.page?.current ?? 0,
                                            total: page?.total ?? table?.page?.total ?? 0,
                                            size: page?.size ?? table?.page?.size ?? 100
                                        });
                                        table?.setLoading(false);
                                        defAvailSetting.current = false;
                                        notification.success({message: `Default has reset`})
                                    } else{
                                        notification.error({message: message || 'Error'})
                                    }
                                } else{
                                    notification.info({message: 'Please select company and location first'})
                                }
                            }}>Clear default</Button>
                        </Space>
                        <Modal
                            title={`Add New ${title}`}
                            width={'50vw'}
                            visible={open}
                            onCancel={()=>setOpen(false)}
                            footer={null}
                            destroyOnClose
                        >
                            <Form
                                schema={schema}
                                initialValue={{is_active: true}}
                                maxHeight={'60vh'}
                                acceptText={'Submit'}
                                config={async () => {
                                    const res = await configs(schema)
                                    return res.data.rows;
                                }}
                                submit={async (values:any) => {
                                    if(company_id && location_id){
                                        const {data: {statusCode, message} = {} } = await insert({
                                            tableName: schema,
                                            data: {...values, company_id, location_id}
                                        })
                                        if(statusCode===200){
                                            table?.setLoading(true);
                                            const request = getDataPayload({options:table?.customData, page:table?.page},schema)
                                            const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                            table?.setData(rows);
                                            table?.setPage({
                                                current: page?.current ?? table?.page?.current ?? 0,
                                                total: page?.total ?? table?.page?.total ?? 0,
                                                size: page?.size ?? table?.page?.size ?? 100
                                            });
                                            table?.setLoading(false);
                                            notification.success({message: 'Success'})
                                            setOpen(false)
                                            return true
                                        } else{
                                            notification.error({message: `Error in creating ${title}` || 'Error'})
                                            return false
                                        }
                                    } else{
                                        notification.info({message: 'Please select company and location first'})
                                        return false
                                    }
                                }}
                                validateField={(rule: any, value: any, schema: string, column: FormElementProps, form: FormInstance<any>) => {
                                    switch (column.name) {
                                        case "is_default": {
                                            if (form.getFieldValue('is_default') && defAvailSetting.current
                                            ) {
                                                return Promise.reject('Default value already available')
                                            }
                                        }
                                    }
                                    return Promise.resolve()
                                }}
                            />
                        </Modal>
                    </React.Fragment>
                }</TableContext.Consumer>
            </Col>
        </Row>
        {title==='Setups'&&<Row style={{margin: 10}}>
            <Alert
                message="When selecting a Truss Type, Jack tables are considered Roof Trusses and Splice tables are neither."
                type="info"
                showIcon
            />
        </Row>}
    </div>
}

interface FieldEditProps {
    schema: string
    value: any,
    row: any
    index: number
    companyID?: number
    locationID?: number
}

const FieldEdit: React.FunctionComponent<FieldEditProps> = ({schema,value, row, index, companyID, locationID}) => {
    const [isEdit, setIsEdit] = useState(false)
    const [currentRow, setCurrentRow] = useState<number>()
    const [currentValue, setCurrentValue] = useState<string>()

    const {Text} = Typography

    const btnStyle: any = {margin: 5, float: "left"}

    return <TableContext.Consumer>
        {table => <Fragment>
            {
                (isEdit && index===currentRow)?
                    <Fragment>
                        <Button style={btnStyle} icon={<CheckOutlined />} onClick={async () => {
                            const {data: {statusCode, message}} = await update({
                                tableName: schema,
                                data: {...row, name: currentValue}
                            })
                            if(statusCode===200){
                                const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                                const {data: {rows = [], page} = {}} = await fetchSettings(request, companyID, locationID);
                                table?.setData(rows);
                                table?.setPage({
                                    current: page?.current ?? table?.page?.current ?? 0,
                                    total: page?.total ?? table?.page?.total ?? 0,
                                    size: page?.size ?? table?.page?.size ?? 100
                                });
                                notification.success({message: `${row?.name} changed as ${currentValue}`})
                                setIsEdit(false)
                            } else{
                                notification.error({message: message || 'Error'})
                            }
                        }}/>
                        <Button style={btnStyle} icon={<CloseOutlined />} onClick={() => {
                            setIsEdit(false)
                        }}/>
                    </Fragment>
                    :
                    <Button style={btnStyle} icon={<EditOutlined />} onClick={() => {
                        unstable_batchedUpdates(() => {
                            setCurrentRow(index)
                            setIsEdit(true)
                        })
                    }}/>
            }
            {
                (isEdit && index===currentRow)?
                    <Input
                        style={{margin: 5, maxWidth: 200, float: "left"}}
                        defaultValue={value}
                        onChange={((e) => {
                            setCurrentValue(e.target.value)
                        })}
                    />
                    :
                    <Text style={{margin: 5, float: "left"}}>{value}</Text>
            }
        </Fragment>}
    </TableContext.Consumer>
}

interface SettingProps {
    title: string,
    name: string,
    schema: string
    company_id?: number
    location_id?: number
    defAvailObj: Object
}

const Setting: React.FunctionComponent<SettingProps> = ({title, name, schema, company_id, location_id, defAvailObj}) => {
    const defAvailSetting: any = defAvailObj[title as keyof typeof defAvailObj]
    return <TableV2
        schema={schema}
        header={<Header title={title} schema={schema} company_id={company_id} location_id={location_id} defAvailSetting={defAvailSetting}/>}
        fetchConfig={async () => {
            const response = await configs(schema) || {}
            return response as ConfigResponse
        }}
        fetchData={async (request) => {
            handleScroll()
            const payload = getDataPayload(request, schema)
            const response = await fetchSettings(payload, company_id, location_id)
            return response as Response
        }}
        scroll={{y: 'calc(100vh - 585px)', x: '100%'}}
        customRenderer={{
            name: (value, row, index, column) => <FieldEdit schema={schema} value={value} row={row} index={index} companyID={company_id} locationID={location_id}/>,
            is_active: (value, row, index, column) => {
                return <TableContext.Consumer>
                    {table => <Checkbox
                        checked={value}
                        onChange={async (e) => {
                            const {data: {statusCode, message}} = await update({
                                tableName: schema,
                                data: {...row, is_active: e.target.checked}
                            })
                            if(statusCode===200){
                                const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                                const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                table?.setData(rows);
                                table?.setPage({
                                    current: page?.current ?? table?.page?.current ?? 0,
                                    total: page?.total ?? table?.page?.total ?? 0,
                                    size: page?.size ?? table?.page?.size ?? 100
                                });
                                notification.success({message: `${row?.name} ${e.target.checked?'activated.':'inactivated'}`})
                            } else{
                                notification.error({message: message || 'Error'})
                            }
                        }}
                    />}
                </TableContext.Consumer>
            },
            is_default: (value, row, index, column) => {
                if (value == true) {
                    // To set the settingRef to true if at least one row is default.
                    defAvailSetting.current = true;
                }
                return <TableContext.Consumer>
                    {table => <Radio checked={value} onChange={async () => {
                        if (company_id && location_id){
                            const {data: {statusCode, message}} = await updateDefault({
                                tableName: schema,
                                id: row?.id,
                                company_id,
                                location_id
                            })
                            if(statusCode===200){
                                const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                                const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                                table?.setData(rows);
                                table?.setPage({
                                    current: page?.current ?? table?.page?.current ?? 0,
                                    total: page?.total ?? table?.page?.total ?? 0,
                                    size: page?.size ?? table?.page?.size ?? 100
                                });
                                notification.success({message: `${row?.name} set as default`})
                            } else{
                                notification.error({message: message || 'Error'})
                            }
                        } else{
                            notification.info({message: 'Please select company and location first'})
                        }
                    }}/>}
                </TableContext.Consumer>
            },
            truss_type: (value, row, index, column) =>{
                return <TableContext.Consumer>{table=><SingleSelectCustomCheckBox
                    value={value}
                    options={column.enums||[]}
                    onChange={async (value) => {
                        const {data: {statusCode, message}} = await update({
                            tableName: schema,
                            data: {...row, truss_type: value}
                        })
                        if(statusCode===200){
                            const request = getDataPayload({options:table?.customData, page:table?.page}, schema);
                            const {data: {rows = [], page} = {}} = await fetchSettings(request, company_id, location_id);
                            table?.setData(rows);
                            table?.setPage({
                                current: page?.current ?? table?.page?.current ?? 0,
                                total: page?.total ?? table?.page?.total ?? 0,
                                size: page?.size ?? table?.page?.size ?? 100
                            });
                            notification.success({message: `${row?.name} Updated`})
                        } else{
                            notification.error({message: message || 'Error'})
                        }
                    }}/>}</TableContext.Consumer>
            }
        }}
    />
}

interface GeneralProps {
    company_id?: number
    location_id?: number
}

interface FieldData {
    company_id: number | undefined
    location_id: number | undefined
    id?: number
    start_day_of_week?: number | null
    floor_span_tolerance?: number | null
    floor_height_tolerance?: number | null
    roof_span_tolerance?: number | null
    roof_height_tolerance?: number | null
}

const General: React.FunctionComponent<GeneralProps> = ({company_id, location_id}) => {
    const [fieldData, setFieldData] = useState<FieldData>({company_id, location_id});
    const [isInputEmpty, setIsInputEmpty] = useState(true);

    const handleInputChange = (value: any, key: any) => {
        setFieldData({ ...fieldData, [key]: value });
        const isAnyInputFilled = Object.values(fieldData).some(val => val !== null);
        setIsInputEmpty(!isAnyInputFilled);
    };

    const load = async () => {
        const {data: {rows = []} = {}} = await fetch({
            tableName: 'general',
            filter: [
                {name: 'company_id', value: company_id},
                {name: 'location_id', value: location_id}
            ]
        })
        setFieldData({...fieldData, ...rows[0]})
    }

    useEffect(() => {
        load()
    }, [])

    const {Text, Title} = Typography;

    const daysOfWeek = Object.freeze({
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
    })

    const clear = () => {
        setFieldData({
            ...fieldData,
            start_day_of_week: null,
            floor_span_tolerance: null,
            floor_height_tolerance: null,
            roof_span_tolerance: null,
            roof_height_tolerance: null,
        })
        setIsInputEmpty(true);
    }

    return <Fragment>
        <PageHeader title={'General'}/>
        <Title level={4}>Interface</Title>
        <Space style={{margin: 5, marginBottom: 20}}>
            <Text strong>Starting day of the week</Text>
            <Radio.Group value={fieldData?.start_day_of_week} onChange={(e) => setFieldData({...fieldData, start_day_of_week: e.target.value})}>
                {
                    Object.entries(daysOfWeek)
                        .map(
                            ([key, value]) =>
                                <Radio.Button style={{color: key==='0'?'red':undefined}} value={parseInt(key)}>{value}</Radio.Button>
                        )
                }
            </Radio.Group>
        </Space>
        <Title level={4}>Plant Specified Tolerances</Title>
        <Text>(ANSI/TPI 1 Tolerances: Length/Span ¾ In; Height ½ In)</Text><br/>
        <Text>(ANSI/TPI 1 Commentary Recommendations for Floor Trusses: Length/Span ¼ In, Height 1/8 In)</Text>
        <Row style={{marginTop: 15, marginLeft: '15vw', maxWidth: '50vw'}}>
            <Col span={12}>
                <Title level={5}>Floor</Title>
                <Space style={{margin: 5}}>
                    <Text style={{marginRight: 11}} strong>Span Tolerance</Text>
                    <InputNumber
                        min={0}
                        value={fieldData?.floor_span_tolerance}
                        onChange={(value) => handleInputChange(value, 'floor_span_tolerance')}
                        addonAfter="/16ths"
                    />
                </Space>
                <Space style={{margin: 5}}>
                    <Text strong>Height Tolerance</Text>
                    <InputNumber
                        min={0}
                        value={fieldData?.floor_height_tolerance}
                        onChange={(value: number | null) => handleInputChange(value, 'floor_height_tolerance')}
                        addonAfter="/16ths"
                    />
                </Space>
            </Col>
            <Col span={12}>
                <Title level={5}>Roof</Title>
                <Space style={{margin: 5}}>
                    <Text style={{marginRight: 11}} strong>Span Tolerance</Text>
                    <InputNumber
                        min={0}
                        value={fieldData?.roof_span_tolerance}
                        onChange={(value: number | null) => handleInputChange(value, 'roof_span_tolerance')}
                        addonAfter="/16ths"
                    />
                </Space>
                <Space style={{margin: 5}}>
                    <Text strong>Height Tolerance</Text>
                    <InputNumber
                        min={0}
                        value={fieldData?.roof_height_tolerance}
                        onChange={(value: number | null) => handleInputChange(value, 'roof_height_tolerance')}
                        addonAfter="/16ths"
                    />
                </Space>
            </Col>
        </Row>
        <Space style={{margin: 10, marginTop: 20, marginRight: '20vw', float: "right"}}>
            <Button onClick={() => {
                clear()
            }}>Clear</Button>
            <Button disabled={isInputEmpty} type={"primary"} onClick={async () => {
                if(company_id && location_id){
                    const {data: {statusCode, message} = {} } = await insertOneForEachCompanyLocation({
                        tableName: 'general',
                        data: fieldData
                    })
                    if(statusCode===200){
                        notification.success({message: 'Settings are saved successfully'})
                    } else{
                        notification.error({message: message || 'Error'})
                    }
                } else{
                    notification.info({message: 'Please select company and location first'})
                    return false
                }
            }}>Save</Button>
        </Space>
    </Fragment>
}

interface File {
    oid: number
    filename: string
}

interface UploadQCManualProps {
    id: number | undefined
}

const UploadQCManual: React.FunctionComponent<UploadQCManualProps> = ({id}) => {
    const [form] = AntdForm.useForm()
    const [open, setOpen] = useState<boolean>()
    const [file, setFile] = useState<File>()

    const loadQCFile = useCallback(async () => {
        if(id) {
            const {data: {rows: [row], rowCount}} = await fetch({tableName: 'locations', filter: [{name: 'id', value: id}]})
            if(rowCount>0) {
                setFile({oid: row.oid, filename: row.filename})
            } else {
                return Promise.reject("Error from fetching location data")
            }
        }
    }, [id])

    useEffect(() => {
        loadQCFile()
    }, [id])

    const handleSubmit = useCallback(async () => {
        await form.validateFields()
        const {status} = await update({tableName: 'locations', data: {id, ...file}})
        if(status===200) {
            notification.success({message: 'File Saved'})
            setOpen(false)
        }
    }, [id, form, file])

    const handleCancel = useCallback(async () => {
        form.resetFields()
        setFile(undefined);
        setOpen(false)
    }, [form])

    const { Item } = AntdForm
    return <Fragment>
        <Space style={{width: "max-content"}}>
            <Typography.Link disabled={!file || !file.oid || !id} onClick={() =>fetchQCManual(file?file.oid:0, file?file.filename:'')}>
                {file?.filename?file.filename:'QC Manual'} <FilePdfOutlined />
            </Typography.Link>
            {file?.filename?<Popconfirm
                title="QC Manual already exists. Do you want to replace it?"
                onConfirm={() => setOpen(true)}
                okText="Yes"
                cancelText="No"
            >
                <Button disabled={!id} icon={<UploadOutlined/>}>Upload QC Manual</Button>
            </Popconfirm>:<Button disabled={!id} icon={<UploadOutlined/>} onClick={() => setOpen(true)}>Upload QC Manual</Button>}
        </Space>
        <Modal
            title={'Upload QC Manual'}
            visible={open}
            onCancel={handleCancel}
            okText={'Save'}
            onOk={handleSubmit}
            centered
            destroyOnClose
        >
            <AntdForm
                form={form}
                name={'upload_qc_manual'}
            >
                <Item
                    label={'QC Manual'}
                    name={'upload'}
                    extra={'Allowed file types are .pdf, .doc, .docx'}
                    rules={
                        [
                            {
                                required: true,
                                message: 'Please select a file'
                            }
                        ]
                    }
                >
                    <Upload
                        maxCount={1}
                        defaultFileList={file?.filename?[{uid: '1', name: file?.filename, status: "done"}]:undefined}
                        showUploadList={{showRemoveIcon: false}}
                        beforeUpload={(async uploadFile => {
                            if(uploadFile.type==='application/pdf' || uploadFile.type==='application/vnd.openxmlformats-officedocument.wordprocessingml.document' || uploadFile.type==='application/msword') {
                                return true
                            } else {
                                message.error('Invalid file type')
                                return Upload.LIST_IGNORE
                            }
                        })}
                        customRequest={async opt => {
                            const formData = new FormData()
                            // @ts-ignore
                            formData.append(opt.file.name, opt.file)
                            const {data, status} = await uploadQCManual(formData)
                            if(status===200) {
                                message.success('File Uploaded')
                                // @ts-ignore
                                setFile({oid: data, filename: opt.file.name})
                                // @ts-ignore
                                opt.onSuccess(data, opt.file)
                            }
                        }}
                    >
                        <Button>Select a File</Button>
                    </Upload>
                </Item>
            </AntdForm>
        </Modal>
    </Fragment>
}

export const Settings: React.FunctionComponent<SettingsProps> = (props) => {
    const [isCertified, setIsCertified] = useState<boolean | null>()

    // Whether default values ara available.
    const defSetupAvail = useRef(false)
    const defShiftAvail = useRef(false)
    const defCrewAvail = useRef(false)

    const defAvailObj = {
        "Setups" : defSetupAvail,
        "Crews" : defShiftAvail,
        "Shifts" : defCrewAvail
    }

    const tabs = [
        {
            tabTitle: 'Setups',
            tabKey: 'lines',
            schema: 'line'
        },
        {
            tabTitle: 'Shifts',
            tabKey: 'shifts',
            schema: 'shift'
        },
        {
            tabTitle: 'Crews',
            tabKey: 'crews',
            schema: 'crew'
        }
    ]

    const {TabPane} = Tabs
    return <Fragment>
        <PageHeader title={'Settings'}/>
        <Space>
            <LocationsFilter
                locationID={props.locationID}
                companyID={props.companyID}
                setLocationID={props.setLocationID}
                setCompanyID={props.setCompanyID}
                companies={props.companies}
                locations={props.locations}
                onChange={(location, locationList)=>{
                    setIsCertified(locationList?.find(({id}) => id===location)?.['is_certified']);
                    defSetupAvail.current = false;
                    defShiftAvail.current = false;
                    defCrewAvail.current = false;
                }}
                disabledValidatorCompany={(user)=>{
                    return user.user_role !== 'administrator'
                }}
                disabledValidatorLocation={(user)=>{
                    return !(user.user_role === 'administrator' || user.user_role === 'district_manager' || user.user_role === 'regional_manager')
                }}
            />
            <UploadQCManual id={props.locationID}/>
        </Space>
        <AuthContext.Consumer>
            {auth => <Tabs defaultActiveKey='lines'>
                {tabs.map(({tabTitle, tabKey, schema})=><TabPane tab={tabTitle} key={tabKey}>
                    <Setting key={props.locationID} title={tabTitle} name={tabKey} schema={schema} company_id={props.companyID} location_id={props.locationID} defAvailObj={defAvailObj}/>
                </TabPane>)}
                <TabPane tab={'General'} key={'general'}>
                    <General key={props.locationID} company_id={props.companyID} location_id={props.locationID}/>
                </TabPane>
                {auth?.user?.user_role==='qc_manager' && isCertified && <TabPane tab={'Certified'} key={'certified'}>
                    <CertifiedSettings key={props.locationID} type={'general'} location_id={props.locationID}/>
                </TabPane>}
            </Tabs>}
        </AuthContext.Consumer>
    </Fragment>
}

export default Settings;