import React, {Fragment, useEffect, useState} from "react";
import {Page, Response, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {configs, fetch, fetchDailyHours, fetchWithoutPageData} from "../api";
import {Button, Col, notification, PageHeader, Row, Space, Typography} from "antd";
import moment from "moment";
import {MapToFilter} from "./filters/mapping";
import {TableFilter} from "./filters/TableFilter";
import {CustomFormInput, FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {useHistory,} from "react-router-dom";
import {getOrdering} from "./filters/ordering";
import {MetaProps} from "./report/Reports";
import {AuthContext} from "./user/AuthProvider";
import {unstable_batchedUpdates} from "react-dom";
import {WEEKDAY} from "../utils/constants";
import { ConfigsContext } from "./configs/ConfigsProvider";
import {DateRangePickerFormatted} from "./lib/DatePickerFormatted";
import {LocationsFilter} from "./filters/LocationsFilter";
import {handleScroll} from "../utils/util";


const fetchDailyHoursFilteredData = async (values: { [key: string]: any }, page?: Page, company_id?: number,
                                           location_id?: number, {column='id', direction='desc'}:{column?:string, direction?:string}={}) => {
    const filters = MapToFilter(values)
    const orderBy = getOrdering(column, direction);
    return await fetch({
        tableName: 'daily_hours',
        orderBy: orderBy,
        filter: [...filters, {name: 'company_id', value: company_id}, {name: 'location_id', value: location_id}],
        page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
    }) || {}
}

interface Enums {
    shifts: any
    lines: any
    crews: any
}

interface DailyHoursTableHeaderProps{
    schema: string
    enums: Enums | undefined
    onFilter: (values: any) => void
    companyID?: number
    locationID?: number
}

const DailyHoursTableHeader: React.FunctionComponent<DailyHoursTableHeaderProps> = ({
                                                                                        schema,
                                                                                        companyID,
                                                                                        locationID,
                                                                                        enums,
                                                                                        onFilter
                                                                                    }) => {

    const fetchWrapper = async (values: { [key: string]: any }, page?: Page, sort?:{column:string,direction:string}) => {
        return await fetchDailyHoursFilteredData(values, page, companyID, locationID, sort)
    }

    return <div style={{paddingLeft: 12, paddingRight: 12, paddingTop: 12, paddingBottom: 12, width: '100%'}}>
        <TableFilter
            schema={schema}
            fetch={fetchWrapper}
            notifyLocation={Boolean(!locationID)}
            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                if (element.name === 'shift_id') {
                    // @ts-ignore
                    return <CustomFormInput
                        type={'STRING'}
                        title={element.title}
                        name={element.name}
                        schema={schema}
                        enums={enums?.shifts}
                    />
                } else if (element.name === 'line_id') {
                    // @ts-ignore
                    return <CustomFormInput
                        type={'STRING'}
                        title={element.title}
                        name={element.name}
                        schema={schema}
                        enums={enums?.lines}
                    />
                } else if (element.name === 'crew_id') {
                    // @ts-ignore
                    return <CustomFormInput
                        type={'STRING'}
                        title={element.title}
                        name={element.name}
                        schema={schema}
                        enums={enums?.crews}
                    />
                }
                else if (element.name === 'job_date_range'){
                    return <DateRangePickerFormatted/>
                }
            }}
            onFilter={values => onFilter(values)}
        />
    </div>
}

interface DailyHoursTableProps{
    schema: string
    enums: Enums | undefined
    onFilter: (values: any) => void
    companyID?: number
    locationID?: number
}

const DailyHoursTable: React.FunctionComponent<DailyHoursTableProps> = ({
                                                                            schema,
                                                                            companyID,
                                                                            locationID,
                                                                            enums,
                                                                            onFilter
                                                                        }) => {
    const table_filter_schema = 'daily_hours_filter'
    // const history = useHistory()

    return <TableV2
        // key={refresh}
        schema={schema}
        maxColumnWidth={400}
        header={DailyHoursTableHeader({
            schema: table_filter_schema,
            companyID,
            locationID,
            enums,
            onFilter: values => onFilter(values)
        })}
        fetchConfig={async (schema) => {
            return await configs(schema)
        }}
        fetchData={async (data_request) => {
            handleScroll()
            if(companyID && locationID) {
                const response = await fetchDailyHoursFilteredData(data_request.options.values || {}, data_request.page, companyID, locationID, data_request.options.inbuilt?.sort)
                return response as Response
            } else {
                return {
                    data: {
                        statusCode: 200,
                        rows: []
                    }
                }
            }
        }}
        customRenderer={{
            job_day: (value, row, index, column) => {
                return <Typography.Text>{WEEKDAY[moment(row.job_date).utc().day()]}</Typography.Text>
            },
            job_date: (value, row, index, column) => {
                return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).utc().format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer>
            },
            created_at: (value, row, index, column) => {
                return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateTimeFormat)}</Typography.Text>}</ConfigsContext.Consumer>
            }
        }}
        scroll={{y: 'calc(100vh - 537px)', x: '100%'}}
    />
}

export const DailyHours: React.FunctionComponent<any> = (props: any) => {
    const daily_hours_table = 'daily_hours'
    const history = useHistory()

    const [enums, setEnums] = useState<Enums>()
    const [enumList, setEnumList] = useState<Enums>()
    const [filter, setFilter] = useState<any>()
    const [meta, setMeta] = useState<MetaProps>({
        start_date: undefined,
        end_date: undefined
    })

    const redirectAddDailyHours = async (user: string, isDaily: boolean) => {
        if (props.companyID && props.locationID) {
            if(enumList?.shifts.length>0 && enumList?.lines.length>0 && enumList?.crews.length>0) {
                history.push({
                    pathname: '/view/add-daily-hours',
                    state: {
                        enumList,
                        isDaily
                    }
                })
            } else {
                notification.info({message: 'Please add shift, line & crew before add hours'})
            }
        } else {
            notification.info({message: 'Please select company and location first'})
        }
    }

    const fetchEnums = async (tableName: string, locationIdValue: number) => {
        const orderBy = getOrdering('name', 'asc');
        const payload = {
            tableName,
            orderBy: orderBy,
            filter: [{name: 'location_id', value: locationIdValue}],
            returns: ['id', 'name', 'is_active']
        }
        const {data: {rows = []} = {}} = await fetchWithoutPageData(payload)
        return rows
    }

    const updateEnums = async(value?:number) => {
        if(value){
            const shifts = fetchEnums('shift', value);
            const lines = fetchEnums('line', value);
            const crews = fetchEnums('crew', value);
            const [shiftList, lineList, crewList] = await Promise.all([shifts, lines, crews]);

            const processData = (rows: any, isActive: boolean) => {
                let filteredRows = []
                if(isActive) {
                    filteredRows = rows?.filter((row: any) => row.is_active===true)
                } else {
                    filteredRows = rows
                }
                return filteredRows?.map((setting: any) => [setting.id.toString(), setting.name])
            }

            unstable_batchedUpdates(() => {
                setEnums({
                    shifts: Object.fromEntries(processData(shiftList, false)),
                    lines: Object.fromEntries(processData(lineList, false)),
                    crews: Object.fromEntries(processData(crewList, false))
                })
                setEnumList({
                    shifts: processData(shiftList, true),
                    lines: processData(lineList, true),
                    crews: processData(crewList, true)
                })
            })
        }
        else{
            unstable_batchedUpdates(()=>{
                setEnums(undefined);
                setEnumList(undefined);
            })
        }
    }

    useEffect(()=>{
        updateEnums(props.locationID)
    }, [props.locationID])

    return <AuthContext.Consumer>
        {auth => <Fragment>
            <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
                <Row>
                    <Col span={12}>
                        <PageHeader title="Daily Hours"/>
                    </Col>
                    <Col span={12}>
                        <Space direction="horizontal">
                            <Button onClick={() => redirectAddDailyHours(auth.user?.email, true)}>{'Add Hours - Daily'}</Button>
                            <Button onClick={() => redirectAddDailyHours(auth.user?.email, false)}>{'Add Hours - Range'}</Button>
                            <ConfigsContext.Consumer>{configs=><Button
                                shape="round"
                                disabled={props.companyID==undefined || props.locationID==undefined}
                                onClick={async () => {
                                    if(filter?.job_start_date && filter?.job_end_date) {
                                            const {data: {rows = [], rowCount} = {}} = await fetchDailyHours({
                                                ...filter,
                                                company_id: props.companyID,
                                                location_id: props.locationID
                                            })
                                            if (rowCount > 0) {
                                                const reportData = rows?.map((row: any) => ({
                                                    shift: enums?.shifts[row.shift_id],
                                                    line: enums?.lines[row.line_id],
                                                    crew: enums?.crews[row.crew_id],
                                                    job_date: moment(row.job_date).format(configs.dateFormat),
                                                    job_day: WEEKDAY[moment(row.job_date).utc().day()],
                                                    hours: row.hours,
                                                    created: moment(row.created_at).format(configs.dateTimeFormat)
                                                }))
                                                history.push({
                                                    pathname: '/view/report-pdf',
                                                    state: {reportType: daily_hours_table, reportData, metaData: meta}
                                                })
                                            } else {
                                                notification.warning({message: 'No data to generate PDF'})
                                            }
                                    } else {
                                        notification.info({message: 'Please filter by date range first'})
                                    }
                                }}
                            >
                                Generate PDF
                            </Button>}</ConfigsContext.Consumer>
                        </Space>
                    </Col>
                </Row>
            </div>
            <LocationsFilter
                locationID={props.locationID}
                companyID={props.companyID}
                setLocationID={props.setLocationID}
                setCompanyID={props.setCompanyID}
                companies={props.companies}
                locations={props.locations}
                disabledValidatorCompany={(user)=>{
                    return user.user_role !== 'administrator'
                }}
                disabledValidatorLocation={(user)=>{
                    return !(user.user_role === 'administrator' || user.user_role === 'district_manager' || user.user_role === 'regional_manager')
                }}
            />
            <ConfigsContext.Consumer>{configs=>
                <DailyHoursTable
                    key={props.locationID}
                    schema={daily_hours_table}
                    companyID={props.companyID}
                    locationID={props.locationID}
                    enums={enumList}
                    onFilter={(values:any) => {
                        const {job_start_date, job_end_date} = values
                        delete values.job_date_range
                        unstable_batchedUpdates(() => {
                            setFilter({...values, job_start_date, job_end_date})
                            setMeta({
                                ...meta,
                                start_date: moment(job_start_date).format(configs.dateFormat),
                                end_date: moment(job_end_date).format(configs.dateFormat)
                            })
                        })
                    }}
            />}</ConfigsContext.Consumer>
        </Fragment>}
    </AuthContext.Consumer>
}
