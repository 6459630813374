import React from "react";
import {Typography} from "antd";
import {AuthContext} from "../user/AuthProvider";

const Unauthorized = () => {
    const { Title } = Typography;

    return <React.Fragment>
        <Title level={2} type={'danger'} >401: Unauthorized</Title>
    </React.Fragment>
}

export const Validate : React.FunctionComponent<{role:string[],warn?: boolean}> = (props)=>{
    return <AuthContext.Consumer>{auth =>
        <React.Fragment>
            { (auth.user === undefined) && <React.Fragment/>}
            { auth.user !== undefined && props.role.includes(auth.user?.user_role) &&  props.children}
            { (auth.user !== undefined && (!props.role.includes(auth.user?.user_role))) && props.warn &&  <Unauthorized/>}
        </React.Fragment>
    }</AuthContext.Consumer>

}

Validate.defaultProps  = {
    warn: false
}
