import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Page, TableV2, Response, TableContext} from "@q4us-sw/q4us-ui/lib/TableV2";
import {
    configs,
    fetch,
    fetch_inspectors,
    fetch_inspections,
    fetch_inspection,
    delete_inspection,
    // fetch_critical_joints,
    upload_critical_joint_images,
    fetch_critical_joints_extra,
    getInspectionCSV
} from "../api";
import {
    Button,
    Col,
    FormInstance,
    PageHeader,
    Row,
    Select,
    Space,
    Tag,
    Typography,
    Popconfirm,
    Input,
    Modal,
    Upload,
    Form as AntdForm, Form, message, Spin, Tooltip, UploadFile, Image,
} from "antd";
import type { SelectProps } from 'antd';
import { DownloadOutlined, DeleteOutlined, EditTwoTone, FilePdfOutlined, UploadOutlined } from '@ant-design/icons';
import moment from "moment";
import {MapToFilter} from "./filters/mapping";
import {getOrdering} from "./filters/ordering";
import {TableFilter} from "./filters/TableFilter";
import {unstable_batchedUpdates} from "react-dom";
import { AuthContext } from "./user/AuthProvider";
import {ConfigsContext} from "./configs/ConfigsProvider";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import DatePickerFormatted  from "./lib/DatePickerFormatted";
import {LocationsFilter, LocationsFilterBaseProps} from "./filters/LocationsFilter";
import {handleScroll} from "../utils/util";
import {AxiosResponse} from "axios";
// import {Validate} from "./lib/Unauthorized";
import JSZip from 'jszip';
import {DISABLED_DATE, DATE, TIMESTAMP_YEAR, QC_MANAGER} from "../utils/constants";
import {writeToCSV} from "../utils/functions";

export interface InspectionsProps extends LocationsFilterBaseProps{

}


const fetchInspectionFilteredData = async (values: {[key:string]: any}, page?: Page, location_id?:number, inspector_id?:number, {column='id', direction='desc'}:{column?:string, direction?:string}={})=>{
    const filters = MapToFilter(values)
    let start_year, end_year;
    filters.forEach((filter) => {
        if (filter.name === DATE) {
            const date = new Date(filter.value)
            if (filter.comparator === '>=') {
                start_year = {
                    name: TIMESTAMP_YEAR,
                    value: date.getFullYear(),
                    comparator: filter.comparator
                }
            } else if (filter.comparator === '<=') {
                end_year = {
                    name: TIMESTAMP_YEAR,
                    value: date.getFullYear(),
                    comparator: filter.comparator
                }
            }
        }
    })
    if (start_year) {
        filters.push(start_year)
    }
    if (end_year) {
        filters.push(end_year)
    }
    const orderBy = getOrdering(column, direction);
    return await fetch_inspections({
        locationId: location_id,
        inspectorId: inspector_id,
        orderBy: orderBy,
        filter: filters,
        page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
    }) || {}
}

const fetchInspectionFilteredDataForCSV = async (values: {[key:string]: any}, id?: number)=>{
    const filters = MapToFilter(values)
    let start_year, end_year;
    filters.forEach((filter) => {
        if (filter.name === 'date') {
            const date = new Date(filter.value)
            if (filter.comparator === '>=') {
                start_year = {
                    name: TIMESTAMP_YEAR,
                    value: date.getFullYear(),
                    comparator: filter.comparator
                }
            } else if (filter.comparator === '<=') {
                end_year = {
                    name: TIMESTAMP_YEAR,
                    value: date.getFullYear(),
                    comparator: filter.comparator
                }
            }
        }
    })
    if (start_year) {
        filters.push(start_year)
    }
    if (end_year) {
        filters.push(end_year)
    }
    return await getInspectionCSV(
        filters,
        id?.toString()
    ) || {}
}
interface InspectionsTableProps{
    schema: string
    inspector_id?: number
    locationID?: number
    setFilters: any
}

interface DeleteButtonProps{
    inspectionID: number
    fetchData:(values: {[key:string]: any}, page?: Page, sort?:{column:string,direction:string})=>Promise<AxiosResponse<any>>
    initialValue?: {[key:string]: any}
}

const DeleteButton:React.FunctionComponent<DeleteButtonProps> = ({inspectionID, fetchData, initialValue}) =>{
    return <AuthContext.Consumer>{auth=>
        auth.user && auth.user.user_role === 'administrator' &&
        <TableContext.Consumer>{table=>
            <Popconfirm
                placement="topRight"
                title="Are you sure want to delete the inspection"
                onConfirm={()=>new Promise(async(resolve) => {
                table?.setLoading(true);

                await delete_inspection(inspectionID);

                // re-fetch data
                const column = table?.customData?.options?.inbuilt?.sort.column || 'id';
                const direction = table?.customData?.options?.inbuilt?.sort.direction || 'desc';
                const {data: {rows = [], page} = {}} = await fetchData({...initialValue, ...table?.customData?.values} || {initialValue}, table?.page, {column, direction});
                table?.setData(rows);
                table?.setPage({
                    current: page?.current ?? table?.page?.current ?? 0,
                    total: page?.total ?? table?.page?.total ?? 0,
                    size: page?.size ?? table?.page?.size ?? 100
                });
                table?.setLoading(false);
                resolve(null);
            })}>
                <Tooltip color={'red'} title={'Delete'} placement={"bottom"}>
                    <Button type="primary" danger icon={<DeleteOutlined/>}/>
                </Tooltip>
            </Popconfirm>
        }</TableContext.Consumer>
    }</AuthContext.Consumer>
}

interface UpdateInspectionProps {
    inspection_id: number | undefined
    detailed_count: number | undefined
}

interface ImageData {
    inspection_id: number;
    joint_id: number;
    plate_id: number;
    side: string;
    is_critical: boolean;
}

const UpdateInspection: React.FunctionComponent<UpdateInspectionProps> = ({inspection_id, detailed_count}) => {
    const {TextArea} = Input;
    const [form] = AntdForm.useForm()
    const [open, setOpen] = useState<boolean>(false)
    const [imageData, setImageData] = useState<ImageData[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<Record<string, File | null>>({});
    const [signOffNote, setSignOffNote] = useState('')
    const [signOffNoteInitValue, setSignOffNoteInitValue] = useState('')
    const [signOffInitial, setSignOffInitial] = useState('')
    const [signOffInitialInitValue, setSignOffInitialInitValue] = useState('')
    const [loading, setLoading] = useState(false);
    const [allOptions, setAllOptions] = useState<SelectProps['options']>(undefined);
    const [selectedActions, setSelectedActions] = useState<number[]>([])
    const [selectedActionsInitValue, setSelectedActionsInitValue] = useState<number[]>([])
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

    const handleNoteChange = (event: any) => {
        setSignOffNote(event.target.value);
    };

    const handleSignoffInitial = (event: any) => {
        setSignOffInitial(event.target.value);
    };

    const handleFileChange = (file: File, itemKey: string) => {
        setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            [itemKey]: file,
        }));
    };

    const handleAddtionalFileChange = (file: File) => {
        // @ts-ignore
        setFileList([...fileList, file]);
    };

    const handleRemove = (file: UploadFile<any>) => {
        const filteredFileList = fileList.filter((item) => item.uid !== file.uid);
        setFileList(filteredFileList);
    };

    const handleCancel = async () => {
        form.resetFields()
        setOpen(false);
    };

    const handleSubmit = () => {
        form.submit();
        if (!signOffNote || signOffNote.trim().length == 0) {
            return Promise.reject()
        }
        if (!selectedActions || selectedActions.length === 0) {
            return Promise.reject()
        }
        setLoading(true); // Show spinner while submitting
        const zip = new JSZip();
        let promises = Object.entries(selectedFiles).map(([key, file]) => {
            if (file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        const fileContent = event.target?.result as ArrayBuffer | null;
                        if (fileContent) {
                            zip.file(`${key}.jpg`, fileContent);
                            resolve(null);
                        } else {
                            reject(new Error(`Failed to read file: ${file.name}`));
                        }
                    };

                    reader.onerror = () => {
                        reject(new Error(`Failed to read file: ${file.name}`));
                    };

                    reader.readAsArrayBuffer(file);
                });
            }
            return Promise.resolve(); }
        );

        Object.entries(fileList).map(([key, file]) => {
            if (file) {
                const returnValue = new Promise((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        const fileContent = event.target?.result as ArrayBuffer | null;
                        if (fileContent) {
                            zip.file(`additional_${parseInt(key)+1}.jpg`, fileContent);
                            resolve(null);
                        } else {
                            reject(new Error(`Failed to read file: ${file.name}`));
                        }
                    };

                    reader.onerror = () => {
                        reject(new Error(`Failed to read file: ${file.name}`));
                    };
                    // @ts-ignore
                    reader.readAsArrayBuffer(file);
                });
                promises.push(returnValue)
            }

            return Promise.resolve(); } );

        Promise.all(promises)
            .then(() => {
                // Generate the zip file asynchronously
                zip.generateAsync({type: 'blob'}).then((content) => {
                    // Create a FormData object
                    const formData = new FormData();
                    formData.append('inspectionID', String(inspection_id));//make sure to add params first
                    formData.append('signOffNote', signOffNote);
                    formData.append('signOffInitial', signOffInitial);
                    formData.append('selectedActions', JSON.stringify(selectedActions));
                    formData.append('zipFile', content, 'images.zip'); // Add the zip file to the FormData as the last item

                    // Perform the POST request
                    // upload_critical_joint_images(formData, String(inspection_id), signOffNote)
                    upload_critical_joint_images(formData)
                        .then((response) => {
                            unstable_batchedUpdates(() => {
                                setOpen(false);
                                setLoading(false); // Hide spinner after image upload
                            });
                        })
                        .catch((error) => {
                            console.error('Image Upload Error:', error);
                            unstable_batchedUpdates(() => {
                                setLoading(false); // Hide spinner after image upload
                            });
                        });
                });
            })
            .catch((error) => {
                console.error('Error creating zip file:', error);
                unstable_batchedUpdates(() => {
                    setLoading(false); // Hide spinner after image upload
                });
            });
    };

    const loadCriticalJoints = useCallback(async () => {
        if (inspection_id) {
            // const {data: {rows = []} = {}} = await fetch_critical_joints(inspection_id)
            const {data: {rows = []} = {}} = await fetch_critical_joints_extra(inspection_id)
            setSignOffNote(rows?.[0]?.inspection.sign_off_note);
            setSignOffNoteInitValue(rows?.[0]?.inspection.sign_off_note)
            setSignOffInitial(rows?.[0]?.inspection.sign_off_initial)
            setSignOffInitialInitValue(rows?.[0]?.inspection.sign_off_initial)
            if (rows?.[0]?.inspection.action_list && rows?.[0]?.inspection.action_list.length > 0) {
                setSelectedActions(rows?.[0]?.inspection.action_list.map((value: string) => parseInt(value)));
                setSelectedActionsInitValue(rows?.[0]?.inspection.action_list.map((value: string) => parseInt(value)))
            }
            if (detailed_count !== undefined) {
                setImageData(rows?.[0]?.plates);
            }
            // return rows

            // Dummy data
            // const dummyData = [
            //     {inspection_id: 7, joint_id: 68, plate_id: 4, side: 'front'},
            //     {inspection_id: 8, joint_id: 85, plate_id: 9, side: 'front'},
            //     {inspection_id: 8, joint_id: 85, plate_id: 9, side: 'back'},
            //     {inspection_id: 9, joint_id: 25, plate_id: 1, side: 'back'},
            //     {inspection_id: 9, joint_id: 25, plate_id: 2, side: 'back'},
            // ];
            // setImageData(dummyData);
        }

    }, [inspection_id])

    const handleChange = (value: number[]) => {
        setSelectedActions(value)
    };

    const loadActions = async () => {
        const orderBy = getOrdering('name', 'asc');
        const {data: {rows = []} = {}} = await fetch({tableName: 'correct_actions', orderBy: orderBy});

        const options: SelectProps['options'] = [];
        Object.entries(rows).map(([key, action]: [string, any]) => {
            options.push({
                label: action['name'],
                value: action['id'],
            });
        });
        setAllOptions(options);
    }

    return <Fragment>
        <Space style={{width: "max-content"}}>
            <Tooltip color={'green'} title={'Update'} placement={"bottom"}>
                <Button style={{background: '#87d068'}} icon={<EditTwoTone twoToneColor="white"/>}
                        onClick={async () => {
                            // await loadCriticalJoints()
                            // await loadCriticalJoints().then(() => loadActions())
                            await Promise.all([loadCriticalJoints(), loadActions()]);
                            setOpen(true)
                        }}/></Tooltip>
        </Space>
        <Modal
            title={'Update Inspection'}
            visible={open}
            onCancel={handleCancel}
            okText={'Save'}
            onOk={handleSubmit}
            centered
            destroyOnClose
        >
            <Spin size="large" spinning={loading}>
                <div style={{maxHeight: '400px', overflow: 'auto', paddingRight: '10px'}}>
                    <AntdForm
                        form={form}
                        layout="horizontal"
                        name={'inspection_update_form'}
                        initialValues={{
                            ["crt-actions"]: selectedActionsInitValue,
                            ["sign-off"]: signOffNoteInitValue,
                            ["sign-off-initials"]: signOffInitialInitValue
                        }}
                    >
                        <Form.Item name="sign-off"
                                   rules={[{required: true, message: 'Please Add sign-off notes here.'}]}>
                            <TextArea rows={5} placeholder="Add sign-off notes here" onChange={handleNoteChange}/>
                        </Form.Item>

                        <Form.Item name="sign-off-initials"
                                   rules={[{required: true, message: 'Please Add sign-off initials here.'}]}>
                            <Input placeholder="Add sign-off initials here" onChange={handleSignoffInitial}/>
                        </Form.Item>

                        <Form.Item name="crt-actions"
                                   rules={[{required: true, message: 'Please select corrective actions.'}]}>
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                style={{width: '100%'}}
                                placeholder="Select corrective actions."
                                onChange={handleChange}
                                options={allOptions}
                                filterOption={(inputValue, option) =>
                                    // @ts-ignore
                                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                                }
                            />

                        </Form.Item>
                        {/* Render your component using the 'data' state */}
                        {imageData.map((item) => {
                            const itemKey = `${item.joint_id}-${item.plate_id}-${item.side}`;
                            return (<Form.Item key={itemKey}
                                    label={(<span style={{ color: item.is_critical ? 'red' : 'inherit' }}>Select an Image</span>)}>
                                    <Upload
                                        maxCount={1}
                                        name={`joint_image_${item.joint_id}_${item.side}`}
                                        accept="image/*"
                                        beforeUpload={async (file) => {
                                            const isImage = file.type.startsWith('image/');
                                            if (!isImage) {
                                                message.error('Please select an image file.')
                                                return Upload.LIST_IGNORE
                                            } else {
                                                handleFileChange(file, itemKey);
                                            }
                                            return false; // Prevent default upload behavior
                                        }}
                                    >
                                        <Button icon={<UploadOutlined/>}>
                                            {`Joint ${item.joint_id}(Plate ${item.plate_id})-${item.side.toUpperCase()}`}
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            );
                        })}
                        <Form.Item label='Select additional Image'>
                            <Upload
                                name='additional_image'
                                accept="image/*"
                                fileList={fileList}
                                beforeUpload={async (file, fileList) => {
                                    const isImage = file.type.startsWith('image/');
                                    if (!isImage) {
                                        message.error('Please select an image file.')
                                        return Upload.LIST_IGNORE
                                    } else {
                                        handleAddtionalFileChange(file);
                                    }
                                    return false;
                                }}
                                onRemove={(file : UploadFile<any>) => handleRemove(file)}
                            >
                                <Button icon={<UploadOutlined/>}>
                                    Additional Image
                                </Button>
                            </Upload>
                        </Form.Item>
                    </AntdForm>
                </div>
            </Spin>
        </Modal>
    </Fragment>
}

const InspectionsTable: React.FunctionComponent<InspectionsTableProps> = ({schema,locationID, inspector_id, setFilters}) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const startDate = currentDate.toDateString();
    const initialStartDate = {start_date:startDate};
    const [displayDate, setDisplayDate] = useState<{[key:string]: any}>({start_date:startDate});
    const table_filter_schema = 'inspections_filter';

    const fetchWrapper = async (values: {[key:string]: any}, page?: Page, sort?:{column:string,direction:string}) =>{
        setFilters(values)
        return await fetchInspectionFilteredData(values, page, locationID, inspector_id, sort)
    }

    return <TableV2
        // key={locationID}
        schema={schema}
        maxColumnWidth={400}
        header={
            <div style={{ paddingRight: 7, width: '100%'}}>
                <TableFilter
                    schema={table_filter_schema}
                    fetch={fetchWrapper}
                    notifyLocation={Boolean(!locationID)}
                    initialValue={displayDate}
                    overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                        if (element.name === 'start_date' || element.name === 'end_date') {
                            return <DatePickerFormatted disabledDate={current => DISABLED_DATE(current)} />
                        } else {
                            return undefined
                        }
                    }}
                />
            </div>
        }
        fetchConfig={async (schema) => {
            return await configs(schema);
        }}
        fetchData={async (data_request) => {
            handleScroll()
            const response = await fetchInspectionFilteredData(
                {...initialStartDate, ...data_request.options.values} || {initialStartDate},
                data_request.page, locationID, inspector_id,
                data_request.options.inbuilt?.sort)
            if (displayDate){
                setDisplayDate({})
            }
            return response as Response
        }}
        customRenderer={{
            view_reports: (value, row, index, column) => {
                const {id, detailed_count} = row
                return <div>
                    <Space>
                        <Button icon={<FilePdfOutlined/>} onClick={async () => {
                            await fetch_inspection(id, "html")
                        }}>View</Button>
                        <Tooltip color={'#108ee9'} title={'Download'} placement={"bottom"}>
                            <Button type="primary" icon={<DownloadOutlined/>} onClick={async () => {
                            await fetch_inspection(id, "pdf")}}/>
                        </Tooltip>
                        {/*<Validate role={["administrator"]}>*/}
                            {/*    <AuthContext.Consumer>{auth => auth.user && auth.user.user_role === 'administrator' &&*/}
                        <UpdateInspection inspection_id = {id} detailed_count = {detailed_count}/>
                            {/*}</AuthContext.Consumer>*/}
                        {/*</Validate>*/}
                        <DeleteButton inspectionID={id} fetchData={fetchWrapper} initialValue={initialStartDate}/>
                    </Space>
                </div>
            },
            status: (value, row, index, column) => {
                const nameCard = (name: string, color: string) => <Tag color={color} style={{margin: 2}}>{name}</Tag>
                return <Space>
                    {value ? nameCard('Passed', 'green') : nameCard('Not Conforming', 'red')}
                    {row.sign_off && nameCard('Corrected', 'blue')}
                </Space>
            },
            date: (value, row, index, column) => {
                return <ConfigsContext.Consumer>{configs =>
                    <Typography.Text>{moment(value).format(configs.dateTimeFormat)}</Typography.Text>}</ConfigsContext.Consumer>
            }
        }}
        scroll={{y: 'calc(100vh - 513px)', x: 'max-content'}}
    />
}

interface DropDownComponentProps{
    title: string
    fetchData: (id:any)=>Promise<any>
    placeHolder?: string
    initId?:number
    filterId?:number
    onChange?:(value:number|undefined)=>void
    display_only?:boolean
    deps?:any
}

const DropDownComponent: React.FunctionComponent<DropDownComponentProps> = (props) => {
    const {Text} = Typography
    const {Option} = Select
    const [items,setItems] = useState<any[]>([])

    const loadItems = async () => {
        const {data: {rows = []} = {}} = await props.fetchData(props.filterId)
        setItems(rows)
        if (props.display_only){
            props.onChange?.(rows?.[0]?.id)
        }
    }

    useEffect(()=>{
        loadItems()
    }, [props.deps])

    if (props.display_only !== false){
        return <Space style={{margin: 10, marginLeft:'25vw', marginTop: 5}}>
            <Row>
                <Col span={5}>
                    <Text strong style={{marginRight: 6}}>{props.title}</Text>
                </Col>
                <Col span={8}>
                    <Select
                        showSearch
                        disabled
                        value={items?.[0]?.name}
                        style={{width: 400}}
                        placeholder=''
                        optionFilterProp="children"
                    />
                </Col>
            </Row>
        </Space>
    }
    else{
        return <Space style={{margin: 10, marginLeft:'25vw', marginTop: 5}}>
            <Row>
                <Col span={5}>
                    <Text strong style={{marginRight: 6}}>{props.title}</Text>
                </Col>
                <Col span={8}>
                    <Select
                        showSearch
                        value={props.initId}
                        style={{width: 400}}
                        placeholder={props.placeHolder}
                        optionFilterProp="children"
                        onChange={
                            (value: number) => {
                                props.onChange?.(value)
                            }
                        }
                    >
                        {items?.map(({id, name}) => <Option key={id} value={id} children={name}/>)}
                    </Select>
                </Col>
            </Row>
        </Space>
    }
}

export const Inspections:React.FunctionComponent<InspectionsProps> = (props) => {
    const inspections_table = 'inspections'
    const [inspectorId, setInspectorId] = useState<number|undefined>()
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 7);
    const startDate = currentDate.toDateString();
    const [filters, setFilters] = useState<{ [key: string]: any; }>({start_date:startDate})
    const [userRole, setUserRole] = useState<string>()

    const onInspectorsDropdown = async (value:number|undefined) => {
        unstable_batchedUpdates(() => {
            setInspectorId(value)
        })
    }

    const renderInspector = (auth:any) =>{
        if (auth.user !== undefined){
            const user = auth.user
            if (user.user_role === 'qc_manager'){
                return <DropDownComponent
                    title={'Inspector'}
                    fetchData={fetch_inspectors}
                    placeHolder={'Please select an inspector'}
                    initId={inspectorId}
                    onChange={onInspectorsDropdown}
                    display_only={false}
                />
            }
        }
    }

    const export_to_csv = async (filters: { [key: string]: any; }, location_id: number | undefined, inspector_id: number | undefined) => {
        let id;
        if (userRole !== QC_MANAGER && inspector_id === undefined) {
            id = location_id
        } else if (userRole === QC_MANAGER) {
            id = inspector_id
        }
        try {
            const res = await fetchInspectionFilteredDataForCSV(
                filters,
                id
            )

            // Download CSV.
            if (res.status === 200) {
                writeToCSV(res, 'InspectionExport.csv')
            }
        } catch (e) {
            console.log("Error in CSV file export:", e)
        }
    }


    return <React.Fragment>
        <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
        <Row>
            <Col span={12}>
                <PageHeader title="Inspections"/>
            </Col>
            <Col span={12}>
                <TableContext.Consumer>{table =>
                    <Fragment>
                        {
                            <Button onClick={() => {
                                export_to_csv(filters, props?.locationID, inspectorId)
                            }
                            }>Export to CSV</Button> }
                    </Fragment>}
                </TableContext.Consumer>
            </Col>
        </Row>
        </div>
        <LocationsFilter
            locationID={props?.locationID}
            companyID={props?.companyID}
            setLocationID={props?.setLocationID}
            setCompanyID={props?.setCompanyID}
            companies={props?.companies}
            locations={props?.locations}
            disabledValidatorCompany={(user)=>{
                return user.user_role !== 'administrator'
            }}
            disabledValidatorLocation={(user)=>{
                return !(user.user_role === 'administrator' || user.user_role === 'district_manager' || user.user_role === 'regional_manager')
            }}
        />
        <AuthContext.Consumer>{auth =>
            <div>
                {renderInspector(auth)}
                {setUserRole(auth.user.user_role)}
            </div>
        }</AuthContext.Consumer>
        <InspectionsTable key={`${props.locationID}${inspectorId}`}
                          schema={inspections_table}
                          locationID={props.locationID}
                          inspector_id={inspectorId}
                          setFilters={setFilters}
        />
    </React.Fragment>
}
