import React from "react";
import {DatePicker} from "antd";
import moment, {Moment} from "moment";
import {ConfigsContext} from "../configs/ConfigsProvider";

export interface DatePickerFormattedProps {
    value?: string|Moment,
    onChange?: (value:any) => void
    style?: Object
    disabled?: boolean
    disabledDate?: (current: Moment) => boolean
}

export interface DateRangePickerFormattedProps {
    value?: any,
    onChange?: (value:any) => void
    style?: Object
    disabled?: boolean
    disabledDate?: (current: Moment) => boolean
}

const DatePickerFormatted: React.FunctionComponent<DatePickerFormattedProps> = ({value, onChange, style,disabled, disabledDate}) => {
    return <ConfigsContext.Consumer>
        {configs=><DatePicker value={value?moment(value):undefined} onChange={onChange} format={configs.dateFormat} style={style} disabled={disabled} disabledDate={disabledDate}/>}
    </ConfigsContext.Consumer>
}

export const DateRangePickerFormatted: React.FunctionComponent<DateRangePickerFormattedProps> = ({value, onChange, style, disabled, disabledDate}) => {
    return <ConfigsContext.Consumer>
        {configs=><DatePicker.RangePicker value={value} onChange={onChange} format={configs.dateFormat} style={style} disabled={disabled} disabledDate={disabledDate}/>}
    </ConfigsContext.Consumer>
}

export default DatePickerFormatted;