import LanguageDetector from 'i18next-browser-languagedetector'
import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const languageDetector = new LanguageDetector(null, {
    order: [
        'cookie',
        'localStorage',
    ],
    lookupCookie: 'i18next-eas',
    lookupLocalStorage: 'i18nextLng-eas',
    caches: [
        'localStorage',
        'cookie',
    ],
});

const cacheOptions = {
    enabled: true,
    expirationTime: 1*24*60*60*1000, // one day
    prefix: 'i18_ch_',
    versions: {
        en: 'v1.0.0',
    },
};

const translatorOptions = {
    cache: cacheOptions,
    fallbackLng: 'en',
    ns: ['translations'],
    debug: true, // debug should be disabled for production
    defaultNS: 'translations',
    react: {
        wait: true,
    },
};

i18n
    .use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        cache: cacheOptions,
        fallbackLng: 'en',
        ns: ['translations'],
        debug: false, // debug should be disabled for production
        defaultNS: 'translations',
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false,
        },
        initImmediate: true,

    });

export default i18n;
