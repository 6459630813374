import React, {Fragment} from "react";
import {Button, Col, FormInstance, PageHeader, Row, Tag, Typography} from "antd";
import {ConfigResponse, Page, Response, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {configs, fetch, getCompanyCSV, getUserCSV} from "../api";
import {TableFilter} from "./filters/TableFilter";
import {MapToFilter} from "./filters/mapping";
import {getOrdering} from "./filters/ordering";
import CustomDropDown from "./lib/CustomDropDown";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {useHistory} from "react-router-dom";

import moment from "moment";
import {Register} from "./Register";
import { ConfigsContext } from "./configs/ConfigsProvider";
import {handleScroll} from "../utils/util";
import {writeToCSV} from "../utils/functions";

interface CompanyProps {
    fetchCompanies: () => void
    fetchLocations: () => void
}

export const Header: React.FunctionComponent<CompanyProps> = (props) => {

    const fetchCompanyData = async (values: {[key:string]: any}, page?: Page)=>{
        const filters = MapToFilter(values)
        return await fetch({
            tableName: 'company',
            orderBy: ['id'],
            filter:[...filters],
            page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
        }) || {}
    }
    const export_to_csv = async (customData: any) => {
        let arrayFilters: object[] = [];
        try {
            if (Object.keys(customData).length > 0) {
                for (let key in customData.values) {
                    if (customData.values[key] !== undefined && customData.values[key] !== "") {
                        arrayFilters = [...arrayFilters, {name: key, value: customData.values[key]}]
                    }
                }
            }
            const res = await getCompanyCSV(arrayFilters);

            // Download CSV.
            if (res.status === 200) {
                writeToCSV(res, 'CompanyExport.csv')
            }
        } catch (e) {
            console.log("Error in CSV file export:", e)
        }
    }

    return <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
        <Row>
            <Col span={12}>
                <PageHeader title={'Company'}/>
            </Col>
            <Col span={12}>
                <TableContext.Consumer>{table =>
                    <Fragment>
                        {
                            <Button onClick={() => {
                            export_to_csv(table?.customData)
                        }
                        }>Export to CSV</Button> }
                    </Fragment>}
                </TableContext.Consumer>
                <Register
                    title={'Register New Company'}
                    schema={'company_registration'}
                    tableName={'company'}
                    btnText={'Register New Company'}
                    fetchCompanies={props.fetchCompanies}
                    fetchLocations={props.fetchLocations}
                />
            </Col>
        </Row>
        <TableFilter
            schema={'company_filter'}
            fetch={fetchCompanyData}
            visibilityValidator={(schema: string, element: any, form: any) => {
                const country = form.getFieldValue('country');
                switch (element.title){
                    case 'State':
                        return country === 'US'
                    case 'Province':
                        return country === 'CA'
                    case 'Zip code':
                        return country === 'US'
                    case 'Postal code':
                        return country === 'CA'
                    default:
                        return true
                }
            }}
            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                if (element.name === 'state') {
                    return <CustomDropDown
                        key={'state'}
                        schema={schema}
                        name={'state'}
                        title={element.title}
                        tableName={'state_ref'}
                        filter={'country_code'}
                        filterValue={form.getFieldValue('country')}
                        enum_map={['code', 'name']}
                    />
                } else {
                    return undefined
                }
            }}
        />
    </div>
}

export const Company: React.FunctionComponent<CompanyProps> = (props) => {
    const history = useHistory()

    return <Fragment>
        <TableV2
            header={<Header fetchCompanies={props.fetchCompanies} fetchLocations={props.fetchLocations}
            />}
            schema={'company'}
            fetchConfig={async (schema: string) => {
                const response = await configs(schema) || {}
                return response as ConfigResponse
            }}
            fetchData={async (request) => {
                handleScroll()
                const filters = MapToFilter(request.options?.values || {})
                const column = request.options.inbuilt?.sort.column || 'id'
                const direction = request.options.inbuilt?.sort.direction || 'asc'
                const orderBy = getOrdering(column, direction);
                const payload = {
                    tableName: 'company',
                    orderBy: orderBy,
                    filter:[...filters],
                    page: {size: request?.page?.size || 100, from: ((request?.page?.current || 1) - 1) * (request?.page?.size || 100)}
                }
                const response = await fetch(payload)
                return response as Response
            }}
            scroll={{y: 'calc(100vh - 441px)', x: '100%'}}
            customRenderer={{
                action: (value, row, index, column) => {
                    const {id, email} = row
                    return <Button onClick={() => {
                        history.push({
                            pathname: '/view/locations',
                            state: {company_id: id.toString()}
                        })
                    }}>View</Button>
                },
                edit: (value, row, index, column) => {
                    const {id} = row
                    return <Register
                        title={'Company - Edit'}
                        schema={'company_registration'}
                        tableName={'company'}
                        // orderBy={['id']}
                        btnText={'Update'}
                        id={id}
                        row={row}
                        fetchCompanies={props.fetchCompanies}
                        fetchLocations={props.fetchLocations}
                    />
                },
                is_active: (value, row, index, column) => {
                    return <Tag color={value?"green":"red"}>{value?'active':'inactive'}</Tag>
                },
                created_timestamp: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateTimeFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                }
            }}
        />
    </Fragment>
}

export default Company