import React, {CSSProperties, FunctionComponent, ReactNode} from "react";
import {Input, InputNumber} from "antd";

interface CustomComponentsProps {
    isEdit?: boolean
    style?: CSSProperties
}

interface CustomInputNumberProps extends CustomComponentsProps{
    value: number
    max?: number
    addonAfter?: ReactNode
    onChange?: (value: number | null) => void
}

interface CustomTextAreaProps extends CustomComponentsProps{
    value: string
    rows?: number
    onChange?: (value: string | null) => void
}

export const CustomInputNumber: FunctionComponent<CustomInputNumberProps> = ({value, max, addonAfter, isEdit, onChange, style}) => {
    return <InputNumber
        style={{backgroundColor: isEdit?'#EBF5FB':'#FFFFFF', color: "black", width: 125, ...style}}
        value={value}
        min={0}
        max={max}
        addonAfter={addonAfter}
        onChange={targetValue => isEdit && onChange && onChange(targetValue)}
        disabled={!isEdit&&!addonAfter}
    />
}

export const CustomTextArea: FunctionComponent<CustomTextAreaProps> = ({value, rows, isEdit, onChange, style}) => {

    const { TextArea } = Input
    return <TextArea
        style={{backgroundColor: isEdit?'#EBF5FB':'#FFFFFF', color: "black", ...style}}
        value={value}
        rows={rows}
        onChange={e => isEdit && onChange && onChange(e.target.value)}
        disabled={!isEdit}
    />
}
