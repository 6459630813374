import React from "react";


export interface ConfigsContextProps {
    dateFormat: string
    dateTimeFormat:string
    dateTimeFormatExtended: string
}

export const ConfigsContext = React.createContext<ConfigsContextProps>({dateFormat: 'MM/DD/YYYY', dateTimeFormat: 'MM/DD/YYYY, h:mm:ss A', dateTimeFormatExtended:'MM/DD/YYYY, h:mm:ss:SSSS A'});

export const ConfigsContextProvider: React.FunctionComponent<any> = (props) => {
    const dateFormat = 'MM/DD/YYYY';
    const dateTimeFormat = dateFormat + ', h:mm:ss A';
    const dateTimeFormatExtended = dateFormat + ', h:mm:ss:SSSS A';
    return <ConfigsContext.Provider value={{dateFormat, dateTimeFormat, dateTimeFormatExtended}}>
        {props.children}
    </ConfigsContext.Provider>
}

