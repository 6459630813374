import React, {Fragment} from "react";
import {Card, Collapse, Empty, Space, Typography} from "antd";
import {
    COUNT, fillColor, graphValues, INSPECTION_COUNT, JOINT_COUNT,
    JOINTS, MEMBER_COUNT,
    MEMBERS,
    NO_OF_INSPECTED, OUT_OF_CONFORMANCE, PLATES_UPSIZED, POSITION_OF_GRAPH_VALUES,
    PRELIMINARY,
    summaryReports,
    TRUSSES
} from "../../utils/reportConstants";
import {Bullet, Column, DualAxes, Pie} from "@ant-design/plots";
import {OutOfConformance} from "./Reports";
import {processTitle} from "../../utils/report";

interface OverallQCInspectionsProps {
    overallQCInspections: any
}

export const OverallQCInspections: React.FunctionComponent<OverallQCInspectionsProps> = ({overallQCInspections}) => {

    const {total_number_inspected: {trusses, preliminary, joints, members}} = overallQCInspections
    const {numberOutOfConformance: {trusses: outOfTrusses, preliminary: outOfPreliminary, joints: outOfJoints, members: outOfMembers}} = overallQCInspections
    const {percentageOfOutOfConformance: {trusses: outOfTrussesPercent, preliminary: outOfPreliminaryPercent, joints: outOfJointsPercent, members: outOfMembersPercent}} = overallQCInspections

    return <Fragment>
        {trusses?<Fragment>
            <Column
                color={fillColor}
                isGroup={true}
                seriesField={'name'}
                xField={'Trusses or Joints'}
                yField={'Count'}
                yAxis={{
                    title: {
                        text: COUNT
                    }
                }}
                data={[
                    {
                        name: NO_OF_INSPECTED,
                        'Trusses or Joints': TRUSSES,
                        'Count': trusses
                    },
                    {
                        name: NO_OF_INSPECTED,
                        'Trusses or Joints': PRELIMINARY,
                        'Count': preliminary
                    },
                    {
                        name: NO_OF_INSPECTED,
                        'Trusses or Joints': JOINTS,
                        'Count': joints
                    },
                    {
                        name: NO_OF_INSPECTED,
                        'Trusses or Joints': MEMBERS,
                        'Count': members
                    },
                    {
                        name: OUT_OF_CONFORMANCE,
                        'Trusses or Joints': TRUSSES,
                        'Count': outOfTrusses
                    },
                    {
                        name: OUT_OF_CONFORMANCE,
                        'Trusses or Joints': PRELIMINARY,
                        'Count': outOfPreliminary
                    },
                    {
                        name: OUT_OF_CONFORMANCE,
                        'Trusses or Joints': JOINTS,
                        'Count': outOfJoints
                    },
                    {
                        name: OUT_OF_CONFORMANCE,
                        'Trusses or Joints': MEMBERS,
                        'Count': outOfMembers
                    }
                ]}
            />
            <Space style={{maxWidth: '100%', overflowX: 'auto'}}>
                {[
                    {name: TRUSSES, value: outOfTrussesPercent},
                    {name: PRELIMINARY, value: outOfPreliminaryPercent},
                    {name: JOINTS, value: outOfJointsPercent},
                    {name: MEMBERS, value: outOfMembersPercent}
                ].map(({name, value}) => <Card
                    title={`${name} Overview`}
                    style={{width: '25vw'}}
                >
                    <Pie
                        angleField={'Percentage'}
                        colorField={'Out of Conformance'}
                        label={{
                            type: 'inner',
                            offset: '-30%',
                            content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
                            style: {
                                fontSize: 14,
                                textAlign: 'center',
                            },
                        }}
                        data={[
                            {
                                'Out of Conformance': `Passed ${name}`,
                                'Percentage': 100 - value
                            },
                            {
                                'Out of Conformance': `Failed ${name}`,
                                'Percentage': value
                            }
                        ]}
                    />
                </Card>)}
            </Space>
        </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </Fragment>
}

interface SummaryReportProps {
    overallQCInspections: any
    outOfConformanceVisual: any
    outOfConformanceDetailed: any
    inspectionByRange: any
    outOfConformanceMembers: any
    platesUpsized: any
}

const SummaryReport: React.FunctionComponent<SummaryReportProps> = ({
                                                                        overallQCInspections,
                                                                        outOfConformanceVisual,
                                                                        outOfConformanceDetailed,
                                                                        outOfConformanceMembers,
                                                                        platesUpsized,
                                                                        inspectionByRange
                                                                    }) => {
    const { Panel } = Collapse;
    const {totalPlates, upsizedPlates} = platesUpsized
    const {title, data} = inspectionByRange

    return <Collapse defaultActiveKey={['overallQCInspections']}>
        {overallQCInspections && <Panel key={'overallQCInspections'} header={summaryReports.OVERALL_QC_INSPECTIONS}>
            <OverallQCInspections overallQCInspections={overallQCInspections}/>
        </Panel>}
        {outOfConformanceVisual && <Panel key={'outOfConformanceVisual'} header={summaryReports.OUT_OF_CONFORMANCE_PRELIMINARY}>
            <Column
                color={fillColor}
                xField={'Type'}
                yField={'No of Conformance'}
                yAxis={{
                    title: {
                        text: INSPECTION_COUNT
                    }
                }}
                label={{
                    position: POSITION_OF_GRAPH_VALUES,
                    style: {
                        fill: graphValues.COLOR,
                        OPACITY: graphValues.OPACITY
                    },
                    content: ({percent}) => `${percent || '0'} %`,
                }}
                data={Object.entries(outOfConformanceVisual)?.map(visuals => {
                    const outOfConformanceVisuals : OutOfConformance = visuals[1] as OutOfConformance
                    return {
                        'Type': processTitle(visuals[0]),
                        'No of Conformance': outOfConformanceVisuals.count,
                        'percent':  outOfConformanceVisuals.percentage
                    }
                })}
            />
        </Panel>}
        {outOfConformanceDetailed && <Panel key={'outOfConformanceDetailed'} header={summaryReports.OUT_OF_CONFORMANCE_JOINTS}>
            <Column
                color={fillColor}
                xField={'Type'}
                yField={'No of Conformance'}
                yAxis={{
                    title: {
                        text: JOINT_COUNT
                    }
                }}
                label={{
                    position: POSITION_OF_GRAPH_VALUES,
                    style: {
                        fill: graphValues.COLOR,
                        OPACITY: graphValues.OPACITY
                    },
                    content: ({percent}) => `${percent || '0'} %`,
                }}
                data={Object.entries(outOfConformanceDetailed)?.map(detailedJoints => {
                    const outOfConformanceDetailedJoints : OutOfConformance = detailedJoints[1] as OutOfConformance
                    return {
                        'Type': processTitle(detailedJoints[0]),
                        'No of Conformance': outOfConformanceDetailedJoints.count,
                        'percent':  outOfConformanceDetailedJoints.percentage
                    }
                })}
            />
        </Panel>}
        {outOfConformanceMembers && <Panel key={'outOfConformanceMembers'} header={summaryReports.OUT_OF_CONFORMANCE_MEMBERS}>
            <Column
                color={fillColor}
                xField={'Type'}
                yField={'No of Conformance'}
                yAxis={{
                    title: {
                        text: MEMBER_COUNT
                    }
                }}
                label={{
                    position: POSITION_OF_GRAPH_VALUES,
                    style: {
                        fill: graphValues.COLOR,
                        OPACITY: graphValues.OPACITY
                    },
                    content: ({percent}) => `${percent || '0'} %`,
                }}
                data={Object.entries(outOfConformanceMembers)?.map(members => {
                    const outOfConformanceDetailedMembers : OutOfConformance = members[1] as OutOfConformance
                    return {
                        'Type': processTitle(members[0]),
                        'No of Conformance': outOfConformanceDetailedMembers.count,
                        'percent':  outOfConformanceDetailedMembers.percentage
                    }
                })}
            />
        </Panel>}
        {platesUpsized && <Panel key={'platesUpsized'} header={summaryReports.PLATES_UPSIZED_FROM_SPECIFIED}>
            {totalPlates?<Bullet
                measureField={'measures'}
                rangeField={'ranges'}
                targetField={'Total plates'}
                xField={'title'}
                data={[
                    {
                        title: PLATES_UPSIZED,
                        ranges: [totalPlates],
                        measures: [upsizedPlates],
                        'Total plates': totalPlates,
                    }
                ]}
            />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </Panel>}
        {inspectionByRange && <Panel key={'inspectionByRange'} header={title}>
            <DualAxes
                xField={'range'}
                yField={['inspections', 'passPercentage']}
                data={[data, data]}
                geometryOptions={[
                    {
                        geometry: 'column'
                    },
                    {
                        geometry: 'line',
                        lineStyle: {
                            lineWidth: 2
                        },
                    },
                ]}
            />
        </Panel>}
    </Collapse>
}

export default SummaryReport;