import React, {Fragment, useMemo} from "react";
import {Card, Col, Collapse, Empty, Row, Space, Typography} from "antd";
import {
    AVERAGE_INSPECTION,
    AVERAGE_PER_WEEK,
    averageReports, AVG_HEIGHT, AVG_SPAN,
    CRITICAL_JOINTS,
    JOINT_STRESS_INDEX,
    CRITICAL_JOINTS_PER_INSPECTION, FLOOR_TRUSSES, FOUR_OR_MORE_CRITICAL_JOINTS, graphValues, INCHES,
    INSPECTION_COUNT,
    INSPECTION_INFORMATION_COUNT,
    INSPECTIONS, JOINT_BREAKDOWN_AS_PERCENTAGE,
    JOINT_BREAKDOWN_COUNT, MAX_HEIGHT, MAX_SPAN, MIN_HEIGHT, MIN_SPAN,
    ONE_CRITICAL_JOINTS,
    OUT_OF_CONFORMANCE, POSITION_OF_GRAPH_VALUES, ROOF_TRUSSES, SETUP_FREQUENCY, THREE_CRITICAL_JOINTS,
    TOTAL_CRITICAL_JOINTS,
    TOTAL_INSPECTIONS, TRUSS_OVERVIEW, TWO_CRITICAL_JOINTS,
    ZERO_CRITICAL_JOINTS,
    JOINT_STRESS_INDEX_AVERAGE, MEDIAN_SPAN, MEDIAN_HEIGHT, fillColor
} from "../../utils/reportConstants";
import {Bullet, Column, Pie} from "@ant-design/plots";
import {inchToFeet} from "../../utils/report";

interface Line {
    line_name: string
    inspections: number
    active_line: boolean
}

interface Shift {
    shift_name: string
    lines: Line[]
}

interface Crew {
    crew_name: string
    inspections: number
    active_crew: boolean
}

interface ISetupFrequency{
    ratio: {count:number, truss_type:string}[],
    avg_setup_per_setup: {shift_id: number, shift: string, line_id:number,setup:string, avg_setups:number}[],
    avg_setup_total: any
    meta: {setups: number, shifts: number}
}

interface AverageReportProps {
    inspectionInformation: any
    jointBreakdown: any
    dimensionBreakdown: any
    inspectionFrequency: Shift[]
    inspectionFrequencyByCrew: Crew[]
    setupFrequency: ISetupFrequency
}

interface SetupFrequencyProps {
    setupFrequency: ISetupFrequency
    isPDFView?:boolean
}


export const SetupFrequency: React.FunctionComponent<SetupFrequencyProps> = ({setupFrequency:{
    ratio, avg_setup_per_setup,avg_setup_total, meta}, isPDFView
                                                                             }) => {
    const ComponentRatio = useMemo(()=><>
        {ratio?<Pie
            angleField={SETUP_FREQUENCY.RATIO_ANGLE_FIELD}
            colorField={SETUP_FREQUENCY.RATIO_COLOR_FIELD}
            label={{
                type: 'inner',
                offset: '-30%',
                content: ({percent}) => `${Math.round(percent * 100)}%`,
                style: {
                    fontSize: 14,
                    textAlign: 'center',
                },
            }}
            radius={0.8}
            data={ratio.map(({count, truss_type})=>({
                [SETUP_FREQUENCY.RATIO_COLOR_FIELD]: `${truss_type} Setups`,
                [SETUP_FREQUENCY.RATIO_ANGLE_FIELD]: count
            }))}
        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </>,[ratio]);

    const ComponentAvgSetupPerSetup = useMemo(()=><>
        {avg_setup_per_setup?<Column
            xField={SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_FIELD}
            yField={SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_Y_FIELD}
            isGroup={true}
            seriesField={SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_SERIES_FIELD}
            yAxis={{
                title: {
                    text: SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_Y_AXIS_TITLE
                }
            }}
            xAxis={{
                title: {
                    text: SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_AXIS_TITLE
                },
                label:{
                    // id is used as x-axis field name to handle duplicates in setup names
                    // override id with setup name for displaying
                    formatter: (data, item,index)=>{
                        const setup = avg_setup_per_setup.find(({shift_id})=>shift_id.toString() === data);
                        return setup?.shift || data;
                    },
                    autoRotate: true
                }
            }}
            legend={
                {
                    itemName:{
                        formatter: (text: string, item: any, index: number) => {
                            const setup = avg_setup_per_setup.find(({line_id})=>line_id.toString() === text);
                            return setup?.setup || text;
                        }
                    }
                }
            }
            tooltip={{
                title: (title: string, datum)=> {
                    return datum[SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_NAME_FIELD];
                },
                customItems: (originalItems: any[]) => {
                    return originalItems.map(((item: any)=> {return {...item, name: item.data[SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_SERIES_FIELD_NAME]}}));
                }
            }}
            appendPadding={20}
            label={avg_setup_per_setup.length<=12?{
                position: 'top'
            }:false}
            data={avg_setup_per_setup.map(({shift_id, shift, line_id, setup, avg_setups})=>({
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_FIELD]: shift_id.toString(),
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_Y_FIELD]: avg_setups,
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_NAME_FIELD]: shift,
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_SERIES_FIELD]: line_id.toString(),
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_SERIES_FIELD_NAME]: setup
            }))}
        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </>,[avg_setup_per_setup]);

    const ComponentAvgSetupTotal = useMemo(()=><>
        {avg_setup_total?<Bullet
            measureField={SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_MEASURE_FIELD}
            rangeField={'ranges'}
            targetField={'target'}
            xField={'title'}
            data={[
                {
                    title: SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_DATA_TITLE,
                    ranges: [avg_setup_total.range],
                    [SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_MEASURE_FIELD]: [avg_setup_total.total_setups],
                    target: avg_setup_total.target,
                }
            ]}
        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </>,[avg_setup_total]);

    if (isPDFView){
        const displayAvgSetup = (meta.setups <= 4) &&  meta.setups * meta.shifts <= 15;
        return <>
            {displayAvgSetup&&<Card title={SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_TITLE} type={"inner"} style={{pageBreakInside: "avoid"}}>
                {ComponentAvgSetupPerSetup}
            </Card>}
            <Space style={{pageBreakInside: "avoid"}}>
                <Card title={SETUP_FREQUENCY.RATIO_TITLE} type={"inner"} style={{minWidth: '19vw'}}>
                    {ComponentRatio}
                </Card>
                <Card title={SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_TITLE} type={"inner"} style={{minWidth: '19vw'}}>
                    {ComponentAvgSetupTotal}
                </Card>
            </Space>
        </>
    }else{
        const displayAvgSetup = meta.setups * meta.shifts <= 15;
        return <Space>
            <Card title={SETUP_FREQUENCY.RATIO_TITLE} style={{width: '30vw', margin: 15}}>
                {ComponentRatio}
            </Card>
            {displayAvgSetup&&<Card title={SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_TITLE} style={{width: '25vw'}}>
                {ComponentAvgSetupPerSetup}
            </Card>}
            <Card title={SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_TITLE} style={{width: '25vw'}}>
                {ComponentAvgSetupTotal}
            </Card>
        </Space>
    }
}

const AverageReport: React.FunctionComponent<AverageReportProps> = ({
                                                                        inspectionInformation,
                                                                        jointBreakdown,
                                                                        dimensionBreakdown,
                                                                        inspectionFrequency,
                                                                        inspectionFrequencyByCrew,
                                                                        setupFrequency
                                                                    }) => {
    const { Panel } = Collapse
    const { Text } = Typography
    const {
        inspection_count,
        inspections_per_week,
        critical_joint_count,
        critical_joints_per_inspection,
        out_of_conformance_count,
        out_of_conformance_per_week,
        inspections_joint_jsi_average,
        inspections_joint_jsi_average_per_week
    } = inspectionInformation
    const {total_truss_count} = jointBreakdown
    const {roof: {total_count: totalRoofCount}} = dimensionBreakdown
    const {floor: {total_count: totalFloorCount}} = dimensionBreakdown
    const {roof: {span: {min: minRoofSpan, max: maxRoofSpan, avg: avgRoofSpan, median: medianRoofSpan}}} = dimensionBreakdown
    const {roof: {height: {min: minRoofHeight, max: maxRoofHeight, avg: avgRoofHeight, median: medianRoofHeight}}} = dimensionBreakdown
    const {floor: {span: {min: minFloorSpan, max: maxFloorSpan, avg: avgFloorSpan, median: medianFloorSpan}}} = dimensionBreakdown
    const {floor: {height: {min: minFloorHeight, max: maxFloorHeight, avg: avgFloorHeight, median: medianFloorHeight}}} = dimensionBreakdown

    const jointBreakdownPercentage = (count: number) => {
        return parseFloat((count / total_truss_count * 100).toFixed(2))
    }

    const dimensionBreakdownPercentage = (count: number) => {
        const totalCount = totalRoofCount + totalFloorCount
        if(totalCount===0) return 0
        return parseFloat((count / totalCount * 100).toFixed(2))
    }

    return <Fragment>
        <Collapse defaultActiveKey={['inspectionInformation']}>
            {inspectionInformation && <Panel key={'inspectionInformation'} header={averageReports.INSPECTION_INFORMATION}>
                <Space align={"center"}>
                    <Card title={INSPECTION_INFORMATION_COUNT} style={{width: '27vw'}}>
                        {inspection_count?<Column
                            color={fillColor}
                            xField={'Inspection Information'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: INSPECTION_COUNT
                                }
                            }}
                            data={[
                                {
                                    'Inspection Information': TOTAL_INSPECTIONS,
                                    'Count': inspection_count
                                },
                                {
                                    'Inspection Information': TOTAL_CRITICAL_JOINTS,
                                    'Count': critical_joint_count
                                },
                                {
                                    'Inspection Information': OUT_OF_CONFORMANCE,
                                    'Count': out_of_conformance_count
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={AVERAGE_PER_WEEK} style={{width: '27vw'}}>
                        {inspections_per_week?<Column
                            color={fillColor}
                            xField={'Inspection Information'}
                            yField={'Average Per Week'}
                            yAxis={{
                                title: {
                                    text: AVERAGE_INSPECTION
                                }
                            }}
                            data={[
                                {
                                    'Inspection Information': INSPECTIONS,
                                    'Average Per Week': inspections_per_week
                                },
                                {
                                    'Inspection Information': CRITICAL_JOINTS,
                                    'Average Per Week': out_of_conformance_per_week
                                },
                                {
                                    'Inspection Information': JOINT_STRESS_INDEX,
                                    'Average Per Week': inspections_joint_jsi_average_per_week
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Col>
                        <Row>
                            <Card title={CRITICAL_JOINTS_PER_INSPECTION} style={{width: '26vw'}}>
                                {critical_joints_per_inspection?<Bullet
                                    measureField={'measures'}
                                    rangeField={'ranges'}
                                    targetField={'target'}
                                    xField={'title'}
                                    style={{ height: '20vh'}}
                                    data={[
                                        {
                                            title: CRITICAL_JOINTS_PER_INSPECTION,
                                            ranges: [critical_joints_per_inspection * 1.2],
                                            measures: [critical_joints_per_inspection],
                                            target: critical_joints_per_inspection,
                                        }
                                    ]}
                                />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                            </Card>
                        </Row>
                        <Row>
                            <Card title={JOINT_STRESS_INDEX_AVERAGE} style={{width: '26vw'}}>
                                {inspections_joint_jsi_average?<Bullet
                                    measureField={'measures'}
                                    rangeField={'ranges'}
                                    targetField={'target'}
                                    xField={'title'}
                                    style={{ height: '20vh'}}
                                    data={[
                                        {
                                            title: JOINT_STRESS_INDEX_AVERAGE,
                                            ranges: [inspections_joint_jsi_average * 1.25],
                                            measures: [inspections_joint_jsi_average],
                                            target: inspections_joint_jsi_average,
                                        }
                                    ]}
                                />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                            </Card>
                        </Row>
                    </Col>
                </Space>
            </Panel>}
            {jointBreakdown && <Panel key={'jointBreakdown'} header={averageReports.JOINT_BREAKDOWN}>
                <Space>
                    <Card title={JOINT_BREAKDOWN_COUNT} style={{width: '40vw'}}>
                        {total_truss_count?<Column
                            color={fillColor}
                            xField={'Joint Breakdown'}
                            yField={'Percentage'}
                            yAxis={{
                                title: {
                                    text: INSPECTION_COUNT
                                }
                            }}
                            xAxis={{
                                title: {
                                    text: CRITICAL_JOINTS
                                }
                            }}
                            data={[
                                {
                                    'Joint Breakdown': ZERO_CRITICAL_JOINTS,
                                    'Percentage': jointBreakdown['0']
                                },
                                {
                                    'Joint Breakdown': ONE_CRITICAL_JOINTS,
                                    'Percentage': jointBreakdown['1']
                                },
                                {
                                    'Joint Breakdown': TWO_CRITICAL_JOINTS,
                                    'Percentage': jointBreakdown['2']
                                },
                                {
                                    'Joint Breakdown': THREE_CRITICAL_JOINTS,
                                    'Percentage': jointBreakdown['3']
                                },
                                {
                                    'Joint Breakdown': FOUR_OR_MORE_CRITICAL_JOINTS,
                                    'Percentage': jointBreakdown['>=4']
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={JOINT_BREAKDOWN_AS_PERCENTAGE} style={{width: '40vw'}}>
                        {total_truss_count?<Pie
                            angleField={'Percentage'}
                            colorField={'Joint Breakdown'}
                            radius={0.8}
                            label={{
                                type: 'inner',
                                offset: '-30%',
                                content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
                                style: {
                                    fontSize: 14,
                                    textAlign: 'center',
                                },
                            }}
                            data={[
                                {
                                    'Joint Breakdown': `${ZERO_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(jointBreakdown['0'])
                                },
                                {
                                    'Joint Breakdown': `${ONE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(jointBreakdown['1'])
                                },
                                {
                                    'Joint Breakdown': `${TWO_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(jointBreakdown['2'])
                                },
                                {
                                    'Joint Breakdown': `${THREE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(jointBreakdown['3'])
                                },
                                {
                                    'Joint Breakdown': `${FOUR_OR_MORE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(jointBreakdown['>=4'])
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Space>
            </Panel>}
            {dimensionBreakdown && <Panel key={'dimensionBreakdown'} header={averageReports.DIMENSION_BREAKDOWN}>
                <Space align="center">
                    <Card
                        title={ROOF_TRUSSES}
                        style={{maxWidth: '70vw', minHeight: '67vh', alignItems: 'center', width: '60vw', marginLeft: '20%', alignContent: 'center'}}
                        extra={<Text type={"secondary"}>count: {totalRoofCount}  ({dimensionBreakdownPercentage(totalRoofCount)}%)</Text>}>
                        {totalRoofCount?<Space>
                            <Column
                                color={fillColor}
                                style={{maxWidth: '30vw'}}
                                xField={'Roof Span'}
                                yField={'Inches'}
                                yAxis={{
                                    title: {
                                        text: INCHES
                                    }
                                }}
                                label={{
                                    content: ({Inches}) => {
                                        return inchToFeet(Inches)
                                    },
                                    position: POSITION_OF_GRAPH_VALUES,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        'Roof Span': MAX_SPAN,
                                        'Inches': maxRoofSpan
                                    },
                                    {
                                        'Roof Span': AVG_SPAN,
                                        'Inches': avgRoofSpan
                                    },
                                    {
                                        'Roof Span': MEDIAN_SPAN,
                                        'Inches': medianRoofSpan
                                    },
                                    {
                                        'Roof Span': MIN_SPAN,
                                        'Inches': minRoofSpan
                                    }
                                ]}
                            />
                            <Column
                                color={fillColor}
                                style={{maxWidth: '30vw'}}
                                xField={'Roof Height'}
                                yField={'Inches'}
                                yAxis={{
                                    title: {
                                        text: INCHES
                                    }
                                }}
                                label={{
                                    content: ({Inches}) => {
                                        return inchToFeet(Inches)
                                    },
                                    position: POSITION_OF_GRAPH_VALUES,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        'Roof Height': MAX_HEIGHT,
                                        'Inches': maxRoofHeight
                                    },
                                    {
                                        'Roof Height': AVG_HEIGHT,
                                        'Inches': avgRoofHeight
                                    },
                                    {    'Roof Height': MEDIAN_HEIGHT,
                                         'Inches': medianRoofHeight
                                    },
                                    {
                                        'Roof Height': MIN_HEIGHT,
                                        'Inches': minRoofHeight
                                    }
                                ]}
                            />
                        </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Space>
                <Space align="center">
                    <Card title={FLOOR_TRUSSES} style={{maxWidth: '70vw', minHeight: '67vh', alignItems: 'center', width: '60vw', marginLeft: '20%', alignContent: 'center'}} extra={<Text type={"secondary"}>count: {totalFloorCount}  ({dimensionBreakdownPercentage(totalFloorCount)}%)</Text>}>
                        {totalFloorCount?<Space>
                            <Column
                                color={fillColor}
                                style={{maxWidth: '30vw'}}
                                xField={'Floor Span'}
                                yField={'Inches'}
                                yAxis={{
                                    title: {
                                        text: INCHES
                                    }
                                }}
                                label={{
                                    content: ({Inches}) => {
                                        return inchToFeet(Inches)
                                    },
                                    position: POSITION_OF_GRAPH_VALUES,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        'Floor Span': MAX_SPAN,
                                        'Inches': maxFloorSpan
                                    },
                                    {
                                        'Floor Span': AVG_SPAN,
                                        'Inches': avgFloorSpan
                                    },
                                    {
                                        'Floor Span': MEDIAN_SPAN,
                                        'Inches': medianFloorSpan
                                    },
                                    {
                                        'Floor Span': MIN_SPAN,
                                        'Inches': minFloorSpan
                                    }
                                ]}
                            />
                            <Column
                                color={fillColor}
                                style={{maxWidth: '30vw'}}
                                xField={'Floor Height'}
                                yField={'Inches'}
                                yAxis={{
                                    title: {
                                        text: INCHES
                                    }
                                }}
                                label={{
                                    content: ({Inches}) => {
                                        return inchToFeet(Inches)
                                    },
                                    position: POSITION_OF_GRAPH_VALUES,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        'Floor Height': MAX_HEIGHT,
                                        'Inches': maxFloorHeight
                                    },
                                    {
                                        'Floor Height': AVG_HEIGHT,
                                        'Inches': avgFloorHeight
                                    },
                                    {
                                        'Floor Height': MEDIAN_HEIGHT,
                                        'Inches': medianFloorHeight
                                    },
                                    {
                                        'Floor Height': MIN_HEIGHT,
                                        'Inches': minFloorHeight
                                    }
                                ]}
                            />
                        </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Space>
                <Card title={TRUSS_OVERVIEW} style={{width: '60vw', margin: 10, marginLeft: '14%'}}>
                    {(totalRoofCount || totalFloorCount)?<Pie
                        angleField={'Percentage'}
                        colorField={'Dimension Breakdown'}
                        label={{
                            type: 'inner',
                            offset: '-30%',
                            content: ({ Percentage }) => `${Percentage} Trusses`,
                            style: {
                                fontSize: 14,
                                textAlign: 'center',
                            },
                        }}
                        radius={0.8}
                        data={[
                            {
                                'Dimension Breakdown': ROOF_TRUSSES,
                                'Percentage': totalRoofCount
                            },
                            {
                                'Dimension Breakdown': FLOOR_TRUSSES,
                                'Percentage': totalFloorCount
                            }
                        ]}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>
            </Panel>}
            {inspectionFrequency && <Panel key={'inspectionFrequency'} header={averageReports.INSPECTION_FREQUENCY}>
                {inspectionFrequency.length>0?<Space style={{maxWidth: '100%', overflowX: 'auto'}}>
                    {inspectionFrequency?.map(({shift_name, lines}) =>
                        <Card title={shift_name} style={{width: '25vw'}}>
                            <Column
                                color={fillColor}
                                xField={'Inspections'}
                                yField={'No of Inspections'}
                                yAxis={{
                                    title: {
                                        text: INSPECTION_COUNT
                                    }
                                }}
                                data={lines?.map(({line_name, inspections}) => ({
                                    'Inspections': line_name,
                                    'No of Inspections': inspections
                                }))}
                            />
                        </Card>
                    )}
                </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            </Panel>}
            {inspectionFrequencyByCrew && <Panel key={'inspectionFrequencyByCrew'} header={averageReports.INSPECTION_FREQUENCY_BY_CREW}>
                {inspectionFrequencyByCrew.length?<Space>
                    <Column
                        color={fillColor}
                        xField={'Inspections'}
                        yField={'No of Inspections'}
                        yAxis={{
                            title: {
                                text: INSPECTION_COUNT
                            }
                        }}
                        data={inspectionFrequencyByCrew?.map(({crew_name, inspections, active_crew}) => ({
                            'Inspections': crew_name,
                            'No of Inspections': inspections
                        }))}
                    />
                </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            </Panel>}
            {setupFrequency&&<Panel key={'setupFrequency'} header={averageReports.SETUP_FREQUENCY}>
                <SetupFrequency setupFrequency={setupFrequency}/>
            </Panel>}
        </Collapse>
    </Fragment>
}

export default AverageReport;
