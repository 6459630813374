import React, {Fragment, useEffect, useState} from "react";
import {Card, Empty, notification, Segmented, Table, Typography} from "antd";
import {AppstoreOutlined, BorderOutlined} from '@ant-design/icons'
import {ColumnsType} from "antd/es/table";
import {Column} from "@ant-design/plots";
import {
    DataType,
    dateColumn, fillColor, formattedCriticalJointsConfigs,
    formattedPreliminaryInspectionConfigs,
    formattedTotalInspectionConfigs, PASS_PERCENTAGE
} from "../../utils/reportConstants";
import {processInspectionPassingData} from "../../utils/report";

interface TotalInspectionBody {
    range: string
    trusses: number
    total_out: number
    total_out_percent: number
}

interface PreliminaryInspectionBody {
    range: string
    preliminary_out: number,
    preliminary_out_percent: number,
    dimensions: number
    lumber: number
    plate: number
    teeth_gap: number
    preliminary_rotation: number
    mbr_gap: number
}

interface CriticalJointsBody {
    range: string
    joints: number
    detailed_out: number
    detailed_out_percent: number
    size: number
    gauge: number
    zero_tol_poly_available: number
    zero_tol_polygon: number
    detailed_rotation: number
}

interface InspectionPassingBody {
    range: string
    inspections: number
    preliminary: number
    joints: number
}

interface WeeklySummaryReportProps {
    totalInspections: TotalInspectionBody[]
    preliminaryInspections: PreliminaryInspectionBody[]
    criticalJoints: CriticalJointsBody[]
    inspectionPassing: InspectionPassingBody[]
}

const WeeklySummaryReport: React.FunctionComponent<WeeklySummaryReportProps> = ({
                                                                                    totalInspections,
                                                                                    preliminaryInspections,
                                                                                    criticalJoints,
                                                                                    inspectionPassing
                                                                                }) => {
    const [data, setData] = useState<any>([])
    const [layout, setLayout] = useState<string>('full')

    const load = () => {
        let tableData = []
        for(let i=0; i<totalInspections.length; i++) {
            if(
                totalInspections[i].range===preliminaryInspections[i].range &&
                totalInspections[i].range===criticalJoints[i].range
            ) {
                tableData.push({...totalInspections[i], ...preliminaryInspections[i], ...criticalJoints[i]})
            } else {
                notification.error({message: 'Found mismatch in date range counts'})
            }
        }
        setData(tableData)
    }

    useEffect(() => {
        load()
    }, [])

    const columns: ColumnsType<DataType> = [
        dateColumn,
        {
            title: 'Total Inspections',
            children: formattedTotalInspectionConfigs
        },
        {
            title: 'Preliminary Inspections',
            children: formattedPreliminaryInspectionConfigs
        },
        {
            title: 'Critical Joints',
            children: formattedCriticalJointsConfigs
        }
    ]

    const { Title } = Typography
    const level = 5

    return <Fragment>
        <Segmented
            style={{margin: 10}}
            options={[
                {
                    label: 'Full View',
                    value: 'full',
                    icon: <BorderOutlined/>,
                },
                {
                    label: 'Split View',
                    value: 'split',
                    icon: <AppstoreOutlined/>,
                },
            ]}
            onChange={value => setLayout(value as string)}
        />
        {layout==='full' && <div style={{overflowX: 'auto', margin: 10}}>
            <Table
                columns={columns}
                dataSource={data}
                bordered
                size="middle"
            />
        </div>}
        {layout==='split' && <div>
            <div style={{marginBottom: 25}}>
                <Title level={level} style={{textAlign: "center", margin: 10, marginBottom: 20}}>Total Inspections</Title>
                <Table
                    bordered
                    columns={[dateColumn, ...formattedTotalInspectionConfigs]}
                    dataSource={totalInspections as any}
                />
            </div>
            <div style={{marginBottom: 25}}>
                <Title level={level} style={{textAlign: "center", margin: 10, marginBottom: 20}}>Preliminary Inspections</Title>
                <Table
                    bordered
                    columns={[dateColumn, ...formattedPreliminaryInspectionConfigs]}
                    dataSource={preliminaryInspections as any}
                />
            </div>
            <div style={{marginBottom: 25}}>
                <Title level={level} style={{textAlign: "center", margin: 10, marginBottom: 20}}>Critical Joints</Title>
                <Table
                    bordered
                    columns={[dateColumn, ...formattedCriticalJointsConfigs]}
                    dataSource={criticalJoints as any}
                />
            </div>
        </div>}
        <Card title={PASS_PERCENTAGE}>
            {inspectionPassing?<Column
                color={fillColor}
                style={{margin: 10}}
                isGroup={true}
                seriesField={'name'}
                xField={'range'}
                yField={'percent'}
                yAxis={{
                    title: {
                        text: 'Percentage %'
                    }
                }}
                data={processInspectionPassingData(inspectionPassing)}
            />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </Card>
    </Fragment>
}

export default WeeklySummaryReport