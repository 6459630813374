import React, {Fragment} from "react";
import {Card, Descriptions, Empty, Typography} from "antd";
import moment from "moment";
import {ConfigsContext} from "../configs/ConfigsProvider";

interface CommentData {
    id: number,
    job_name: string,
    status: boolean,
    date: string,
    inspector_id: number,
    local_uuid: string,
    first_name: string,
    last_name: string,
    line_id: number,
    line_name: string,
    shift_id: number,
    shift_name: string,
    crew_id: number,
    crew_name: string,
    truss_id: string,
    type: string
    comments: string
}

interface CommentReportProps {
    data: CommentData[]
    column?: number
}

const CommentReport: React.FunctionComponent<CommentReportProps> = ({data, column}) => {
    const {Item} = Descriptions
    const {Text} = Typography
    return <Fragment>
        {data?.length>0?data?.map(({
                        id,
                        job_name,
                        status,
                        date,
                        local_uuid,
                        first_name,
                        last_name,
                        line_name,
                        shift_name,
                        crew_name,
                        truss_id,
                        type,
                        comments
                    }) => <Card style={{backgroundColor: '#f5f5f5', margin: 10}}>
            <Descriptions
                title={<Text>Job: {job_name}</Text>}
                column={column}
                extra={<Text type={"secondary"}>Id: {id}</Text>}
                labelStyle={{fontWeight: 'bold'}}
            >
                <Item label="Date"><ConfigsContext.Consumer>{configs=>moment(date).format(configs.dateTimeFormat)}</ConfigsContext.Consumer></Item>
                <Item label="Inspector">{first_name + ' ' + last_name}</Item>
                <Item label="Setup">{line_name}</Item>
                <Item label="Shift">{shift_name}</Item>
                <Item label="Crew">{crew_name}</Item>
                <Item label="Inspection Id">{local_uuid}</Item>
                <Item label="Truss Id">{truss_id}</Item>
                <Item label="Type">{type}</Item>
                <Item label="Status">{status?'Pass':'Fail'}</Item>
            </Descriptions>
            <Card>
                <Descriptions>
                    <Item>{comments}</Item>
                </Descriptions>
            </Card>
        </Card>):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </Fragment>
}

export default CommentReport