
import React, {useState} from 'react';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker, {PickerProps} from 'antd/es/date-picker/generatePicker';
import { Dayjs as DateType } from 'dayjs';
import moment, {Moment} from 'moment';

interface ExtendedDatePickerCustomWeekStartProps{
    start_day: number,
    value?: (DateType|undefined)[],
    disabledDate?: (current: Moment) => boolean,
    onChange?: (value:(Moment|undefined)[]) => void
}

export type DatePickerCustomWeekStartProps = PickerProps<DateType> & ExtendedDatePickerCustomWeekStartProps

const DatePickerCustomWeekStart:React.FunctionComponent<DatePickerCustomWeekStartProps> = ({start_day, onChange, value, disabledDate, ...base_props}) => {
    const [selected, setSelected] = useState<DateType|null>();

    const onSelect = (value:DateType|null) => {
        setSelected(value);
        // converting to moment to be compatible with parent component
        const momentValue =  value?moment(value?.toISOString()):undefined
        onChange?.([momentValue, momentValue]);
    }

    // override method
    const CustomDatePicker = generatePicker<DateType>({...dayjsGenerateConfig, locale: {...dayjsGenerateConfig.locale, getWeekFirstDay: () => start_day}});
    return <CustomDatePicker  value={selected} onChange={onSelect} {...base_props} disabledDate={disabledDate}/>
}

export default DatePickerCustomWeekStart