import React, {FunctionComponent} from "react";
import { Select, Space, Tooltip, Typography} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";

export interface HeaderProps {
    title: string
    isEdit: boolean
    isCheck?: boolean | undefined
    onCheck: (value: boolean) => void
    statusValue?: string | undefined
    onSelect: (value: string) => void
}

const options = [
    { value: 'ok', label: 'OK' },
    { value: 'opportunityToImprove', label: 'Opportunity to improve' },
    { value: 'improvementNeeded', label: 'Improvement needed' }
];

const Header: FunctionComponent<HeaderProps> = ({title, isEdit, isCheck, onCheck, statusValue, onSelect}) => {

    const {Text} = Typography

    return <Space>
        <Select
            value={statusValue}
            style={{ width: 120 }}
            disabled={!isEdit}
            onChange={(value) => {onSelect(value)}}
            options={options} />
        <Text strong>{title}</Text>
        <Tooltip title={"Tip will be available soon"} color={'blue'}>
            <InfoCircleOutlined
                style={{color: "#1890ff"}}
                onClick={() => console.log('clicked')}
            />
        </Tooltip>
    </Space>
}

export default Header
