
import React, {useState} from "react";
import {Button, Modal, notification, Spin} from "antd";
import {Form} from "@q4us-sw/q4us-ui";
import {CustomFormInput, FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {DataRequest, ITableContext, TableContext} from "@q4us-sw/q4us-ui/lib/TableV2";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import CustomDropDown from "./lib/CustomDropDown";
import {configs, fetch, insertCompanyLocations, update, inactive} from "../api";
import {validateEmail, validatePhone, validateZipPostal} from "../utils/util";
import CertifiedSettings from "./CertifiedSettings";
import {unstable_batchedUpdates} from "react-dom";
import {LOCATION_EDIT} from "../utils/constants";
import {getOrdering} from "./filters/ordering";
import {MapToFilter} from "./filters/mapping";

export interface RegisterProps{
    title: string;
    btnText: string;
    schema: string;
    tableName: 'company'|'locations';
    orderBy?: string[];
    onSubmit?: (values: any) => void;
    onCancel?: () => void;
    id?:number;
    row?: number;
    fetchCompanies?: () => void
    fetchLocations?: () => void
}

const getRequestPayload = (request:DataRequest) => {
    const column = request?.options?.inbuilt?.sort.column || 'id'
    const direction = request?.options?.inbuilt?.sort.direction || 'asc'
    const orderBy = getOrdering(column, direction);
    const filters = MapToFilter(request?.options?.values || {})
    return  {
        tableName: request.schema,
        orderBy:orderBy,
        filter:filters,
        page: {size: request?.page?.size || 100, from: ((request?.page?.current || 1) - 1) * (request?.page?.size || 100)}
    }
}

export const Register: React.FunctionComponent<RegisterProps> = (props:RegisterProps) => {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isCertifiedLocation, setIsCertifiedLocation] = React.useState<boolean>(false);
    const [opt, setOpt] = React.useState<{ values?: object, open: boolean }>({open: false});
    const [settingId, setSettingId] = React.useState<number>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleCancel = () => {
        props.onCancel?.()
        setIsModalVisible(false)
    };

    const handleOk = async (table:  ITableContext | undefined) => {
        if(props.tableName === 'company' || props.tableName === 'locations') {
            const res = await inactive({
                tableName: props.tableName,
                data: {...opt.values, id: props.id}
            })
            if(res.status === 200){
                notification.success({message: 'Success'})
                unstable_batchedUpdates(() => {
                    table?.setData(res.data?.rows || []);
                    table?.setPage({
                        current: res.data?.page?.current ?? table?.page?.current ?? 0,
                        total: res.data?.page?.total ?? table?.page?.total ?? 0,
                        size: res.data?.page?.size ?? table?.page?.size ?? 100
                    });
                    setIsModalVisible(false)
                    setLoading(false)
                })
            } else {
                notification.error({message: res?.data?.message || 'Error'})
                setLoading(false)
            }
        }
        setOpt({open: false});
    }
    const handleCancelButton = () => {
        setOpt({open: false});
        setLoading(false)
    }
    return <TableContext.Consumer>{
        table=><React.Fragment>
            <Button style={{ margin: 10 }} onClick={()=>{setIsModalVisible(true)}}>{props.btnText}</Button>
            <Modal
                title={props.title}
                width={'50vw'}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose
            >
                <Spin spinning={loading}>
                    <div style={{maxHeight: '60vh', overflowY: "auto", padding: 5, paddingBottom: 20}}>
                        {!settingId && <Form
                            schema={props.schema}
                            initialValue = {props.row || {is_active:true}}
                            acceptText={'Submit'}
                            config={async (schema:any) => {
                                const res = await configs(props.schema)
                                return res.data.rows;
                            }}
                            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                                switch (element.name) {
                                    case 'state':{
                                        return <CustomDropDown
                                            key={element.name}
                                            schema={schema}
                                            name={element.name}
                                            title={element.title}
                                            tableName={'state_ref'}
                                            filter={'country_code'}
                                            filterValue={form.getFieldValue('country')}
                                            enum_map={['code', 'name']}
                                        />
                                    }
                                    case 'area_code':{
                                        return <CustomDropDown
                                            key={element.name}
                                            schema={schema}
                                            name={element.name}
                                            title={element.title}
                                            tableName={'area_ref_view'}
                                            filter={'state_code'}
                                            filterValue={form.getFieldValue('state')}
                                            enum_map={['code', 'code']}
                                        />
                                    }
                                    case 'company_id':{
                                        if(props.title===LOCATION_EDIT) {
                                            // @ts-ignore
                                            return  <CustomFormInput
                                                disabled={true}
                                                type={element.type}
                                                title={element.title}
                                                name={element.name}
                                                schema={props.schema}
                                                enums={element.enums}
                                            />
                                        } else return undefined
                                    }
                                    default:
                                        return undefined;
                                }
                            }
                            }
                            visibilityValidator={(schema: string, element: any, form: any) => {
                                if(element.name==='area_code'){
                                    return form.getFieldValue('state')
                                }

                                if(element.name==='email' && props.row){
                                    return false;
                                }

                                const country = form.getFieldValue('country');
                                switch (element.title) {
                                    case 'State':
                                        return country === 'US';
                                    case 'Province':
                                        return country === 'CA';
                                    case 'Zip Code':
                                        return country === 'US';
                                    case 'Postal Code':
                                        return country === 'CA';
                                    default:
                                        return true
                                }
                            }}
                            validateField={async  (rule:any, value:any, schema:string, column:FormElementProps, form:FormInstance<any>) =>{
                                if (value!=null){
                                    switch (column.name){
                                        case "email":{
                                            if(!validateEmail(form.getFieldValue('email'))){
                                                return Promise.reject("Invalid Email Address")
                                            }
                                            break
                                        }
                                        case "contact_number":{
                                            if(!validatePhone(form.getFieldValue('contact_number'))){
                                                return Promise.reject('Invalid Contact Number')
                                            }
                                            break
                                        }
                                        case "zip_code":{
                                            if(!validateZipPostal(form.getFieldValue('zip_code'), form.getFieldValue('country'))){
                                                return Promise.reject('Invalid Code')
                                            }
                                            break
                                        }
                                        case 'comments':{
                                            break
                                        }
                                        default:
                                            const value = form.getFieldValue(column.name)
                                            if (value===''){
                                                return  Promise.reject('This field can\'t be empty')
                                            }
                                    }
                                }
                                return Promise.resolve()
                            }}
                            submit={async (values:any) => {
                                const isCertified = values.is_certified
                                let sId: number
                                let response

                                setLoading(true)
                                if((values.is_active === false && props.tableName ==='company') || (values.is_active === false && props.tableName === 'locations'))
                                {
                                    setOpt({values: values, open: true})
                                        if (props.id){
                                            if (isCertified) sId = props.id
                                        }
                                }
                                else {
                                    if (props.id){
                                        response = await update({
                                            tableName: props.tableName,
                                            data: {...values, id: props.id}
                                        })
                                        if(isCertified) sId = props.id
                                    }
                                    else{
                                        response = await insertCompanyLocations({
                                            tableName: props.tableName,
                                            data: {...values, id: props.row}
                                        })
                                        if(isCertified) sId = response?.data?.rows[0]?.id
                                    }
                                    if(response?.status===200){
                                        notification.success({message: 'Success'})
                                        if(isCertified) {
                                            unstable_batchedUpdates(() => {
                                                setIsCertifiedLocation(isCertified)
                                                setSettingId(sId)
                                                setLoading(false)
                                            })
                                        } else {
                                            const request = getRequestPayload({options:{values:table?.customData?.values, inbuilt:table?.customData?.options?.inbuilt}, page:table?.page, schema:props.tableName});
                                            const res = await fetch(request)
                                            await props.fetchCompanies?.();
                                            await props.fetchLocations?.();
                                            unstable_batchedUpdates(() => {
                                                table?.setData(res.data?.rows || []);
                                                table?.setPage({
                                                    current: res.data?.page?.current ?? table?.page?.current ?? 0,
                                                    total: res.data?.page?.total ?? table?.page?.total ?? 0,
                                                    size: res.data?.page?.size ?? table?.page?.size ?? 100
                                                });
                                                setIsModalVisible(isCertified)
                                                setLoading(false)
                                            })
                                        }
                                        props.onSubmit?.(values)
                                        return true
                                    }else{
                                        notification.error({message: response?.data?.message || 'Error'})
                                        setLoading(false)
                                    }
                                }

                            }}
                        />}
                        {isCertifiedLocation && settingId && <CertifiedSettings
                            type={"location"}
                            location_id={settingId}
                            onFinish={async () => {
                                const request = getRequestPayload({options:{values:table?.customData?.values, inbuilt:table?.customData?.options?.inbuilt}, page:table?.page, schema:props.tableName});
                                const res = await fetch(request)
                                await props.fetchCompanies?.();
                                await props.fetchLocations?.();
                                unstable_batchedUpdates(() => {
                                    table?.setData(res.data?.rows || []);
                                    table?.setPage({
                                        current: res.data?.page?.current ?? table?.page?.current ?? 0,
                                        total: res.data?.page?.total ?? table?.page?.total ?? 0,
                                        size: res.data?.page?.size ?? table?.page?.size ?? 100
                                    })
                                    setIsCertifiedLocation(false)
                                    setSettingId(undefined)
                                    setIsModalVisible(false)
                                })
                            }}
                        />}
                    </div>
                </Spin>
            </Modal>
            <Modal title="Confirmation" open={opt.open} onCancel={handleCancelButton} onOk={() => {handleOk(table);}}>
               <React.Fragment>
                Please note that deactivating this company or location will also result in the deactivation of all associated locations and users.
               </React.Fragment>
            </Modal>
        </React.Fragment>
    }</TableContext.Consumer>
}