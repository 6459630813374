import React, {Fragment, useEffect, useState} from "react";
import {
    Alert,
    Button,
    Col,
    Divider,
    Input,
    InputNumber,
    notification,
    Row,
    Segmented,
    Select,
    Space,
    Typography
} from "antd";
import {feetToInch, inchToFeet} from "../utils/report";
import {fetch, insert, update, updateWithoutNull} from "../api"
import {CheckOutlined, EditOutlined} from "@ant-design/icons";
import {unstable_batchedUpdates} from "react-dom";

interface SpanProps {
    initialValue: number
    onChange: (value: number | null) => void
}

const Span: React.FunctionComponent<SpanProps> = ({initialValue, onChange}) => {
    const [invalid, setInvalid] = useState<boolean>(false)
    const [type, setType] = useState<string>('ft')
    const [curValue, setCurValue] = useState<number | null>()
    const [curFeetValue, setCurFeetValue] = useState<string>()

    const options = ['in', 'ft']

    const unit = <Segmented
        value={type}
        size={"small"}
        options={options}
        onChange={value => setType(value as string)}
    />

    return <Fragment>
        {type==='in'?
            <InputNumber
                addonAfter={unit}
                style={{width: '95%'}}
                value={curValue || initialValue}
                onChange={value => {
                    setCurValue(value)
                    onChange(value)
                }}
            />:
            <Input
                status={invalid?'error':undefined}
                addonAfter={unit}
                placeholder={'4-3-2'}
                style={{width: '95%'}}
                value={curFeetValue || (initialValue && inchToFeet(initialValue))}
                onChange={e => {
                    const value = e.target.value
                    setCurFeetValue(e.target.value)
                    const regex = /^\d+-\d{1,2}-\d{1,2}$/
                    if(value.match(regex)) {
                        const [feetAsStr, inchesAsStr, sixteenthAsStr] = value.split('-')
                        const feet: number = parseInt(feetAsStr)
                        const inches: number = parseInt(inchesAsStr)
                        const sixteenth: number = parseInt(sixteenthAsStr)
                        if (inches < 12 && sixteenth < 16) {
                            const valueInInches = feetToInch(feet, inches, sixteenth)
                            setInvalid(false)
                            onChange(valueInInches as number)
                        } else {
                            setInvalid(true)
                        }
                    } else {
                        setInvalid(true)
                    }
                }}
            />}
    </Fragment>
}

interface ScoreBody {
    id: number
    name: string
    title: string
    value: number | null
}

interface ScoreValueProps extends ScoreBody{
    score: ScoreBody[]
    onChange: (scoreValue: ScoreBody) => void
}

const ScoreValue: React.FunctionComponent<ScoreValueProps> = ({score, id, name, title, value, onChange}) => {
    const [data, setData] = useState<ScoreBody[]>(score)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [curValue, setCurValue] = useState<number | null>()

    return <Fragment>
        <InputNumber
            style={{width: '100%'}}
            disabled={!isEdit}
            min={0}
            max={100}
            value={curValue || value}
            addonAfter={
                isEdit?
                    <CheckOutlined
                        onClick={async () => {
                            const scoreData = {
                                id,
                                name,
                                title,
                                value: curValue
                            }
                            const payload = {
                                tableName: 'score',
                                data: scoreData
                            }
                            onChange(scoreData as ScoreBody)
                            const {data: {statusCode} = {}} = await update(payload)
                            statusCode===200 && notification.success({message: 'Updated'})
                            setIsEdit(false)
                        }}
                    />:
                    <EditOutlined
                        onClick={() => setIsEdit(true)}
                    />
            }
            onChange={targetValue => {
                let modifiedScoreList = data?.map(scoreElement => {
                    if(scoreElement.id===id) {
                        return {id, name, title, value: targetValue}
                    } else {
                        return scoreElement
                    }
                })
                unstable_batchedUpdates(() => {
                    setCurValue(targetValue)
                    setData(modifiedScoreList || [])
                })
            }}
        />
    </Fragment>
}

const Score: React.FunctionComponent = () => {
    const [score, setScore] = useState<ScoreBody[]>()

    const total = () => score?.reduce((acc, cur) => acc + (cur.value || 0), 0)

    const load = async () => {
        const {data: {rows = []} = {}} = await fetch({tableName: 'score', orderBy: ['id']})
        setScore(rows as ScoreBody[])
    }

    useEffect(() => {
        load()
    }, [])

    const { Text } = Typography;
    return <Fragment>
        <Alert message="Score" type="info" style={{width: '25%'}}/>
        <Row style={{margin: 10}}>
            <Col span={18}><Text strong>Type</Text></Col>
            <Col style={{textAlign: "center"}} span={6}><Text strong>Weight %</Text></Col>
        </Row>
        {score?.map(({id, name, title, value}) => <Row style={{margin: 10}}>
            <Col span={18}><Text>{title}</Text></Col>
            <Col span={6}>
                <ScoreValue
                    score={score}
                    onChange={scoreValue => setScore(
                        score?.map(scoreItem => {
                            if(scoreItem.id===scoreValue.id) {
                                return scoreValue
                            } else {
                                return scoreItem
                            }
                        })
                    )}
                    id={id}
                    name={name}
                    title={title}
                    value={value}
                />
            </Col>
        </Row>)}
        <Row style={{margin: 10}}>
            <Col span={18}><Text strong>Total</Text></Col>
            <Col span={6}>
                <Text type={total()===100?"success":"danger"} strong>{total()}</Text>
            </Col>
        </Row>
        {total()&&total()!==100?<Alert style={{margin: 10, marginTop: 5}} message="Total should be equal to 100" type="error" showIcon closable />:undefined}
    </Fragment>
}

interface ReportSetting{
    id: number
    location_id: number
    type: "location" | "general" | "global"
    trusses_per_shift_per_week: number
    hours_per_week: number
    multiplier: number
    reducer_score: number
    avg_roof_span: number
    avg_floor_span: number
    certification_minimum_floor: number
    certification_minimum_roof: number
    minimum_required_floor: number
    minimum_required_roof: number
    detailed_certification_minimum_floor: number
    detailed_certification_minimum_roof: number
    average_distance_back: number
    average_distance_front: number
    average_rotation_back: number
    average_rotation_front: number
}

interface CertifiedSettingsProps {
    location_id?: number
    type: "location" | "general" | "global"
    onFinish?: () => void
}

const CertifiedSettings: React.FunctionComponent<CertifiedSettingsProps> = ({type, location_id, onFinish}) => {
    const [data, setData] = useState<any>()
    const [isNew, setIsNew] = useState<boolean>()
    const [loading, setLoading] = useState<boolean>(false)

    const load = async () => {
        const payload = {
            tableName: 'report_settings',
            filter: location_id?[{name: 'location_id', value: location_id}, {name: 'type', value: type}]:[{name: 'type', value: type}]
        }
        const {data: {rows = [], rowCount} = {}} = await fetch(payload)
        if(rowCount===0) {
            unstable_batchedUpdates(() => {
                setData({location_id, type})
                setIsNew(true)
            })
        } else {
            unstable_batchedUpdates(() => {
                setData(rows[0] as ReportSetting)
                setIsNew(false)
            })
        }
    }

    useEffect(() => {
        load()
    }, [])

    const titles = Object.freeze({
        location: 'Location Certified Location Settings',
        general: 'General Certified Location Settings',
        global: 'Global Certified Location Settings'
    })

    const { Text, Title } = Typography;
    const { Option } = Select;

    const thresholdOptions = [
        { value: 'percentage', label: 'Percentage' },
        { value: 'amount', label: 'Amount' },
    ];
    return <Fragment>
        <div style={type==='location'?{width: '100%'}:{marginLeft: '20%', width: '50%'}}>
            <Title level={4} style={{textAlign: "center", margin: 25, marginBottom: 50}}>{titles[type]}</Title>
            {type==='global' && <Score/>}
            <Alert message="Frequency" type="info" style={{width: '25%'}}/>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Target Trusses per Shift per Week</Text></Col>
                <Col span={12}><InputNumber style={{width: '50%'}} value={data?.trusses_per_shift_per_week} onChange={value => setData({...data, trusses_per_shift_per_week: value})}/></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Target Hours per Week</Text></Col>
                <Col span={12}><InputNumber addonAfter={'h'} style={{width: '50%'}} value={data?.hours_per_week} onChange={value => setData({...data, hours_per_week: value})}/></Col>
            </Row>
            <Divider />
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Multiplier</Text></Col>
                <Col span={12}><InputNumber style={{width: '50%'}} value={data?.multiplier} onChange={value => setData({...data, multiplier: value})}/></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Reducer Score</Text></Col>
                <Col span={12}><InputNumber style={{width: '50%'}} value={data?.reducer_score} onChange={value => setData({...data, reducer_score: value})}/></Col>
            </Row>
            <Alert message="Span" type="info" style={{width: '25%'}}/>
            <Row style={{margin: 10}}>
                <Col span={12}/>
                <Col style={{textAlign: "center"}} span={6}><Text strong>Floor</Text></Col>
                <Col style={{textAlign: "center"}} span={6}><Text strong>Roof</Text></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Certification Average</Text></Col>
                <Col span={6}><Span initialValue={data?.avg_floor_span} onChange={value => setData({...data, avg_floor_span: value})}/></Col>
                <Col span={6}><Span initialValue={data?.avg_roof_span} onChange={value => setData({...data, avg_roof_span: value})}/></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Certification Minimum</Text></Col>
                <Col span={6}><Span initialValue={data?.certification_minimum_floor} onChange={value => setData({...data, certification_minimum_floor: value})}/></Col>
                <Col span={6}><Span initialValue={data?.certification_minimum_roof} onChange={value => setData({...data, certification_minimum_roof: value})}/></Col>
            </Row>
            <Alert message="Critical Joints" type="info" style={{width: '25%'}}/>
            <Row style={{margin: 10}}>
                <Col span={12}/>
                <Col style={{textAlign: "center"}} span={6}><Text strong>Floor</Text></Col>
                <Col style={{textAlign: "center"}} span={6}><Text strong>Roof</Text></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Minimum Required</Text></Col>
                <Col span={6}><InputNumber style={{width: '95%'}} value={data?.minimum_required_floor} onChange={value => setData({...data, minimum_required_floor: value})}/></Col>
                <Col span={6}><InputNumber style={{width: '95%'}} value={data?.minimum_required_roof} onChange={value => setData({...data, minimum_required_roof: value})}/></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Certification Minimum</Text></Col>
                <Col span={6}><InputNumber style={{width: '95%'}} value={data?.detailed_certification_minimum_floor} onChange={value => setData({...data, detailed_certification_minimum_floor: value})}/></Col>
                <Col span={6}><InputNumber style={{width: '95%'}} value={data?.detailed_certification_minimum_roof} onChange={value => setData({...data, detailed_certification_minimum_roof: value})}/></Col>
            </Row>
            <Alert message="Goals" type="info" style={{width: '25%'}}/>
            <Row style={{margin: 10}}>
                <Col span={12}/>
                <Col style={{textAlign: "center"}} span={6}><Text strong>Front</Text></Col>
                <Col style={{textAlign: "center"}} span={6}><Text strong>Back</Text></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Average Distance</Text></Col>
                <Col span={6}><InputNumber max={16} addonAfter={'/16ths'} style={{width: '95%'}} value={data?.average_distance_front} onChange={value => setData({...data, average_distance_front: value})}/></Col>
                <Col span={6}><InputNumber max={16} addonAfter={'/16ths'} style={{width: '95%'}} value={data?.average_distance_back} onChange={value => setData({...data, average_distance_back: value})}/></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Average Rotation</Text></Col>
                <Col span={6}><InputNumber max={360} addonAfter={'°'} style={{width: '95%'}} value={data?.average_rotation_front} onChange={value => setData({...data, average_rotation_front: value})}/></Col>
                <Col span={6}><InputNumber max={360} addonAfter={'°'} style={{width: '95%'}} value={data?.average_rotation_back} onChange={value => setData({...data, average_rotation_back: value})}/></Col>
            </Row>
            <Alert message="In Plant Values" type="info" style={{width: '25%'}}/>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Threshold Type</Text></Col>
                <Col span={12}><Select style={{width: '50%'}} value={data?.threshold_type} onChange={value => setData({...data, threshold_type: value})}>
                    {thresholdOptions.map(option => (
                        <Option key={option.value} value={option.value}>{option.label}</Option>
                    ))}
                </Select></Col>
            </Row>
            <Row style={{margin: 10}}>
                <Col span={12}><Text>Threshold Value</Text></Col>
                <Col span={12}><InputNumber style={{width: '50%'}} min={1} value={data?.threshold_value} onChange={value => setData({...data, threshold_value: value})}/></Col>
            </Row>
            <Space style={{margin: 20, float: "right", paddingBottom: 20}}>
                <Button
                    type={"primary"}
                    loading={loading}
                    onClick={async () => {
                        let status
                        setLoading(true)
                        if(isNew) {
                            const {data: {rows, statusCode} = {}} = await insert({tableName: 'report_settings', data})
                            if(statusCode===200) {
                                unstable_batchedUpdates(() => {
                                    setData({...data, id: rows[0]?.id})
                                    setLoading(false)
                                })
                            }
                            status = statusCode
                        } else {
                            const {data: {statusCode} = {}} = await updateWithoutNull({tableName: 'report_settings', data})
                            setLoading(false)
                            status = statusCode
                        }
                        if(status===200) {
                            notification.success({message: 'Settings are saved successfully'})
                        } else {
                            notification.error({message: 'Error while saving settings'})
                        }
                        onFinish && onFinish()
                    }}
                >Save</Button>
            </Space>
        </div>
    </Fragment>
}

export default CertifiedSettings;