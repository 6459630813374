import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Modal, notification, Row, Space, Tabs, Tag, Typography, Image, Alert} from 'antd';
import {TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {fetch_inspection, logReportAddSign, logReportGetSign} from "../../api";
import {DownloadOutlined} from "@ant-design/icons";
import {handleScroll} from "../../utils/util";
import {Form} from "@q4us-sw/q4us-ui";
import {unstable_batchedUpdates} from "react-dom";
import moment from "moment";
import {ConfigsContext} from "../configs/ConfigsProvider";
import {FormInstance} from 'antd/lib/form/hooks/useForm'
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import DatePickerFormatted from "../lib/DatePickerFormatted";
import Signature from "../lib/Signature";
import {MetaProps} from "./Reports";

const {Title, Text} = Typography;


interface logReportProps {
    reportData: any,
    metaData: MetaProps,
    locationID?: number
}

interface SignProps {
    metaData: MetaProps,
    isPDFView?: boolean
    locationID?: number
}

interface SignState{
    sign_off: string,
    signature: string,
    sign_off_date: string,
    hash_valid: boolean,
    updated_timestamp: string,
    user_name: string
    comments?: string,
}

interface LogReportTableProps {
    tableConfigs: any
    subTableConfigs: any
    data: any
    isPDFView?: boolean
}

interface InspectionListTableProps {
    subTableConfigs: any
    inspectionsList: any[]
}

const InspectionListTable: React.FunctionComponent<InspectionListTableProps> = ({inspectionsList, subTableConfigs}) => {
    return <TableV2
        schema={'-'}
        fetchConfig={async (schema) => {
            return {data:{
                    statusCode: 200,
                    rows: subTableConfigs
                }}
        }}
        fetchData={async (request) => {
            handleScroll()
            return {data: {
                    statusCode: 200,
                    rows: inspectionsList
                }}
        }}
        customRenderer={{
            id: (value, row, index, column) => {
                const {id} = row
                return <div>
                    <Space>
                        <Button onClick={async () => {
                            await fetch_inspection(id, "html")
                        }}>View</Button>
                        <Button type="primary" icon={<DownloadOutlined />} onClick={async () => {
                            await fetch_inspection(id, "pdf")
                        }}>PDF</Button>
                    </Space>
                </div>
            },
            status: (value, row, index, column) => {
                return <Tag color={value?"green":"red"}>{value?'Passed':'Not Conforming'}</Tag>
            }
        }}
    />
}

export const LogReportTable: React.FunctionComponent<LogReportTableProps> = ({tableConfigs, subTableConfigs, data, isPDFView}) => {
    return <TableV2
        schema={'-'}
        fetchConfig={async (schema) => {
            return  {data:{
                statusCode: 200,
                    rows: tableConfigs
            }}
        }}
        fetchData={async (request) => {
            handleScroll()
            return {data: {
                    statusCode: 200,
                    rows: data?.map((line:{line_id:number, line_sum: number, line_data: any, line_name: string, inspections_list: any})=>{
                        const {line_data, ...data} = line
                        return  {
                            ...data,
                            ...line_data
                        }
                    })
                }}
        }}
        scroll={isPDFView?undefined:{y: 'calc(70vh - 20px)', x: 'max-content' }}
        expandable={{
            expandedRowRender: record => {
                return <InspectionListTable inspectionsList={record.inspections_list} subTableConfigs={subTableConfigs}/>
            },
            rowExpandable: record => {
                return record.inspections_list?.length > 0;
            },
            defaultExpandAllRows: !!isPDFView,
            expandIcon: isPDFView?()=><div/>:undefined
        }}
        enumOverride={{
            '*': () => {
                return [['undefined', 'NA']]
            }
        }}
    />
}

export const Sign: React.FunctionComponent<SignProps> = ({isPDFView, locationID, metaData}) =>{
    const [showAddSign, setShowAddSign] = useState<boolean>(false);
    const [sign, setSign] = useState<SignState|undefined>();

    useEffect(()=>{
        (async () =>{
            const {data: sign_data} = await logReportGetSign({
                start_date: moment(metaData.start_date).format('YYYY-MM-DD'),
                end_date: moment(metaData.end_date).format('YYYY-MM-DD'),
                location_id: locationID
            });
            if (sign_data){
                setSign?.(sign_data);
            }
        })()
    }, []);

    return <div>
        {sign?.sign_off && <div style={{margin: 10}}>
            <Divider/>
            {!sign?.hash_valid&&<Alert message="Sign Off Invalid. Log data has been updated." type="error" style={{marginBottom: 20}}/>}
            <Row gutter={[8, 24]}>
                <Col span={4}><Title level={5}>Manager Signature</Title ></Col>
                <Col span={9}>{sign?.sign_off}</Col>
                <Col span={2}><Title level={5}>Date</Title></Col>
                <Col span={9}><ConfigsContext.Consumer>{configs =>
                    <Typography.Text>{moment(sign?.sign_off_date).format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer></Col>
            </Row>
            {sign?.signature&&<Row gutter={[8, 24]}>
                <Col span={4}><Title level={5}>Signature</Title></Col>
                <Col span={20}><Image style={{padding: 20}} src={sign?.signature} preview={false}/></Col>
            </Row>}
            <Row gutter={[8, 24]}>
                <Col span={4}><Title level={5}>Comments</Title></Col>
                <Col span={20}>{sign?.comments}</Col>
            </Row>
            <Row gutter={[8, 24]}>
                <Col span={24}>
                    <Text type="secondary" italic>
                        Signed by "{sign?.user_name}" at {<ConfigsContext.Consumer>{configs =>moment(sign?.updated_timestamp).format(configs.dateTimeFormatExtended)}</ConfigsContext.Consumer>}
                    </Text>
                </Col>
            </Row>
            <Divider/>
        </div>}
        {!isPDFView && <Button
            style={{ margin: 10 }}
            onClick={()=>{setShowAddSign(true)}}
            type="primary"
        >
            {sign?'Update Manager Signature':'Add Manager Signature'}
        </Button>}
        <Modal
            title={"Add/Update Manager Sign"}
            width={'50vw'}
            visible={showAddSign}
            onCancel={()=>{setShowAddSign(false)}}
            cancelText={"Reset"}
            footer={null}
            destroyOnClose
        >
            <Form
                schema={''}
                initialValue={sign}
                acceptText={sign?'Update Sign':'Add Sign'}
                config={async (schema:any) => {
                    return [
                        {
                            name: 'sign_off',
                            title: 'Manager Signature',
                            type: 'STRING',
                            required: true
                        },
                        {
                            name: 'signature',
                            title: 'Signature',
                            type: 'STRING',
                            required: true
                        },
                        {
                            name: 'comments',
                            title: 'Comments',
                            type: 'STRING'
                        },
                        {
                            name: 'sign_off_date',
                            title: 'Date',
                            type: 'DATE',
                            required: true
                        }
                    ];
                }}
                overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                    if (element.name === 'sign_off_date'){
                        return <DatePickerFormatted disabledDate={(current => current > moment().endOf("day"))}/>
                    }
                    else if (element.name === 'signature'){
                        return <Signature/>
                    }
                    return undefined;
                }}
                submit={async (values:any) => {
                    const sign_off_date = moment(values.sign_off_date).utc().set({hour: 12, minute: 0, second: 0, millisecond: 0});
                    // there is a size limit for POST request fields
                    // if it is exceeded need to convert to multipart/form-data
                    if (values.signature.length >= 1e6){
                        notification.warning({message: 'Signature is too large. Information might be lost.'});
                    }
                    const res = await logReportAddSign({
                        ...values,
                        sign_off_date: sign_off_date.toString(),
                        meta_data: {
                            start_date: moment(metaData.start_date).format('YYYY-MM-DD'),
                            end_date: moment(metaData.end_date).format('YYYY-MM-DD')
                        },
                        location_id: locationID
                    });
                    if(res.status === 200) {
                        const {data: sign_data} = await logReportGetSign({
                            start_date: moment(metaData.start_date).format('YYYY-MM-DD'),
                            end_date: moment(metaData.end_date).format('YYYY-MM-DD'),
                            location_id: locationID
                        });
                        if (sign_data){
                            notification.success({message: 'Sign-Off Added'});
                            unstable_batchedUpdates(()=>{
                                setSign?.(sign_data);
                                setShowAddSign(false);
                            })
                        }
                        else{
                            notification.error({message: 'Unabled to fetch Sign-Off'});
                        }
                    }
                }}
                rejected={(form: FormInstance) => {
                    form.resetFields();
                }}
            />
        </Modal>
    </div>
}

const LogReport: React.FunctionComponent<logReportProps> = ({reportData, locationID, metaData}) => {
    return <React.Fragment>
        <Tabs>
            {reportData?.timeLogs?.map((shift: {shift_id:number, shift_data: any, name: string})=>{
                return <Tabs.TabPane tab={shift.name} key={shift.shift_id}>
                    <LogReportTable tableConfigs={reportData?.tableConfigs} subTableConfigs={reportData.subTableConfigs} data={shift.shift_data}/>
                </Tabs.TabPane>
            })}
        </Tabs>
        <Sign locationID={locationID} metaData={metaData}/>
    </React.Fragment>
}

export default LogReport;