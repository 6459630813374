import React, {useEffect, useRef, useState} from "react";
import {fetchWithoutPageData, setDailyHours} from "../api";
import {
    Button,
    Col,
    DatePicker,
    InputNumber,
    notification,
    PageHeader,
    Row,
    Select,
    Space,
    Spin,
    Table,
    Tabs,
    Tooltip,
    Typography
} from "antd";
import {useHistory, useLocation} from 'react-router-dom';
import {ExclamationCircleOutlined, PlusCircleOutlined} from '@ant-design/icons';
import moment from "moment-timezone";
import {MapToFilter} from "./filters/mapping";
import {getOrdering} from "./filters/ordering";
import {unstable_batchedUpdates} from "react-dom";
import DatePickerFormatted, {DateRangePickerFormatted} from "./lib/DatePickerFormatted";
import {LocationsFilterBaseProps} from "./filters/LocationsFilter";
import {DISABLED_DATE} from "../utils/constants";

export const fetchDailyHoursData = async (values: { [key: string]: any },
                                          company_id?: number,
                                          location_id?: number,
                                          {column='id', direction='desc'}:{column?:string, direction?:string}={}) => {
    const filters = MapToFilter(values)
    const orderBy = getOrdering(column, direction);
    return await fetchWithoutPageData({
        tableName: 'daily_hours',
        orderBy: orderBy,
        filter: [...filters, {name: 'company_id', value: company_id}, {name: 'location_id', value: location_id}]
    }) || {}
}

interface DailyHourDateProps {
    lineId?: number
    crewId?: number
    shiftId?: number
    taskDate?: Date
    parentCallBack: (value: any) => void
    value: number | undefined
}

const DailyHourDate: React.FunctionComponent<DailyHourDateProps> = ({
                                                                        lineId,
                                                                        crewId,
                                                                        shiftId,
                                                                        taskDate,
                                                                        parentCallBack,
                                                                        value

                                                                    }) => {
    const onChangeDateHour = (value: any) => {
        parentCallBack({
            line_id: lineId,
            crew_id: crewId,
            shift_id: shiftId,
            job_date: moment(taskDate).format("YYYY-MM-DD"),
            hours: value
        })
    }

    return <Space direction="horizontal">
        <DatePickerFormatted style={{maxWidth: 143}} value={moment(taskDate)} disabled/>
        <InputNumber
            key={`${crewId}_${lineId}_${shiftId}_${moment(taskDate).format('DD-MM-YYYY')}`}
            min={0}
            max={15}
            step={"0.25"}
            defaultValue={value}
            value={value}
            onChange={onChangeDateHour}
        />
        {value===undefined && <Tooltip title="No hours recorded for date" color={'#FBBE43'} mouseEnterDelay={0.5}>
            <ExclamationCircleOutlined style={{color: '#FBBE43'}}/>
        </Tooltip>}
    </Space>
}

interface DailyHourDateRangeProps {
    date: any
    parentCallBack: (value: any) => void
    lines: [id: string, name: string][]
    shifts: [id: string, name: string][]
    crews: [id: string, name: string][]
    isDaily: boolean
    companyId?: number
    locationId?: number
    updatedData: DailyHoursRow[]
    allData: DailyHoursRow[]
    // user_id?: string
}

interface DailyHoursRow {
    company_id?: number
    location_id?: number
    line_id?: number
    crew_id?: number
    hours?: number
    shift_id?: number
    job_date?: Date
}

export interface AddDailyHoursProps extends Partial<LocationsFilterBaseProps>{

}

const DailyHourDateRange: React.FunctionComponent<DailyHourDateRangeProps> = ({
                                                                                  date,
                                                                                  parentCallBack,
                                                                                  lines,
                                                                                  shifts,
                                                                                  crews,
                                                                                  isDaily,
                                                                                  companyId,
                                                                                  locationId,
                                                                                  updatedData,
                                                                                  allData
                                                                              }) => {
    const [selectedShiftId, setSelectedShiftId] = useState(parseInt(shifts[0][0]))
    let [data, setData] = useState<DailyHoursRow[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [current, setCurrent] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(20);
    const {TabPane} = Tabs
    let isUpdated = false;
    const isPaginationChange = useRef(false);

    const loadData = async () => {
        if(date) {
            let values: {[key: string]: any} = {shift_id: selectedShiftId}
            if(isDaily) {
                values = {...values, job_date: moment(date).format('YYYY-MM-DD')}
            } else {
                const [job_start_date, job_end_date] = date
                values = {...values, job_start_date, job_end_date}
            }
            setLoading(true)
            const {data: {rows =[]} ={}} = await fetchDailyHoursData(values, companyId, locationId)
            if(allData?.length >= 0) {
            rows?.forEach((row: any) => {
                allData?.forEach((rowOne) => {
                    const allDataIndex = `${rowOne.line_id}_${rowOne.crew_id}_${rowOne.shift_id}_${rowOne.location_id}_${rowOne.job_date}`;
                    const rowIndex = `${row.line_id}_${row.crew_id}_${row.shift_id}_${row.location_id}_${moment.utc(row.job_date).format('YYYY-MM-DD')}`;
                    if(allDataIndex === rowIndex) {
                        row.hours = (rowOne.hours)?.toString()
                    } else {
                        rows.push(rowOne)
                    }
            })}
            );}
            unstable_batchedUpdates(() => {
                setData(rows)
                setLoading(false)
            })
        }
    }

    const keyMap = new Map();
    updatedData.forEach((item) => {
        const key = `${item.line_id}_${item.crew_id}_${item.shift_id}_${item.location_id}_${item.job_date}`;
        keyMap.set(key, item.hours);
    });

    data.forEach((item) => {
        const key = `${item.line_id}_${item.crew_id}_${item.shift_id}_${item.location_id}_${moment.utc(item.job_date).format('YYYY-MM-DD')}`;
        const updatedHours = keyMap.get(key);
        if (updatedHours !== undefined) {
            item.hours = updatedHours.toString();
            isUpdated = true;
        }
    });

    if(!isUpdated) {
        data.push(...updatedData)
    }

    useEffect(() => {
        loadData()
    }, [selectedShiftId, date])

    const getDateList = () => {
        if (date) {
            let start
            let end
            if(isDaily) {
                start = new Date(date)
                end = new Date(date)
            } else {
                start = new Date(date[0])
                end = new Date(date[1])
            }
            let collect = []

            let loop = new Date(start)
            while (loop <= end) {
                collect.push(new Date(loop))
                let newDate = loop.setDate(loop.getDate() + 1)
                loop = new Date(newDate)
            }
            return collect
        }
        return []
    }

    const onChangeDateRangeHour = (data: DailyHoursRow) => {
        data['company_id'] = companyId
        data['location_id'] = locationId
        data['shift_id'] = selectedShiftId
        parentCallBack(data)
    }

    const convertLinesToColumns = () => {
        let columns: {}[] = []
        columns.push({title: ' ', dataIndex: 'crew_name', key: 0, width: 200, fixed: 'left'})
        lines.forEach(([id, name], i) => {
            columns.push({
                title: name,
                dataIndex: i + 1,
                key: i + 1,
                width: 300,
                render: (text: string, record: any) => {
                    return <Spin size={"small"} spinning={loading}>
                        <Space direction="vertical" style={{maxHeight: '120px', overflowY: 'scroll'}}>
                            {getDateList().map((date, index) => {
                                    return <DailyHourDate
                                        key={index}
                                        lineId={parseInt(id)}
                                        crewId={record['crew_id']}
                                        shiftId={record['shift-id']}
                                        taskDate={date}
                                        parentCallBack={onChangeDateRangeHour}
                                        value={(data?.find((row) =>
                                                row.line_id === parseInt(id)
                                                && row.crew_id === record.crew_id
                                                && row.shift_id === record.shift_id
                                                && moment.utc(row.job_date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'))
                                            || {hours: undefined})['hours']}
                                    />
                                }
                            )}
                        </Space>
                    </Spin>
                }
            })
        })
        return columns
    }

    return <div>
        <Tabs defaultActiveKey={shifts[0][0]} onChange={key => setSelectedShiftId(parseInt(key))}>
            {
                shifts.map(([id, name], i) => <TabPane tab={name} key={id}>
                    <Space direction="vertical" style={{maxWidth: '85vw', overflowX: 'auto'}}>
                        <Table
                            dataSource={crews.map(([crewId, name], i) => ({key: i, crew_name: name, crew_id: parseInt(crewId), shift_id: parseInt(id)}))}
                            columns={convertLinesToColumns()}
                            pagination={{
                                pageSize: 20,
                                showTotal: (total, range) => `Records ${range[0]}-${range[1]} from ${total} items`,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50'],
                                current: current,
                                onChange: (newPage, newPageSize) => {
                                    isPaginationChange.current = true;
                                    setPageSize(newPageSize);
                                    setCurrent(pageSize !== newPageSize ? 1 : newPage);
                                }
                            }}
                            scroll={{y: 'calc(100vh - 550px)', x: '100%'}}
                        />
                    </Space>
                </TabPane>)
            }
        </Tabs>
    </div>
};

export const AddDailyHours: React.FunctionComponent<AddDailyHoursProps> = (props) => {
    const {Text} = Typography
    const {Option} = Select
    const location = useLocation<any>()
    const {enumList, isDaily} = location.state
    const [date, setDate] = useState()
    const [updatedData, setUpdatedData] = useState<any[]>([]);
    const [allData, setAllData] = useState<any[]>([]);
    let savedData: any[] = [];
    let repeatData: any[] = [];
    let updatedAllData: any[] = [];

    const history = useHistory()

    const onChangeHour = (value: any) => {
        const first = allData.find((obj) => {
            return obj.company_id === value.company_id && obj.location_id === value.location_id
                && obj.line_id === value.line_id && obj.shift_id === value.shift_id
                && obj.crew_id === value.crew_id && obj.job_date === value.job_date
        })
        if (first) {
            first.hours = value.hours
            repeatData.push(first)
        } else {
            savedData.push(value)
        }
        savedData = savedData.filter((obj) => obj.hours !== null)
        repeatData = repeatData.filter((obj) => obj.hours !== null)
        updatedAllData = [...allData, ...savedData];
        setAllData(updatedAllData);
        if(savedData.length !== 0) {
            setUpdatedData(savedData)
        } else {
            setUpdatedData(repeatData)
        }
    }

    const onSubmitHours = async () => {
        if (!date) {
            notification.info({message: `Please select the date ${isDaily?'':'range'} first`})
            return false
        }
        if (allData.length===0) {
            notification.warning({message: 'No data entered!'})
            return false
        }

        let response = await setDailyHours({tableName: 'daily_hours', data: allData})
        const {data = {}} = response;
        if (data?.statusCode === 200) {
            notification.success({message: 'Daily hours created/updated successfully.'})
            history.push({
                pathname: '/view/daily-hours-reports'
            })
            return true
        } else {
            notification.error({message: 'Daily hours creation failed. Please try again.'})
        }
    }

    const {RangePicker} = DatePicker

    const companyName = props.companies?.find(({id})=>id===props.companyID)?.name;
    const locationName = props.locations?.find(({id})=>id===props.locationID)?.name;

    const onDatesChange = (dates: any) => {
        const [job_start_date, job_end_date] = dates;
        const dateDiff = moment(job_end_date).diff(moment(job_start_date), 'days');
        if (dateDiff > 30) {
            notification.error({message: 'Please select your date range less than 30 days'});
        } else {
            setDate(dates)
        }
    };

    return <React.Fragment>
        <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
            <Row>
                <Col span={12}>
                    <PageHeader title={`Add Hours - ${isDaily ? 'Daily' : 'Range'}`}/>
                </Col>
            </Row>
        </div>
        <Space style={{margin: 10, marginLeft: '25vw', marginBottom: 5}}>
            <Text strong>Company</Text>
            <Select
                showSearch
                disabled
                value={companyName}
                style={{width: 400}}
                placeholder="Please select company..."
                optionFilterProp="children"
            >
                <Option key={props.companyID} value={props.companyID} children={companyName}/>
            </Select>
        </Space>
        <div/>
        <Space style={{margin: 10, marginLeft: '25vw', marginTop: 5}}>
            <Text strong style={{marginRight: 6}}>Location</Text>
            <Select
                showSearch
                disabled
                value={locationName}
                style={{width: 400}}
                placeholder="Please select location..."
                optionFilterProp="children"
            >
                <Option key={props.locationID} value={props.locationID} children={locationName}/>
            </Select>
        </Space>
        <Space style={{margin: 10, marginLeft: '25vw', marginBottom: 5}}>
            {isDaily?<DatePickerFormatted
                onChange={(date: any) => setDate(date)}
                disabledDate={current => DISABLED_DATE(current)}
            />:<DateRangePickerFormatted
                onChange={onDatesChange}
                disabledDate={current => DISABLED_DATE(current)}
            />}
            <Button type="primary" icon={<PlusCircleOutlined/>} onClick={onSubmitHours}>Submit</Button>
        </Space>
        <div/>
        <Space>
            <DailyHourDateRange
                date={date}
                parentCallBack={onChangeHour}
                allData={allData}
                updatedData={updatedData}
                lines={enumList.lines}
                shifts={enumList.shifts}
                crews={enumList.crews}
                isDaily={isDaily}
                companyId={props.companyID}
                locationId={props.locationID}
            />
        </Space>
    </React.Fragment>
}