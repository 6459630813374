import React, {Fragment, FunctionComponent} from "react";
import {Button, PageHeader} from "antd";
import {LeftOutlined} from '@ant-design/icons';
import {useHistory} from "react-router-dom";

interface CustomPageHeaderProps {
    title: string
    start_date?: string
    end_date?: string
    quarter_date?: string
    quarter_name?: string
    weeks?: string
    remove_back_button?: boolean
}

const CustomPageHeader: FunctionComponent<CustomPageHeaderProps> = ({title, start_date, end_date, quarter_date, quarter_name, weeks, remove_back_button= false}) => {
    const history = useHistory()

    return <Fragment>
        {remove_back_button || <Button
            style={{margin: '10px 0 0 15px'}}
            icon={<LeftOutlined />}
            size={"large"}
            onClick={() => history.push({
                pathname: '/view/review-dashboard',
                state: {start_date, end_date,quarter_date, quarter_name, weeks}
            })}
        />}
        <PageHeader title={title}/>
    </Fragment>
}

export default CustomPageHeader
