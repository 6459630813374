import {Filter} from "../../api";
import moment from "moment";

export const MapToFilter = (values: {[key:string]: any})=> {
    return Object.entries(values).reduce((acc, [name, value]) => {
        if (value) {
            switch(name){
                case 'timestamp':{
                    const [from, to] = value
                    return [...acc, {
                        name,
                        value: moment(from).utc().set({hour: 0, minute: 0, second: 0, millisecond: 0}),
                        comparator: '>='
                    }, {
                        name,
                        value: moment(to).utc().set({hour: 23, minute: 59, second: 59, millisecond: 999}),
                        comparator: '<='
                    }]
                }
                case 'start_date':{
                    return [...acc, {name: 'date', value: moment(value).set({hour:0,minute:0,second:0,millisecond:0}).utc(), comparator: '>='}]
                }
                case 'end_date':{
                    return [...acc, {name : 'date', value: moment(value).set({hour:23,minute:59,second:59,millisecond:999}).utc(), comparator: '<='}]
                }
                case 'job_start_date':{
                    return [...acc, {name: 'job_date', value: moment(value).format('YYYY-MM-DD'), comparator: '>='}]
                }
                case 'job_end_date':{
                    return [...acc, {name : 'job_date', value: moment(value).format('YYYY-MM-DD'), comparator: '<='}]
                }
                case 'started':{
                    return [...acc, {name: 'started', value: moment(value).set({hour:0,minute:0,second:0,millisecond:0}).utc(), comparator: '>='}]
                }
                case 'completed':{
                    return [...acc, {name : 'completed', value: moment(value).set({hour:23,minute:59,second:59,millisecond:999}).utc(), comparator: '<='}]
                }
                case 'name':{
                    return [...acc, {name : 'name', value: value, comparator: 'iContains'}]
                }
                case 'email':{
                    return [...acc, {name : 'email', value: value, comparator: 'iContains'}]
                }
                case 'first_name':{
                    return [...acc, {name : 'first_name', value: value, comparator: 'iContains'}]
                }
                case 'last_name':{
                    return [...acc, {name : 'last_name', value: value, comparator: 'iContains'}]
                }
                case 'username':{
                    return [...acc, {name : 'username', value: value, comparator: 'iContains'}]
                }
                case 'user_id':{
                    return [...acc, {name : 'user_id', value: value, comparator: 'iContains'}]
                }
                default:
                    return [...acc, {name, value}]
            }
        } else
            return acc;
    }, [] as Filter[])
}
