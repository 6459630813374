import React, {FunctionComponent} from "react";
import {Card, Descriptions, PageHeader, Statistic} from "antd";
import {certificationReports} from "../../utils/reportConstants";
import {processTitle} from "../../utils/report";

interface StatProps {
    name: string
    value: number
}

const Stat: FunctionComponent<StatProps> = ({name, value}) => {
    return <Card style={{backgroundColor: '#ececec'}}>
        <Card style={{width: 200, textAlign: "center"}}>
            <Statistic title={name} value={value} />
        </Card>
    </Card>
}

interface Breakdown {
    inspection_out_of_conformance: number
    preliminary_out_of_conformance: number
    critical_joints_out_of_conformance: number
    frequency: number
    truss_selection: number
    inspection_detail: number
}

interface ScoreProps {
    breakdown: Breakdown
    multiplier: number
    reducer_score: number
    total: number
    final_score: number
}

interface Data {
    data: ScoreProps
    column: number
}

const Score: React.FunctionComponent<Data> = ({data: {breakdown, multiplier, reducer_score, total, final_score}, column}) => {

    const {Item} = Descriptions
    return <Card style={{backgroundColor: '#f5f5f5'}}>
        <Card>
            <PageHeader title={certificationReports.CERTIFICATION_SCORING}>
                <Descriptions title={'Score Breakdown'} column={1} bordered>
                    {Object.entries(breakdown)?.map(([name, value]) => <Item label={processTitle(name)}>{value}</Item>)}
                </Descriptions>
                <Descriptions column={column} style={{margin: 20}}>
                    <Item><Stat name="Multiplier" value={multiplier} /></Item>
                    <Item><Stat name="Reducer Score" value={reducer_score} /></Item>
                    <Item><Stat name="Total" value={total} /></Item>
                    <Item><Stat name="Final Score" value={final_score} /></Item>
                </Descriptions>
            </PageHeader>
        </Card>
    </Card>
}

export default Score