import React, {Fragment} from "react";
import {configs, fetch} from "../api";
import {TableFilter} from "./filters/TableFilter";
import {MapToFilter} from "./filters/mapping";
import {getOrdering} from "./filters/ordering";
import {ConfigResponse, Page, Response, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {Col, FormInstance, PageHeader, Row, Typography} from "antd";
import moment from "moment";
import { ConfigsContext } from "./configs/ConfigsProvider";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {DateRangePickerFormatted} from "./lib/DatePickerFormatted";
import {handleScroll} from "../utils/util";

export const Header: React.FunctionComponent = () => {

    const fetchAuditLogs = async (values: {[key:string]: any}, page?: Page)=>{
        const filters = MapToFilter(values)
        return await fetch({
            tableName: 'audit_log',
            orderBy: ['started'],
            filter:[...filters],
            page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
        }) || {}
    }

    return <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
        <Row>
            <Col span={12}>
                <PageHeader title="Audit Trail"/>
            </Col>
        </Row>
        <TableFilter
            schema={'audit_filter'}
            fetch={fetchAuditLogs}
            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                if (element.name === 'audit_date_range') {
                    return <DateRangePickerFormatted/>
                } else {
                    return undefined
                }
            }}
        />
    </div>
}

export const AuditTrail : React.FunctionComponent = () => {

    return <Fragment>
        <TableV2
            header={<Header/>}
            schema={'audit_log'}
            fetchConfig={async (schema: string) => {
                const response = await configs(schema) || {}
                return response as ConfigResponse
            }}
            fetchData={async (request) => {
                handleScroll()
                const filters = MapToFilter(request.options?.values || {})
                const column = request.options.inbuilt?.sort.column || 'started'
                const direction = request.options.inbuilt?.sort.direction || 'asc'
                const orderBy = getOrdering(column, direction);
                const payload = {
                    tableName: 'audit_log',
                    orderBy: orderBy,
                    filter:[...filters],
                    page: {size: request?.page?.size || 100, from: ((request?.page?.current || 1) - 1) * (request?.page?.size || 100)}
                }
                const response = await fetch(payload)
                return response as Response
            }}
            customRenderer={{
                started: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateTimeFormatExtended)}</Typography.Text>}</ConfigsContext.Consumer>
                },
                completed: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateTimeFormatExtended)}</Typography.Text>}</ConfigsContext.Consumer>
                }
            }}
            scroll={{y: 'calc(100vh - 419px)', x: '100%'}}
        />
    </Fragment>
}

export default AuditTrail