import React, {Fragment, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {Badge, Button, Card, Checkbox, Empty, PageHeader, Space, Table, Typography} from "antd";
import {useReactToPrint} from "react-to-print";
import {DownloadOutlined} from "@ant-design/icons";
import {Bullet, Column, DualAxes, Line, Pie} from "@ant-design/plots";
import {MetaProps, OutOfConformance} from "./Reports";
import {
    ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES,
    AVERAGE_DISTANCE,
    AVERAGE_DISTANCE_FROM_DESIGNED_CENTER,
    AVERAGE_FEET,
    AVERAGE_INSPECTION,
    AVERAGE_PER_WEEK,
    AVERAGE_ROTATION,
    AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION,
    averageReports,
    AVG,
    BACK,
    certificationReports,
    COUNT,
    CRITICAL_JOINTS,
    CRITICAL_JOINTS_PER_INSPECTION,
    dateColumn,
    DISTANCE_IN_INCHES, fillColor,
    FLOOR_CRITICAL,
    FLOOR_TRUSSES,
    formattedCriticalJointsConfigs,
    formattedPreliminaryInspectionConfigs,
    formattedTotalInspectionConfigs,
    FOUR_OR_MORE_CRITICAL_JOINTS,
    FRONT,
    graphValues,
    HOURS,
    HOURS_PER_WEEK,
    INCHES,
    INSPECTION_COUNT,
    INSPECTION_INFORMATION_COUNT,
    INSPECTIONS,
    JOINT_BREAKDOWN,
    JOINT_BREAKDOWN_AS_PERCENTAGE,
    JOINT_BREAKDOWN_COUNT,
    JOINT_COUNT,
    JOINT_OVERVIEW,
    JOINT_QC_DETAIL,
    JOINT_STRESS_INDEX,
    JOINT_STRESS_INDEX_AVERAGE,
    jointReports,
    JOINTS,
    JOINTS_IN_DETAIL,
    MAX, MEDIAN,
    MEMBER_COUNT,
    MEMBERS,
    MEMBERS_IN_DETAIL,
    MIN,
    NO_OF_INSPECTED,
    ONE_CRITICAL_JOINTS,
    OUT_OF_CONFORMANCE,
    OVERVIEW_AS_PERCENTAGE,
    PASS_PERCENTAGE,
    PERCENT_DEFECTS_OF_INSPECTIONS,
    PERCENT_DEFECTS_OF_JOINTS,
    PERCENT_OF_PLATES_UPSIZED,
    PERCENT_TRUSSES_OF_UPSIZED_PLATES,
    PERCENTAGE,
    PLATES_UPSIZED,
    PLATES_UPSIZED_OVERVIEW,
    PRELIMINARY,
    PRELIMINARY_BREAKDOWN,
    PRELIMINARY_IN_DETAIL,
    PRELIMINARY_INSPECTIONS,
    reportTitles,
    ROOF_CRITICAL,
    ROOF_TRUSSES,
    ROTATION_IN_DEGREES, SETUP_FREQUENCY,
    SQUARE_INCHES,
    summaryReportByCrew,
    summaryReports,
    TARGET,
    THREE_CRITICAL_JOINTS,
    TOTAL_CRITICAL_JOINTS,
    TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS,
    TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS,
    TOTAL_INSPECTIONS,
    TRUSS_COUNT,
    TRUSS_OVERVIEW,
    TRUSSES,
    TRUSSES_AS_PERCENTAGE,
    TRUSSES_IN_DETAIL,
    TRUSSES_PER_SHIFT_PER_WEEK,
    TWO_CRITICAL_JOINTS,
    ZERO_CRITICAL_JOINTS
} from "../../utils/reportConstants";
import {LineSetting, Shift} from "./QualityControlLog";
import {plantShift, plantLineSetting} from "./PlantReport";
import {
    inchToFeet,
    processData,
    processDefectFoundData,
    processFrequencySummaryData,
    processInspectionDetailData,
    processInspectionPassingData,
    processTitle,
    processTrussCountData,
    processTrussPercentData,
    processUpsizedPlatesData
} from "../../utils/report";
import Score from "./Score";
import CommentReport from "./CommentReport";
import {Breakdown, Overview, SummaryByCrew} from "./CrewSummaryReport";
import {LogReportTable, Sign} from "./LogReport";
import {LocationsFilterBaseProps} from "../filters/LocationsFilter";
import {AuthContext} from "../user/AuthProvider";
import {SetupFrequency} from "./AverageReport";

interface LocationProps {
    reportType: string
    reportData: any
    metaData: MetaProps
}

export interface ReportPDFProps extends Partial<LocationsFilterBaseProps>{

}

export const ReportPDF: React.FunctionComponent<ReportPDFProps> = (props) => {
    const [subReports, setSubReports] = useState<string[]>()

    const locationHook = useLocation()
    const {reportType, reportData, metaData} = locationHook.state as LocationProps

    const titles = Object.freeze<{[key: string]: string}>({
        inspectionInformation: averageReports.INSPECTION_INFORMATION,
        jointBreakdown: averageReports.JOINT_BREAKDOWN,
        dimensionBreakdown: averageReports.DIMENSION_BREAKDOWN,
        inspectionFrequency: averageReports.INSPECTION_FREQUENCY,
        inspectionFrequencyByCrew: averageReports.INSPECTION_FREQUENCY_BY_CREW,
        overallQCInspections: summaryReports.OVERALL_QC_INSPECTIONS,
        outOfConformanceVisual: summaryReports.OUT_OF_CONFORMANCE_PRELIMINARY,
        outOfConformanceDetailed: summaryReports.OUT_OF_CONFORMANCE_JOINTS,
        outOfConformanceMembers: summaryReports.OUT_OF_CONFORMANCE_MEMBERS,
        overallOutOfConformance: certificationReports.OVERALL_OUT_OF_CONFORMANCE,
        defectsFound: certificationReports.DEFECTS_FOUND,
        upsizedPlates: certificationReports.UPSIZED_PLATES,
        placementTolerance: certificationReports.PLACEMENT_TOLERANCE,
        frequencySummary: certificationReports.FREQUENCY_SUMMARY,
        trussSelection: certificationReports.TRUSS_SELECTION,
        inspectionDetail: certificationReports.INSPECTION_DETAIL,
        scoring: certificationReports.CERTIFICATION_SCORING,
        summary: jointReports.SUMMARY,
        breakdown: jointReports.BREAKDOWN,
        jointsByRange: jointReports.JOINTS_BY_RANGE,
        crewSummary: summaryReportByCrew.CREW_SUMMARY,
        platesUpsized: PLATES_UPSIZED,
        inspectionByRange: reportData?.inspectionByRange?.title,
        totalInspections: TOTAL_INSPECTIONS,
        preliminaryInspections: PRELIMINARY_INSPECTIONS,
        criticalJoints: CRITICAL_JOINTS,
        inspectionPassing: PASS_PERCENTAGE,
        setupFrequency: SETUP_FREQUENCY.SUB_REPORT_TITLE
    })

    const qualityLogConfig = Object.freeze({
        crew_name: 'Crew',
        job_name: 'Job Number',
        id: 'Truss ID',
        span_actual: 'Span',
        crew_hours: 'Hours',
        qc_inspector: 'QC Inspector',
        build_at: 'Date Build',
        date: 'Date Inspected'
    })

    const componentRef  = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: titles[reportType]
    });

    const load = () => {
        setSubReports(Object.entries(reportData).map(report => report[0]))
    }

    useEffect(() => {
        load()
    }, [])

    const jointBreakdownPercentage = (count: number) => {
        return parseFloat((count / reportData?.jointBreakdown?.total_truss_count * 100).toFixed(2))
    }

    const dimensionBreakdownPercentage = (count: number) => {
        const totalCount = reportData?.dimensionBreakdown?.roof?.total_count + reportData?.dimensionBreakdown?.floor?.total_count
        if(totalCount===0) return 0
        return parseFloat((count / totalCount * 100).toFixed(2))
    }

    const positionOfGraphValues = 'bottom'
    const certificationReportLabelColor = '#566573'

    const { Text, Title } = Typography
    return <Fragment>
        <PageHeader title={'Generate PDF'}/>
        <Space style={{margin: 10}}>
            <Checkbox.Group value={subReports} onChange={(list) => setSubReports(list as string[])}>
                {
                    reportType!=='quality_control' &&
                    reportType!=='comment' &&
                    reportType!=='daily_hours' &&
                    reportType!=='log_report' &&
                    Object.entries(reportData).map(report => <Checkbox value={report[0]}>{titles[report[0]]}</Checkbox>)
                }
            </Checkbox.Group>
            <Button
                disabled={subReports?.length===0}
                type={"primary"}
                icon={<DownloadOutlined />}
                onClick={handlePrint}
            >
                Download
            </Button>
        </Space>
        <div style={{border:'1px solid #ABB2B9', maxWidth: '45vw', marginLeft: '10vw', marginRight: '10vw', display: 'table'}}>
            <div ref={componentRef} style={{padding: '2vw'}}>
                <Title level={2} style={{textAlign: "center", color: '#4863A0'}}>{reportTitles[reportType]}</Title>
                <div style={{paddingRight: 20, textAlign: "right"}}>
                    <ul style={{listStyle: 'none'}}>
                        <li>
                            <Text strong style={{color: '#566573'}}>Company:</Text><Text style={{color: '#ABB2B9'}}> {props.companies?.find(({id})=>id===props.companyID)?.name}</Text>
                        </li>
                        <li>
                            <Text strong style={{color: '#566573'}}>Location:</Text><Text style={{color: '#ABB2B9'}}> {props.locations?.find(({id})=>id===props.locationID)?.name}</Text>
                        </li>
                        {Object.entries(reportType==='log_report'?{...metaData, start_date:reportData.startDate, end_date: reportData.endDate}:metaData)?.map(([key, value]) => <li>
                            <Text strong style={{color: '#566573'}}>{processTitle(key)}:</Text><Text style={{color: '#ABB2B9'}}> {value}</Text>
                        </li>)}
                        <AuthContext.Consumer>{auth=>
                            <li>
                                <Text strong style={{color: '#566573'}}>{processTitle('user')}:</Text><Text style={{color: '#ABB2B9'}}> {auth?.user?.username}</Text>
                            </li>
                        }</AuthContext.Consumer>
                    </ul>
                </div>

                {/*Average report*/}
                {subReports?.includes('inspectionInformation') && <Card
                    title={averageReports.INSPECTION_INFORMATION}
                    style={{pageBreakAfter: "always"}}
                >
                    <Card title={INSPECTION_INFORMATION_COUNT} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        {reportData?.inspectionInformation?.inspection_count?<Column
                            color={fillColor}
                            xField={'Inspection Information'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: INSPECTION_COUNT
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={[
                                {
                                    'Inspection Information': TOTAL_INSPECTIONS,
                                    'Count': reportData?.inspectionInformation?.inspection_count
                                },
                                {
                                    'Inspection Information': TOTAL_CRITICAL_JOINTS,
                                    'Count': reportData?.inspectionInformation?.critical_joint_count
                                },
                                {
                                    'Inspection Information': OUT_OF_CONFORMANCE,
                                    'Count': reportData?.inspectionInformation?.out_of_conformance_count
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={AVERAGE_PER_WEEK} type={"inner"} style={{minWidth: '22vw'}}>
                        {reportData?.inspectionInformation?.inspections_per_week?<Column
                            color={fillColor}
                            xField={'Inspection Information'}
                            yField={'Average Per Week'}
                            yAxis={{
                                title: {
                                    text: AVERAGE_INSPECTION
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={[
                                {
                                    'Inspection Information': INSPECTIONS,
                                    'Average Per Week': reportData?.inspectionInformation?.inspections_per_week
                                },
                                {
                                    'Inspection Information': CRITICAL_JOINTS,
                                    'Average Per Week': reportData?.inspectionInformation?.out_of_conformance_per_week
                                },
                                {
                                    'Inspection Information': JOINT_STRESS_INDEX,
                                    'Average Per Week': reportData?.inspectionInformation?.inspections_joint_jsi_average_per_week
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card type={"inner"} title={CRITICAL_JOINTS_PER_INSPECTION} style={{minWidth: '15vw'}}>
                        {reportData?.inspectionInformation?.critical_joints_per_inspection?<Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'target'}
                            xField={'title'}
                            style={{ height: '30vh'}}
                            data={[
                                {
                                    title: CRITICAL_JOINTS_PER_INSPECTION,
                                    ranges: [reportData?.inspectionInformation?.critical_joints_per_inspection * 1.2],
                                    measures: [reportData?.inspectionInformation?.critical_joints_per_inspection],
                                    target: reportData?.inspectionInformation?.critical_joints_per_inspection,
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card type={"inner"} title={JOINT_STRESS_INDEX_AVERAGE} style={{minWidth: '15vw'}}>
                        {reportData?.inspectionInformation?.inspections_joint_jsi_average?<Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'target'}
                            xField={'title'}
                            style={{ height: '30vh'}}
                            data={[
                                {
                                    title: JOINT_STRESS_INDEX_AVERAGE,
                                    ranges: [reportData?.inspectionInformation?.inspections_joint_jsi_average * 1],
                                    measures: [reportData?.inspectionInformation?.inspections_joint_jsi_average],
                                    target: reportData?.inspectionInformation?.inspections_joint_jsi_average,
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Card>}

                {/*Joint Breakdown*/}
                {subReports?.includes('jointBreakdown') && <Card
                    title={averageReports.JOINT_BREAKDOWN}
                    style={{pageBreakAfter: "always"}}
                >
                    <Card title={JOINT_BREAKDOWN_COUNT} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        {reportData?.jointBreakdown?.total_truss_count?<Column
                            color={fillColor}
                            xField={'Joint Breakdown'}
                            yField={'Percentage'}
                            yAxis={{
                                title: {
                                    text: INSPECTION_COUNT
                                }
                            }}
                            xAxis={{
                                title: {
                                    text: CRITICAL_JOINTS
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={[
                                {
                                    'Joint Breakdown': ZERO_CRITICAL_JOINTS,
                                    'Percentage': reportData?.jointBreakdown['0']
                                },
                                {
                                    'Joint Breakdown': ONE_CRITICAL_JOINTS,
                                    'Percentage': reportData?.jointBreakdown['1']
                                },
                                {
                                    'Joint Breakdown': TWO_CRITICAL_JOINTS,
                                    'Percentage': reportData?.jointBreakdown['2']
                                },
                                {
                                    'Joint Breakdown': THREE_CRITICAL_JOINTS,
                                    'Percentage': reportData?.jointBreakdown['3']
                                },
                                {
                                    'Joint Breakdown': FOUR_OR_MORE_CRITICAL_JOINTS,
                                    'Percentage': reportData?.jointBreakdown['>=4']
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={JOINT_BREAKDOWN_AS_PERCENTAGE} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        {reportData?.jointBreakdown?.total_truss_count?<Pie
                            angleField={'Percentage'}
                            colorField={'Joint Breakdown'}
                            radius={0.8}
                            label={{
                                type: 'inner',
                                offset: '-30%',
                                content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
                                style: {
                                    fontSize: 14,
                                    textAlign: 'center',
                                },
                            }}
                            data={[
                                {
                                    'Joint Breakdown': `${ZERO_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(reportData?.jointBreakdown['0'])
                                },
                                {
                                    'Joint Breakdown': `${ONE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(reportData?.jointBreakdown['1'])
                                },
                                {
                                    'Joint Breakdown': `${TWO_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(reportData?.jointBreakdown['2'])
                                },
                                {
                                    'Joint Breakdown': `${THREE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(reportData?.jointBreakdown['3'])
                                },
                                {
                                    'Joint Breakdown': `${FOUR_OR_MORE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`,
                                    'Percentage': jointBreakdownPercentage(reportData?.jointBreakdown['>=4'])
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Card>}

                {/*Dimension Breakdown*/}
                {subReports?.includes('dimensionBreakdown') && <Card
                    title={averageReports.DIMENSION_BREAKDOWN}
                    style={{pageBreakAfter: "always"}}
                    className="report-pdf-card-body-style"
                >
                    <Card
                        title={ROOF_TRUSSES}
                        type={"inner"}
                        style={{pageBreakInside: "avoid", padding: '0px'}}
                        className="report-pdf-inside-card-body-style"
                        extra={<Text type={"secondary"}>count: {reportData?.dimensionBreakdown?.roof?.total_count}  ({dimensionBreakdownPercentage(reportData?.dimensionBreakdown?.roof?.total_count)}%)</Text>}
                    >
                        {reportData?.dimensionBreakdown?.roof?.total_count?<Space>
                            <Card title={'Roof Span'} type={"inner"} style={{minWidth: '27vw'}}>
                                <Column
                                    color={fillColor}
                                    xField={'Roof Span'}
                                    yField={'Inches'}
                                    yAxis={{
                                        title: {
                                            text: INCHES
                                        }
                                    }}
                                    label={{
                                        content: ({Inches}) => {
                                            return inchToFeet(Inches)
                                        },
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={[
                                        {
                                            'Roof Span': MAX,
                                            'Inches': reportData?.dimensionBreakdown?.roof?.span?.max
                                        },
                                        {
                                            'Roof Span': AVG,
                                            'Inches':  reportData?.dimensionBreakdown?.roof?.span?.avg
                                        },
                                        {
                                            'Roof Span': MEDIAN,
                                            'Inches':  reportData?.dimensionBreakdown?.roof?.span?.median
                                        },
                                        {
                                            'Roof Span': MIN,
                                            'Inches':  reportData?.dimensionBreakdown?.roof?.span?.min
                                        }
                                    ]}
                                />
                            </Card>
                            <Card title={'Roof Height'} type={"inner"} style={{minWidth: '27vw'}}>
                                <Column
                                    color={fillColor}
                                    xField={'Roof Height'}
                                    yField={'Inches'}
                                    yAxis={{
                                        title: {
                                            text: INCHES
                                        }
                                    }}
                                    label={{
                                        content: ({Inches}) => {
                                            return inchToFeet(Inches)
                                        },
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={[
                                        {
                                            'Roof Height': MAX,
                                            'Inches': reportData?.dimensionBreakdown?.roof?.height?.max
                                        },
                                        {
                                            'Roof Height': AVG,
                                            'Inches': reportData?.dimensionBreakdown?.roof?.height?.avg
                                        },
                                        {
                                            'Roof Height': MEDIAN,
                                            'Inches': reportData?.dimensionBreakdown?.roof?.height?.median
                                        },
                                        {
                                            'Roof Height': MIN,
                                            'Inches': reportData?.dimensionBreakdown?.roof?.height?.min
                                        }
                                    ]}
                                />
                            </Card>
                        </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card
                        title={FLOOR_TRUSSES}
                        type={"inner"}
                        style={{pageBreakInside: "avoid"}}
                        extra={<Text type={"secondary"}>count: {reportData?.dimensionBreakdown?.floor?.total_count}  ({dimensionBreakdownPercentage(reportData?.dimensionBreakdown?.floor?.total_count)}%)</Text>}
                    >
                        {reportData?.dimensionBreakdown?.floor?.total_count?<Space>
                            <Card title={'Floor Span'} type={"inner"} style={{minWidth: '25vw'}}>
                                <Column
                                    color={fillColor}
                                    xField={'Floor Span'}
                                    yField={'Inches'}
                                    yAxis={{
                                        title: {
                                            text: INCHES
                                        }
                                    }}
                                    label={{
                                        content: ({Inches}) => {
                                            return inchToFeet(Inches)
                                        },
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={[
                                        {
                                            'Floor Span': MAX,
                                            'Inches': reportData?.dimensionBreakdown?.floor?.span?.max
                                        },
                                        {
                                            'Floor Span': AVG,
                                            'Inches': reportData?.dimensionBreakdown?.floor?.span?.avg
                                        },
                                        {
                                            'Floor Span': MEDIAN,
                                            'Inches': reportData?.dimensionBreakdown?.floor?.span?.median
                                        },
                                        {
                                            'Floor Span': MIN,
                                            'Inches': reportData?.dimensionBreakdown?.floor?.span?.min
                                        }
                                    ]}
                                />
                            </Card>
                            <Card title={'Floor Height'} type={"inner"} style={{minWidth: '25vw'}}>
                                <Column
                                    color={fillColor}
                                    xField={'Floor Height'}
                                    yField={'Inches'}
                                    yAxis={{
                                        title: {
                                            text: INCHES
                                        }
                                    }}
                                    label={{
                                        content: ({Inches}) => {
                                            return inchToFeet(Inches)
                                        },
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={[
                                        {
                                            'Floor Height': MAX,
                                            'Inches': reportData?.dimensionBreakdown?.floor?.height?.max
                                        },
                                        {
                                            'Floor Height': AVG,
                                            'Inches': reportData?.dimensionBreakdown?.floor?.height?.avg
                                        },
                                        {
                                            'Floor Height': MEDIAN,
                                            'Inches': reportData?.dimensionBreakdown?.floor?.height?.median
                                        },
                                        {
                                            'Floor Height': MIN,
                                            'Inches': reportData?.dimensionBreakdown?.floor?.height?.min
                                        }
                                    ]}
                                />
                            </Card>
                        </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={TRUSS_OVERVIEW} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        {(reportData?.dimensionBreakdown?.roof?.total_count || reportData?.dimensionBreakdown?.floor?.total_count)?<Pie
                            angleField={'Percentage'}
                            colorField={'Dimension Breakdown'}
                            label={{
                                type: 'inner',
                                offset: '-30%',
                                content: ({ Percentage }) => `${Percentage} Trusses`,
                                style: {
                                    fontSize: 14,
                                    textAlign: 'center',
                                },
                            }}
                            radius={0.8}
                            data={[
                                {
                                    'Dimension Breakdown': ROOF_TRUSSES,
                                    'Percentage': reportData?.dimensionBreakdown?.roof?.total_count
                                },
                                {
                                    'Dimension Breakdown': FLOOR_TRUSSES,
                                    'Percentage': reportData?.dimensionBreakdown?.floor?.total_count
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Card>}

                {/*Inspection Frequency*/}
                {subReports?.includes('inspectionFrequency') && <Card
                    title={averageReports.INSPECTION_FREQUENCY}
                    style={{pageBreakAfter: "always"}}
                >
                    {reportData?.inspectionFrequency.length>0?<Fragment>
                        {reportData?.inspectionFrequency?.map((shift: any) =>
                            <Card title={shift?.shift_name} type={"inner"} style={{pageBreakInside: "avoid"}}>
                                <Column
                                    color={fillColor}
                                    xField={'Inspections'}
                                    yField={'No of Inspections'}
                                    yAxis={{
                                        title: {
                                            text: INSPECTION_COUNT
                                        }
                                    }}
                                    label={{
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={shift?.lines?.map((line: any) => ({
                                        'Inspections': line?.line_name,
                                        'No of Inspections': line?.inspections
                                    }))}
                                />
                            </Card>
                        )}
                    </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*Inspection Frequency By Crew*/}
                {subReports?.includes('inspectionFrequencyByCrew') && <Card
                    title={averageReports.INSPECTION_FREQUENCY_BY_CREW}
                    style={{pageBreakAfter: "always", pageBreakInside: "avoid"}}
                >
                    {reportData?.inspectionFrequencyByCrew.length?<Column
                        color={fillColor}
                        xField={'Inspections'}
                        yField={'No of Inspections'}
                        yAxis={{
                            title: {
                                text: INSPECTION_COUNT
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={reportData?.inspectionFrequencyByCrew?.map((inspectionFrequencyForParticularCrew: any) => ({
                            'Inspections': inspectionFrequencyForParticularCrew?.crew_name,
                            'No of Inspections': inspectionFrequencyForParticularCrew?.inspections
                        }))}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*setup Frequency*/}
                {subReports?.includes('setupFrequency') && <Card
                    title={SETUP_FREQUENCY.SUB_REPORT_TITLE}
                    style={{pageBreakAfter: "always"}}
                >
                    <SetupFrequency setupFrequency={reportData?.setupFrequency} isPDFView={true}/>
                </Card>}

                {/*"Overall QC Inspections"*/}
                {subReports?.includes('overallQCInspections') && <Card
                    title={summaryReports.OVERALL_QC_INSPECTIONS}
                    style={{pageBreakAfter: "always"}}
                >
                    {reportData?.overallQCInspections?.total_number_inspected?.trusses?<Fragment>
                        <Card title={'Overall Inspection Overview'} type={"inner"}>
                            <Column
                                color={fillColor}
                                isGroup={true}
                                seriesField={'name'}
                                xField={'Trusses or Joints'}
                                yField={'Count'}
                                yAxis={{
                                    title: {
                                        text: COUNT
                                    }
                                }}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        name: NO_OF_INSPECTED,
                                        'Trusses or Joints': TRUSSES,
                                        'Count': reportData?.overallQCInspections?.total_number_inspected?.trusses
                                    },
                                    {
                                        name: NO_OF_INSPECTED,
                                        'Trusses or Joints': PRELIMINARY,
                                        'Count': reportData?.overallQCInspections?.total_number_inspected?.preliminary
                                    },
                                    {
                                        name: NO_OF_INSPECTED,
                                        'Trusses or Joints': JOINTS,
                                        'Count': reportData?.overallQCInspections?.total_number_inspected?.joints
                                    },
                                    {
                                        name: NO_OF_INSPECTED,
                                        'Trusses or Joints': MEMBERS,
                                        'Count': reportData?.overallQCInspections?.total_number_inspected?.members
                                    },
                                    {
                                        name: OUT_OF_CONFORMANCE,
                                        'Trusses or Joints': TRUSSES,
                                        'Count': reportData?.overallQCInspections?.numberOutOfConformance?.trusses
                                    },
                                    {
                                        name: OUT_OF_CONFORMANCE,
                                        'Trusses or Joints': PRELIMINARY,
                                        'Count': reportData?.overallQCInspections?.numberOutOfConformance?.preliminary
                                    },
                                    {
                                        name: OUT_OF_CONFORMANCE,
                                        'Trusses or Joints': JOINTS,
                                        'Count': reportData?.overallQCInspections?.numberOutOfConformance?.joints
                                    },
                                    {
                                        name: OUT_OF_CONFORMANCE,
                                        'Trusses or Joints': MEMBERS,
                                        'Count': reportData?.overallQCInspections?.numberOutOfConformance?.members
                                    }
                                ]}
                            />
                        </Card>
                        <Card
                            title={OVERVIEW_AS_PERCENTAGE}
                            type={"inner"}
                        >
                            <Space>
                                {[
                                    {
                                        name: TRUSSES,
                                        value: reportData?.overallQCInspections?.percentageOfOutOfConformance?.trusses
                                    },
                                    {
                                        name: PRELIMINARY,
                                        value: reportData?.overallQCInspections?.percentageOfOutOfConformance?.preliminary
                                    },
                                    {
                                        name: JOINTS,
                                        value: reportData?.overallQCInspections?.percentageOfOutOfConformance?.joints
                                    },
                                    {
                                        name: MEMBERS,
                                        value: reportData?.overallQCInspections?.percentageOfOutOfConformance?.members
                                    }
                                ].map(({name, value}) => <Pie
                                    style={{height: 200, width: 175}}
                                    angleField={'Percentage'}
                                    colorField={'Out of Conformance'}
                                    radius={0.8}
                                    label={{
                                        type: 'inner',
                                        offset: '-30%',
                                        content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
                                        style: {
                                            fontSize: 12,
                                            textAlign: 'center',
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    legend={{
                                        layout: 'vertical',
                                        position: 'bottom'
                                    }}
                                    data={[
                                        {
                                            'Out of Conformance': `Passed ${name}`,
                                            'Percentage': 100 - value
                                        },
                                        {
                                            'Out of Conformance': `Failed ${name}`,
                                            'Percentage': value
                                        }
                                    ]}
                                />)}
                            </Space>
                        </Card>
                    </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*"Out Of Conformance Visual"*/}
                {subReports?.includes('outOfConformanceVisual') && <Card
                    title={summaryReports.OUT_OF_CONFORMANCE_PRELIMINARY}
                >
                    <Card title={'Out Of Conformance Preliminary Graph'} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        <Column
                            color={fillColor}
                            xField={'Type'}
                            yField={'No of Conformance'}
                            yAxis={{
                                title: {
                                    text: INSPECTION_COUNT
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={Object.entries(reportData?.outOfConformanceVisual)?.map(visuals => {
                                const outOfConformanceVisuals : OutOfConformance = visuals[1] as OutOfConformance
                                return {
                                    'Type': visuals[0].substring(0, 3),
                                    'No of Conformance': outOfConformanceVisuals.count
                                }
                            })}
                        />
                    </Card>
                    <Card title={'Terminology'} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        <ul>
                            {Object.entries(reportData?.outOfConformanceVisual)?.map(visuals => <li>
                                <Text strong>{visuals[0].substring(0, 3)}</Text> - <Text>{processTitle(visuals[0])}</Text>
                            </li>)}
                        </ul>
                    </Card>
                </Card>}

                {/*"Out Of Conformance Detailed"*/}
                {subReports?.includes('outOfConformanceDetailed') && <Card
                    title={summaryReports.OUT_OF_CONFORMANCE_JOINTS}
                    style={{pageBreakInside: "avoid"}}
                >
                    <Column
                        color={fillColor}
                        xField={'Type'}
                        yField={'No of Conformance'}
                        yAxis={{
                            title: {
                                text: JOINT_COUNT
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={Object.entries(reportData?.outOfConformanceDetailed)?.map(detailedJoints => {
                            const outOfConformanceDetailedJoints : OutOfConformance = detailedJoints[1] as OutOfConformance
                            return {
                                'Type': processTitle(detailedJoints[0]),
                                'No of Conformance': outOfConformanceDetailedJoints.count
                            }
                        })}
                    />
                </Card>}

                {/*"Out Of Conformance Members"*/}
                {subReports?.includes('outOfConformanceMembers') && <Card
                    title={summaryReports.OUT_OF_CONFORMANCE_MEMBERS}
                    style={{pageBreakInside: "avoid"}}
                >
                    <Column
                        color={fillColor}
                        xField={'Type'}
                        yField={'No of Conformance'}
                        yAxis={{
                            title: {
                                text: MEMBER_COUNT
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={Object.entries(reportData?.outOfConformanceMembers)?.map(detailedMembers => {
                            const outOfConformanceDetailedMembers : OutOfConformance = detailedMembers[1] as OutOfConformance
                            return {
                                'Type': processTitle(detailedMembers[0]),
                                'No of Conformance': outOfConformanceDetailedMembers.count
                            }
                        })}
                    />
                </Card>}

                {/*"Plates Upsized From Specified"*/}
                {subReports?.includes('platesUpsized') && <Card
                    title={summaryReports.PLATES_UPSIZED_FROM_SPECIFIED}
                    style={{pageBreakInside: "avoid"}}
                >
                    {reportData?.platesUpsized?.totalPlates?<Bullet
                        measureField={'measures'}
                        rangeField={'ranges'}
                        targetField={'Total plates'}
                        xField={'title'}
                        data={[
                            {
                                title: PLATES_UPSIZED,
                                ranges: [reportData?.platesUpsized?.totalPlates],
                                measures: [reportData?.platesUpsized?.upsizedPlates],
                                'Total plates': reportData?.platesUpsized?.totalPlates,
                            }
                        ]}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*"Inspection By Range"*/}
                {subReports?.includes('inspectionByRange') && <Card
                    title={reportData?.inspectionByRange?.title}
                    style={{pageBreakInside: "avoid"}}
                >
                    <DualAxes
                        xField={'range'}
                        yField={['inspections', 'passPercentage']}
                        data={[reportData?.inspectionByRange?.data, reportData?.inspectionByRange?.data]}
                        geometryOptions={[
                            {
                                geometry: 'column'
                            },
                            {
                                geometry: 'line',
                                lineStyle: {
                                    lineWidth: 2
                                },
                            },
                        ]}
                    />
                </Card>}

                {/*"Quality Control Log"*/}
                {reportType==='quality_control' && reportData?.map((shift: Shift) => <Card
                    title={shift.shift_name}
                    extra={<Space><Text type={"secondary"}>Hours: </Text><Text>{shift.shift_hours?shift.shift_hours:'0.00'}h</Text><Text type={"secondary"}> Status: </Text><Text>{shift.active_shift?'Active':'Inactive'}</Text><Badge status={shift.active_shift?'success':'error'} /></Space>}
                >
                    {shift.lines?.map((line: LineSetting) => <Card
                        title={line.line_name}
                        type={"inner"}
                        extra={<Space><Text type={"secondary"}> Inspection count: </Text><Text>{line.inspection_count}</Text><Text type={"secondary"}> Hours: </Text><Text>{line.line_hours?line.line_hours:'0.00'}h</Text><Text type={"secondary"}> Status: </Text><Text>{line.active_line?'Active':'Inactive'}</Text><Badge status={line.active_line?'success':'error'} /></Space>}
                    >
                        <Table
                            columns={Object.entries(qualityLogConfig).map(([key, value]) => ({
                                key,
                                dataIndex: key,
                                title: value
                            }))}
                            dataSource={line.inspections?.map((inspection: any) => ({
                                ...inspection,
                                key: inspection.id
                            }))}
                            pagination={false}
                        />
                    </Card>)}
                </Card>)}

                {/*"plant"*/}
                {reportType==='plant' && reportData.shiftData?.map((shift: plantShift) => <Card
                    title={shift.shift_name}
                    extra={<Space><Text type={"secondary"}>Hours: </Text><Text>{shift.shift_hours?shift.shift_hours:'0.00'}h</Text><Text type={"secondary"}> Status: </Text><Text>{shift.active_shift?'Active':'Inactive'}</Text><Badge status={shift.active_shift?'success':'error'} /></Space>}
                >
                    {shift.lines?.map((line: plantLineSetting) => <Card
                        title={line.line_name}
                        type={"inner"}
                        extra={<Space style={{ border: line.statusValue? '1px solid green' : '1px solid red', backgroundColor: line.statusValue? '#C1E1C1': '#FAA0A0', padding: '3px' }}>
                            <Text type={"secondary"}> Total Inspection count: </Text><Text>{line.inspection_count}</Text><Text type={"secondary"}> Actual Inspections: </Text><Text>{line.actual_value}</Text>
                            <Text type={"secondary"}>{line.isAmount ? "Expected Inspection Amount:" : "Expected Inspection Percentage:"}</Text>
                            <Text>{line.isAmount ? `${line.expected_value}A` : `${line.expected_value}%`}</Text>
                            <Text type={"secondary"}> Status: </Text><Text>{line.statusValue?'Passed':'Failed'}</Text><Badge status={line.statusValue?'success':'error'} /></Space>}
                    >
                        <Table
                            columns={Object.entries(qualityLogConfig).map(([key, value]) => ({
                                key,
                                dataIndex: key,
                                title: value
                            }))}
                            dataSource={line.inspections?.map((inspection: any) => ({
                                ...inspection,
                                key: inspection.id
                            }))}
                            pagination={false}
                        />
                    </Card>)}
                </Card>)}


                {/*"Total Inspections"*/}
                {subReports?.includes('totalInspections') && <div style={{marginBottom: 25}}>
                    <Title level={5} style={{textAlign: "center", margin: 10}}>Total Inspections</Title>
                    <Table
                        columns={[dateColumn, ...formattedTotalInspectionConfigs]}
                        dataSource={reportData?.totalInspections}
                        bordered
                        pagination={false}
                    />
                </div>}

                {/*"Preliminary Inspections"*/}
                {subReports?.includes('preliminaryInspections') && <div style={{marginBottom: 25}}>
                    <Title level={5} style={{textAlign: "center", margin: 10}}>Preliminary Inspections</Title>
                    <Table
                        size={"small"}
                        columns={[dateColumn, ...formattedPreliminaryInspectionConfigs]}
                        dataSource={reportData?.preliminaryInspections}
                        bordered
                        pagination={false}
                    />
                </div>}

                {/*"Critical Joints"*/}
                {subReports?.includes('criticalJoints') && <div style={{marginBottom: 25}}>
                    <Title level={5} style={{textAlign: "center", margin: 10}}>Critical Joints</Title>
                    <Table
                        size={"small"}
                        columns={[dateColumn, ...formattedCriticalJointsConfigs]}
                        dataSource={reportData?.criticalJoints}
                        bordered
                        pagination={false}
                    />
                </div>}

                {/*"Pass Percentage"*/}
                {subReports?.includes('inspectionPassing') && <Card title={PASS_PERCENTAGE}>
                    {reportData?.inspectionPassing?<Column
                        color={fillColor}
                        style={{margin: 10}}
                        isGroup={true}
                        seriesField={'name'}
                        xField={'range'}
                        yField={'percent'}
                        yAxis={{
                            title: {
                                text: 'Percentage %'
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={processInspectionPassingData(reportData?.inspectionPassing)}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}


                {/*Overall Out Of Conformance*/}
                {subReports?.includes('overallOutOfConformance') && <Card title={certificationReports.OVERALL_OUT_OF_CONFORMANCE} style={{marginBottom: 400}}>
                    <Line
                        xField={'Quarter'}
                        yField={'Average'}
                        seriesField={'category'}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: certificationReportLabelColor,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        yAxis={{
                            title: {
                                text: PERCENTAGE
                            }
                        }}
                        tooltip={{
                            formatter: ({category, Average}) => ({ name: category, value: Average + '%' })
                        }}
                        data={processData(reportData?.overallOutOfConformance)}
                    />
                </Card>}

                {/*Defects Found*/}
                {subReports?.includes('defectsFound') && <Card title={certificationReports.DEFECTS_FOUND} style={{marginBottom: 100}}>
                    <Card title={TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS} type={"inner"}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: COUNT
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={processDefectFoundData(reportData?.defectsFound, "defect")}
                        />
                    </Card>
                    <Card title={TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS} type={"inner"} style={{marginBottom: 40}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: COUNT
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={processDefectFoundData(reportData?.defectsFound, "defect_out")}
                        />
                    </Card>
                    <Card title={PERCENT_DEFECTS_OF_JOINTS} type={"inner"} style={{marginBottom: 40}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: PERCENTAGE
                                }
                            }}
                            tooltip={{
                                formatter: ({type, count}) => ({ name: type, value: count + '%' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={processDefectFoundData(reportData?.defectsFound, "joint")}
                        />
                    </Card>
                    <Card title={PERCENT_DEFECTS_OF_INSPECTIONS} type={"inner"}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: PERCENTAGE
                                }
                            }}
                            tooltip={{
                                formatter: ({type, count}) => ({ name: type, value: count + '%' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={processDefectFoundData(reportData?.defectsFound, "inspection")}
                        />
                    </Card>
                </Card>}

                {/*Upsized Plates*/}
                {subReports?.includes('upsizedPlates') && <Card title={certificationReports.UPSIZED_PLATES} style={{marginBottom: 600}}>
                    <Card title={PERCENT_OF_PLATES_UPSIZED} type={"inner"} style={{marginBottom: 15}}>
                        <Column
                            color={fillColor}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: PERCENTAGE
                                }
                            }}
                            tooltip={{
                                formatter: ({count}) => ({ name: 'Upsized plates', value: count + '%' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={reportData?.upsizedPlates?.map((quarter: any) => ({
                                name: quarter.range,
                                count: quarter.upsized_trusses
                            }))}
                        />
                    </Card>
                    <Card title={ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES}>
                        <Column
                            color={fillColor}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: SQUARE_INCHES
                                }
                            }}
                            tooltip={{
                                formatter: ({count}) => ({ name: 'Additional square inches', value: count + ' sq in' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={reportData?.upsizedPlates?.map((quarter: any) => ({
                                name: quarter.range,
                                count: quarter.additional_square
                            }))}
                        />
                    </Card>
                    <Card title={PERCENT_DEFECTS_OF_JOINTS} type={"inner"} style={{marginBottom: 25}}>
                        <Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'target'}
                            xField={'title'}
                            tooltip={{
                                formatter: ({measures}) => ({ name: 'Actual/specified ratio', value: measures + '%' })
                            }}
                            data={reportData?.upsizedPlates?.map((quarter: any) => ({
                                title: quarter.range,
                                ranges: [1, quarter.area_ratio * 1.2],
                                measures: [quarter.area_ratio],
                                target: 1
                            }))}
                        />
                    </Card>
                    <Card title={PERCENT_TRUSSES_OF_UPSIZED_PLATES} type={"inner"}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: PERCENTAGE
                                }
                            }}
                            tooltip={{
                                formatter: ({type, count}) => ({ name: type, value: count + '%' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={processUpsizedPlatesData(reportData?.upsizedPlates)}
                        />
                    </Card>
                </Card>}

                {/*Placement Tolerance*/}
                {subReports?.includes('placementTolerance') && <Card title={certificationReports.PLACEMENT_TOLERANCE}>
                    <Card title={AVERAGE_DISTANCE} type={"inner"} style={{marginBottom: 15}}>
                        <Card title={FRONT} type={"inner"} style={{marginBottom: 600}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={processData(reportData?.placementTolerance?.map((quarter: any) => quarter.distance.front))}
                            />
                        </Card>
                        <Card title={BACK} type={"inner"}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={processData(reportData?.placementTolerance?.map((quarter: any) => quarter.distance.back))}
                            />
                        </Card>
                    </Card>
                    <Card title={AVERAGE_ROTATION} type={"inner"}>
                        <Card title={FRONT} type={"inner"} style={{marginBottom: 25}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={processData(reportData?.placementTolerance?.map((quarter: any) => quarter.rotation.front))}
                            />
                        </Card>
                        <Card title={BACK} type={"inner"}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={processData(reportData?.placementTolerance?.map((quarter: any) => quarter.rotation.back))}
                            />
                        </Card>
                    </Card>
                </Card>}

                {/*Frequency Summary*/}
                {subReports?.includes('frequencySummary') && <Card title={certificationReports.FREQUENCY_SUMMARY}>
                    <Card title={TRUSSES_PER_SHIFT_PER_WEEK} type={"inner"}>
                        <Line
                            xField={'Quarter'}
                            yField={'Count'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: TRUSSES
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category===TARGET){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={processFrequencySummaryData(reportData?.frequencySummary, true)}
                        />
                    </Card>
                    <Card title={HOURS_PER_WEEK} type={"inner"}>
                        <Line
                            xField={'Quarter'}
                            yField={'Count'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: HOURS
                                }
                            }}
                            label={{
                                content: ({Count}) => `${Count}h`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category===TARGET){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={processFrequencySummaryData(reportData?.frequencySummary, false)}
                        />
                    </Card>
                </Card>}

                {/*Truss Selection*/}
                {subReports?.includes('trussSelection') && <Card title={`${certificationReports.TRUSS_SELECTION} (Average Span)`}>
                    <Card title={TRUSS_COUNT} type={"inner"} style={{marginBottom: 20}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: COUNT
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={processTrussCountData(reportData?.trussSelection)}
                        />
                    </Card>
                    <Card title={TRUSSES_AS_PERCENTAGE} type={"inner"}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Percent'}
                            yAxis={{
                                title: {
                                    text: PERCENTAGE
                                }
                            }}
                            label={{
                                content: ({Percent}) => `${Percent}%`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            tooltip={{
                                formatter: ({category, Percent}) => ({ name: category, value: `${Percent}%` })
                            }}
                            data={processTrussPercentData(reportData?.trussSelection)}
                        />
                    </Card>
                    <Card title={ROOF_TRUSSES} type={"inner"} style={{marginBottom: 120}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: AVERAGE_FEET
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: inchToFeet(Average * 12) })
                            }}
                            label={{
                                content: ({Average}) => {
                                    return inchToFeet(Average * 12)
                                },
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category==='goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={processData(reportData?.trussSelection?.map((quarter: any) => quarter.roof), true)}
                        />
                    </Card>
                    <Card title={FLOOR_TRUSSES} type={"inner"}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: AVERAGE_FEET
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: inchToFeet(Average * 12) })
                            }}
                            label={{
                                content: ({Average}) => {
                                    return inchToFeet(Average * 12)
                                },
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category==='goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={processData(reportData?.trussSelection?.map((quarter: any) => quarter.floor), true)}
                        />
                    </Card>
                    <Card title={ROOF_CRITICAL} type={"inner"} style={{marginBottom: 125}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category==='minimum_required_goal' || category==='certification_minimum_goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: Average })
                            }}
                            data={processData(reportData?.trussSelection?.map((quarter: any) => quarter.roof.critical), false)}
                        />
                    </Card>
                    <Card title={FLOOR_CRITICAL} type={"inner"}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category==='minimum_required_goal' || category==='certification_minimum_goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: Average })
                            }}
                            data={processData(reportData?.trussSelection?.map((quarter: any) => quarter.floor.critical), false)}
                        />
                    </Card>
                </Card>}

                {/*Inspection Detail*/}
                {subReports?.includes('inspectionDetail') && <Card title={certificationReports.INSPECTION_DETAIL} style={{marginBottom: 560}}>
                    <Card title={AVERAGE_DISTANCE_FROM_DESIGNED_CENTER} type={"inner"} style={{marginBottom: 25}}>
                        <Column
                            color={fillColor}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: DISTANCE_IN_INCHES
                                }
                            }}
                            label={{
                                content: ({Count}) => `${Count}in`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            tooltip={{
                                formatter: ({Count}) => ({ name: 'avg distance', value: `${Count}in` })
                            }}
                            data={reportData?.inspectionDetail?.map((detail: any) => ({
                                Quarter: detail?.detailed.range,
                                Count: detail?.detailed.avg_center_misplaced_distance,
                            }))}
                        />
                    </Card>
                    <Card title={AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION} type={"inner"}>
                        <Column
                            color={fillColor}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: ROTATION_IN_DEGREES
                                }
                            }}
                            label={{
                                content: ({Count}) => `${Count}°`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            tooltip={{
                                formatter: ({Count}) => ({ name: 'avg rotation', value: `${Count}°` })
                            }}
                            data={reportData?.inspectionDetail?.map((detail: any) => ({
                                Quarter: detail?.detailed.range,
                                Count: detail?.detailed.avg_rotation_from_specified,
                            }))}
                        />
                    </Card>
                    <Card title={OVERVIEW_AS_PERCENTAGE} style={{marginBottom: 125}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Percent'}
                            yAxis={{
                                title: {
                                    text: PERCENTAGE
                                }
                            }}
                            label={{
                                content: ({Percent}) => `${Percent}%`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            tooltip={{
                                formatter: ({category, Percent}) => ({ name: category, value: `${Percent}%` })
                            }}
                            data={processInspectionDetailData(reportData?.inspectionDetail)}
                        />
                    </Card>
                    <Card title={ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES}>
                        <Column
                            color={fillColor}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: SQUARE_INCHES
                                }
                            }}
                            tooltip={{
                                formatter: ({count}) => ({ name: 'Additional square inches', value: count + ' sq in' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={reportData?.inspectionDetail?.map((detail: any) => ({
                                name: detail?.detailed.range,
                                count: detail?.detailed.additional_square
                            }))}
                        />
                    </Card>
                </Card>}

                {/*Certification Score*/}
                {subReports?.includes('scoring') && <Score data={reportData?.scoring} column={2}/>}


                {/*"Comment Report"*/}
                {reportType==='comment' && <CommentReport data={reportData} column={2}/>}


                {/*Summary*/}
                {subReports?.includes('summary') && <Card title={jointReports.SUMMARY} style={{marginBottom: 100}}>
                    {reportData?.summary?.critical.total_joints?<Fragment>
                        <Card type={"inner"} title={JOINT_OVERVIEW} style={{marginBottom: 400}}>
                            <Column
                                color={fillColor}
                                xField={'name'}
                                yField={'count'}
                                yAxis={{
                                    title: {
                                        text: COUNT
                                    }
                                }}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        name: NO_OF_INSPECTED,
                                        count: reportData?.summary?.critical.total_joints
                                    },
                                    {
                                        name: OUT_OF_CONFORMANCE,
                                        count: reportData?.summary?.critical.failed_joints
                                    }
                                ]}
                            />
                        </Card>
                        <Card type={"inner"} title={OVERVIEW_AS_PERCENTAGE}>
                            <Pie
                                angleField={'count'}
                                colorField={'name'}
                                radius={0.8}
                                label={{
                                    type: 'inner',
                                    offset: '-30%',
                                    content: ({ count }) => `${count}%`,
                                    style: {
                                        fontSize: 14,
                                        textAlign: 'center',
                                    },
                                }}
                                tooltip={{
                                    formatter: ({name, count}) => ({ name, value: `${count}%` })
                                }}
                                data={[
                                    {
                                        'name': `Passed Joints`,
                                        'count': parseFloat((100 - reportData?.summary?.critical.percent).toFixed(2))
                                    },
                                    {
                                        'name': `Out of Conformance`,
                                        'count': reportData?.summary?.critical.percent
                                    }
                                ]}
                            />
                        </Card>
                        <Card type={"inner"} title={summaryReports.PLATES_UPSIZED_FROM_SPECIFIED}>
                            <Bullet
                                measureField={'measures'}
                                rangeField={'ranges'}
                                targetField={'Total plates'}
                                xField={'title'}
                                data={[
                                    {
                                        title: PLATES_UPSIZED,
                                        ranges: [reportData?.summary?.critical.total_joints],
                                        measures: [reportData?.summary?.upsized.count],
                                        'Total plates': reportData?.summary?.critical.total_joints,
                                    }
                                ]}
                            />
                        </Card>
                    </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*Breakdown*/}
                {subReports?.includes('breakdown') && <Card title={jointReports.BREAKDOWN}>
                    {reportData?.summary?.critical.total_joints?<Fragment>
                        <Card type={"inner"} title={OUT_OF_CONFORMANCE}>
                            <Column
                                color={fillColor}
                                xField={'name'}
                                yField={'count'}
                                yAxis={{
                                    title: {
                                        text: COUNT
                                    }
                                }}
                                label={{
                                    content: ({percent}) => `${percent || 0}%`,
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={Object.entries(reportData?.breakdown?.out_of_conformance)?.map((field: any) => ({
                                    name: field[1].abbreviation,
                                    count: field[1].count,
                                    percent: field[1].percent
                                }))}
                            />
                        </Card>
                        <Card title={'Terminology'} type={"inner"} style={{marginBottom: 250}}>
                            <ul>
                                {Object.entries(reportData?.breakdown?.out_of_conformance)?.map((field: any) => <li>
                                    <Text strong>{field[1].abbreviation}</Text> - <Text>{processTitle(field[0])}</Text>
                                </li>)}
                            </ul>
                        </Card>
                        <Card type={"inner"} title={JOINT_QC_DETAIL}>
                            <Column
                                color={fillColor}
                                xField={'name'}
                                yField={'count'}
                                yAxis={{
                                    title: {
                                        text: COUNT
                                    }
                                }}
                                label={{
                                    content: ({percent}) => `${percent || 0}%`,
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={Object.entries(reportData?.breakdown?.joint_detail)
                                    ?.filter((field: any) => field[0]!=='average_distance_16ths' && field[0]!=='average_orientation')
                                    ?.map((field: any) => ({
                                        name: field[1].abbreviation,
                                        count: field[1].count,
                                        percent: field[1].percent
                                    }))}
                            />
                        </Card>
                        <Card title={'Terminology'} type={"inner"} style={{marginBottom: 450}}>
                            <ul>
                                {Object.entries(reportData?.breakdown?.joint_detail)
                                    ?.filter((field: any) => field[0]!=='average_distance_16ths' && field[0]!=='average_orientation')
                                    ?.map((field: any) => <li>
                                        <Text strong>{field[1].abbreviation}</Text> - <Text>{processTitle(field[0])}</Text>
                                    </li>)}
                            </ul>
                        </Card>
                        <Card type={"inner"} title={AVERAGE_DISTANCE_FROM_DESIGNED_CENTER}>
                            <Bullet
                                measureField={'measures'}
                                rangeField={'ranges'}
                                targetField={'Total joints'}
                                xField={'title'}
                                data={[
                                    {
                                        title: AVERAGE_DISTANCE,
                                        ranges: [reportData?.breakdown?.joint_detail.average_distance_16ths * 4],
                                        measures: [reportData?.breakdown?.joint_detail.average_distance_16ths],
                                        'Total joints': reportData?.breakdown?.joint_detail.average_distance_16ths * 4,
                                    }
                                ]}
                                style={{maxHeight: 145}}
                            />
                        </Card>
                        <Card type={"inner"} title={AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION}>
                            <Bullet
                                measureField={'measures'}
                                rangeField={'ranges'}
                                targetField={'Total joints'}
                                xField={'title'}
                                data={[
                                    {
                                        title: AVERAGE_ROTATION,
                                        ranges: [reportData?.breakdown?.joint_detail.average_orientation * 4],
                                        measures: [reportData?.breakdown?.joint_detail.average_orientation],
                                        'Total joints': reportData?.breakdown?.joint_detail.average_orientation * 4,
                                    }
                                ]}
                                style={{maxHeight: 145}}
                            />
                        </Card>
                    </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*Joints by range*/}
                {subReports?.includes('jointsByRange') && <Card title={reportData?.jointsByRange.title}>
                    {reportData?.summary?.critical.total_joints?<DualAxes
                        xField={'range'}
                        yField={['joints', 'passPercentage']}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={[reportData?.jointsByRange.data, reportData?.jointsByRange.data]}
                        geometryOptions={[
                            {
                                geometry: 'column'
                            },
                            {
                                geometry: 'line',
                                lineStyle: {
                                    lineWidth: 2
                                },
                            },
                        ]}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}


                {/*Summary Report by Crew*/}
                {subReports?.includes('crewSummary') && reportData?.crewSummary?.map((crew: SummaryByCrew) => {
                    const {id, name, is_active, inspection, preliminary, joint, member, upsized} = crew

                    return <Card
                        key={id}
                        title={name}
                        style={{pageBreakBefore: "always"}}
                        extra={<Badge status={is_active?'success':'error'} />}
                    >
                        <Card title={TRUSSES_IN_DETAIL} style={{pageBreakAfter: "always"}}>
                            <Overview title={TRUSSES} data={inspection} isPDFMode={true}/>
                        </Card>
                        <Card title={PRELIMINARY_IN_DETAIL} style={{pageBreakAfter: "always"}}>
                            <Overview title={PRELIMINARY} data={preliminary} isPDFMode={true}/>
                            <Space>
                                <Card type={"inner"} title={PRELIMINARY_BREAKDOWN} style={{pageBreakInside: "avoid", width: 400}}>
                                    <Breakdown data={preliminary.breakdown} isPDFMode={true}/>
                                </Card>
                                <Card title={'Terminology'} type={"inner"} style={{pageBreakInside: "avoid", height: 481}}>
                                    <ul>
                                        {Object.entries(preliminary.breakdown)?.map(visuals => <li>
                                            <Text strong>{visuals[0].substring(0, 3)}</Text> - <Text>{processTitle(visuals[0])}</Text>
                                        </li>)}
                                    </ul>
                                </Card>
                            </Space>
                        </Card>
                        <Card title={JOINTS_IN_DETAIL} style={{pageBreakAfter: "always"}}>
                            <Overview title={JOINTS} data={joint} isPDFMode={true}/>
                            <Card type={"inner"} title={JOINT_BREAKDOWN} style={{pageBreakInside: "avoid"}}>
                                <Breakdown data={joint.breakdown}/>
                            </Card>
                        </Card>
                        <Card title={MEMBERS_IN_DETAIL}>
                            <Overview title={MEMBERS} data={member} isPDFMode={true}/>
                        </Card>
                        <Card title={PLATES_UPSIZED}>
                            <Card type={"inner"} title={PLATES_UPSIZED_OVERVIEW} style={{pageBreakInside: "avoid"}}>
                                <Bullet
                                    style={{height: 100}}
                                    measureField={'measures'}
                                    rangeField={'ranges'}
                                    targetField={'Total plates'}
                                    xField={'title'}
                                    data={[
                                        {
                                            title: PLATES_UPSIZED,
                                            ranges: [upsized.total],
                                            measures: [upsized.upsized],
                                            'Total plates': upsized.total
                                        }
                                    ]}
                                />
                            </Card>
                            <Card type={"inner"} title={OVERVIEW_AS_PERCENTAGE+' %'} style={{pageBreakInside: "avoid"}}>
                                <Bullet
                                    style={{height: 100}}
                                    measureField={'measures'}
                                    rangeField={'ranges'}
                                    targetField={'Total plates'}
                                    xField={'title'}
                                    data={[
                                        {
                                            title: PLATES_UPSIZED,
                                            ranges: [100],
                                            measures: [upsized.percent],
                                            'Total plates': 100
                                        }
                                    ]}
                                />
                            </Card>
                        </Card>
                    </Card>
                })}

                {/*Daily Hours Report*/}
                {reportType==='daily_hours' && <Table
                    pagination={false}
                    bordered={true}
                    dataSource={reportData}
                    columns={Object.entries(reportData[0]).map(config => ({key: config[0], dataIndex: config[0], title: processTitle(config[0])}))}
                />}

                {/*"Inspection Log Report"*/}
                {reportType==='log_report' && <div>{reportData?.timeLogs?.map((shift: any)=>{
                    return <Card
                        key={shift.shift_id}
                        title={'Shift - ' + shift.name}
                    >
                        {/*filter out Report Column*/}
                        <LogReportTable tableConfigs={reportData?.tableConfigs}
                                        subTableConfigs={reportData?.subTableConfigs?.filter(({name}: {name:any})=>name !== 'id')}
                                        data={shift.shift_data} isPDFView={true}/>
                    </Card>
                })}
                    <Sign isPDFView={true} locationID={props.locationID} metaData={metaData}/>
                </div>}
            </div>
        </div>
        <div style={{minHeight: 20}}/>
    </Fragment>
}
