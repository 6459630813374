import React, {useEffect} from "react";
import SignatureCanvas from 'react-signature-canvas';
import {Button, Space} from "antd";

export interface SignPadProps{
    value?: string,
    onChange?: (data?: string) => void
}

const style = {
    height: 100,
    width: 250,
    padding: 10,
    borderStyle: 'solid',
    borderColor: '#d9d9d9',
    borderWidth: 1
};

const Signature: React.FunctionComponent<SignPadProps> = ({onChange, value}) => {
    const padRef = React.useRef<SignatureCanvas>(null);


    useEffect(()=>{
        onChange?.(undefined);
        }, [])

    const onEnd = (e: MouseEvent) => {
        onChange?.(padRef.current?.getTrimmedCanvas().toDataURL("image/png"));
    }

    const onClear = () =>{
        padRef.current?.clear();
        onChange?.(undefined);
    }

    return <Space align="end">
        <div style={style}>
            <SignatureCanvas ref={padRef} onEnd={(e)=>{onEnd(e)}} canvasProps={{height: style.height - 2 * style.padding, width: style.width - 2 * style.padding}}/>
        </div>
        <Button onClick={onClear}>Clear</Button>
    </Space>
}

export default Signature;