import React from "react";
import {unstable_batchedUpdates} from 'react-dom'
import {Auth, IAuth, TokenResponse} from "@q4us-sw/q4us-ui";
import {signIn, signOut, refreshToken, userInfo, signInWithCode} from "../../api";
import {notification} from "antd";
import {useLocation, useHistory} from "react-router-dom";

Auth.configure({
    signIn: async (userName, passWord) => {
        const {access_token, refresh_token} = await signIn(userName, passWord) || {}
        return {token: access_token, refreshToken: refresh_token} as TokenResponse;
    },
    refresh: async (token) => {
        const {access_token, refresh_token} = await refreshToken(token) || {}
        return {token: access_token, refreshToken: refresh_token} as TokenResponse;
    },
    signOut: async () => {
        await signOut(Auth.refreshToken)
        return true;
    },
    signInWithCode: async (authorizationCode, redirectUri) => {
        const {access_token, refresh_token} = await signInWithCode(authorizationCode, redirectUri)
        return {token: access_token, refreshToken: refresh_token}
    },
    storage: "session",
    storageKey: "sbca.token"
})

export interface AuthContextProps {
    auth: IAuth,
    user?: any,
    setUser?: (data: any) => void
    state?: boolean,
    loading?: boolean,
    location_url?: string
}

export const AuthContext = React.createContext<AuthContextProps>({auth: Auth})

export const AuthContextProvider: React.FunctionComponent = (props) => {

    const [signIn, setSignIn] = React.useState(false)
    const [user, setUser] = React.useState()

    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
        Auth.subscribe(async (state) => {
            if (state) {
                const {data} = await userInfo();
                setUser(data)
                setSignIn(state)
                history.push(location.pathname);
            } else {
                setSignIn(false)
            }
        })
        console.log(user)
    }, [])

    React.useEffect(() => {
        // console.log(`signIn= ${signIn}`)
        if (!signIn) {
            unstable_batchedUpdates(() => {
                setUser(undefined)
            })
        } else {
            userInfo().then(({data}) => {
                // console.log(`user= ${JSON.stringify(data)}`)
                unstable_batchedUpdates(() => {
                    setUser(data)
                })

            }).catch(e => {
                setSignIn(false)
                notification.error({message: `Failed to fetch user data`})
            })
        }
    }, [signIn])


    return <AuthContext.Provider value={{auth: Auth, user: user, setUser: setUser}}>
        {props.children}
    </AuthContext.Provider>
}





