import {CustomFormInput} from "@q4us-sw/q4us-ui/lib/Form";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {fetch} from "../../api";
import {Button, Divider, Input, InputRef, notification, Select, Space} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {Trans} from "react-i18next";
import {AxiosResponse} from "axios";
import {unstable_batchedUpdates} from "react-dom";
import {validateAreaCode} from "../../utils/util";

export interface CustomDropDownProps {
    type?: string
    value?: any,
    onChange?: (data: any) => void,
    schema: string,
    name: string,
    title: string,
    tableName?: string,
    filter?:string
    filterValue?: string
    enum_map: string[]
    fetch?: (request:{tableName:string, filter:{[key:string] : any}}) => Promise<AxiosResponse<any>>
}


const CustomDropDown: React.FunctionComponent<CustomDropDownProps> = (props) => {
    const [enums, setEnums] = useState<[string, string][]|undefined>(undefined);
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);

    const fetchEnums = useCallback(async ({tableName, filter, value}:{tableName:string, filter:string|undefined, value:string|undefined})=>{
        if (!props.fetch && (filter && !value)){
            setEnums([]);
            props.onChange?.(undefined)
        }
        else{
            const request = {
                tableName: tableName,
                filter:filter?[{name:filter, value:value, comparator:'='}]:[]
            }
            const response = await props.fetch?.(request) || await fetch(request);
            const {status, data:{rows=[]}}:{status:number, data:{rows:Array<any>}}  = response;
            if (status===200){
                const enums = rows.map((item):[string, string]=>{return [String(item[props.enum_map[0]]) ,String(item[props.enum_map[1]])]});
                if (enums?.filter((enum_item)=>{return enum_item[0] === String(props.value)}).length==0){
                    props.onChange?.(undefined)
                }
                setEnums(enums);
            }
            else{
                setEnums([]);
                props.onChange?.(undefined)
            }
        }
    }, [props.filterValue]);

    useEffect(()=>{
        fetchEnums({tableName: props.tableName || '', filter:props.filter, value:props.filterValue});
    }, [props.filterValue]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if(name) {
            if (validateAreaCode(name)){
                if (enums?.find(element => element[0] === name)){
                    notification.error({message: 'Area Code Already Exists'});
                }
                else{
                    unstable_batchedUpdates(() => {
                        setEnums([...enums || [], [name, name]])
                        setName('')
                    })
                }
            }
            else{
                notification.error({message: 'Invalid Area Code'});
            }
        }
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    if (props.name === 'area_code') {
        return (
            <Select
                allowClear
                showSearch
                value={props.value}
                onChange={(e)=>{props.onChange?.(e)}}
                style={{ width: 300 }}
                dropdownRender={menu => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                placeholder="Please enter code"
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                Add Code
                            </Button>
                        </Space>
                    </>
                )}
            >
                {enums?.map(([k,v])=><Select.Option key={k} value={k} title={v}>
                    <Trans i18nKey={`${props.schema}.${props.name}.${k.replace(/\./g, "_")}`} defaults={v}/>
                </Select.Option>)}
            </Select>
        );
    } else {
        // @ts-ignore
        return  <CustomFormInput
            type={props.type || 'STRING'}
            title={props.title}
            name={props.name}
            schema={props.schema}
            enums={enums}
            value={props.value}
            onChange={(e)=>{props.onChange?.(e)}}
        />
    }

}

export default CustomDropDown